.mapOnclick {
    .sds-title {
        position: absolute;
        top: -160px;
        left: 50%;
        transform: translateX(-50%);
        font-family: $font-primary;
        font-size: 2.4rem;
        line-height: 32px;
    }

    .pointer {
        cursor: pointer;

        // img, svg {
        //     position: absolute;
        //     width: 8%;
        // }
    }

    .positioning {
        width: fit-content;
    }

    .close-button {
        position: absolute;
        top: -15px;
        right: -15px;
        border-radius: 50%;
        background-color: #fff;
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-weight: 700;
        box-shadow: -3px 3px 2px 0px rgba(0, 0, 0, 0.1);
        cursor: pointer;
    }

    .legend {
        .title {
            font-size: 2.4rem;
            line-height: 32px;
            font-family: $font-primary;
            padding-bottom: 2.4rem;
        }

        .item {
            font-size: 1.6rem;
            line-height: 24px;
            font-weight: 700;
            margin-bottom: 16px;

            img {
                margin-right: 16px;
            }
        }
    }

    /* pin */

    .villasimius {
        img, svg {
            bottom: 11%;
            right: 16%;
        }
    }

    .sestu {
        img, svg {
            bottom: 18%;
            right: 42%;
        }
    }

    .villaspeciosa {
        img, svg {
            bottom: 17%;
            right: 57%;
        }
    }

    .ortacesus {
        img, svg {
            bottom: 29%;
            right: 31%;
        }
    }

    .silius {
        img, svg {
            bottom: 24%;
            right: 24%;
        }
    }

    .dolianova {
        img, svg {
            bottom: 22%;
            right: 35%;
        }
    }

    .cardedu {
        img, svg {
            bottom: 43%;
            right: 12%;
        }
    }

    .tertenia {
        img, svg {
            bottom: 36%;
            right: 16%;
        }
    }

    .carbonia {
        img, svg {
            bottom: 10%;
            right: 73%;
        }
    }

    .oristano {
        img, svg {
            bottom: 40%;
            right: 70%;
        }
    }

    .marrubiu {
        img, svg {
            bottom: 34%;
            right: 66%;
        }
    }

    .macomer {
        img, svg {
            bottom: 49%;
            right: 61%;
        }
    }

    .bortigali {
        img, svg {
            bottom: 56%;
            right: 58%;
        }
    }

    .mamoiada {
        img, svg {
            bottom: 54%;
            right: 32%;
        }
    }

    .oliena {
        img, svg {
            bottom: 58%;
            right: 18%;
        }
    }

    .dorgali {
        img, svg {
            bottom: 60%;
            right: 6%;
        }
    }

    .oschiri {
        img, svg {
            bottom: 84%;
            right: 28%;
        }
    }

    .budduso {
        img, svg {
            bottom: 79%;
            right: 20%;
        }
    }

    .mores {
        img, svg {
            bottom: 70%;
            right: 61%;
        }
    }

    .ittiri {
        img, svg {
            bottom: 72%;
            right: 74%;
        }
    }

    .thiesi {
        img, svg {
            bottom: 67%;
            right: 69%;
        }
    }

    /* modalbox specifici */

    .modal-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .list {
        cursor: pointer;
        font-size: 1.6rem;
        line-height: 28px;
        font-weight: 600;
        text-transform: capitalize;
        color: $green;
        text-align: center;

        &:hover {
            text-decoration: underline;
        }
    }

    .boxMap {
        box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
        background-color: $white;
        max-width: 350px;

        .item {
            padding: 24px;
            text-align: left
        }

        p, .richtext {
            font-size: 2.4rem;
            line-height: 32px;
            font-family: $font-primary;
            text-transform: capitalize;
        }

        span {
            display: block;
        }

        img {
            height: 205px;
        }
    }
}