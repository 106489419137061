/*Carousel, mediumHero and miniHero*/

section {
    &.minihero {
        background-image: url('./../images/mini-header.jpg');
        height: 244px;
        @include media-breakpoint-down(lg){
            height: 146px;
        }
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom center;
        box-shadow: inset 0 0 0 1000px rgb(101 86 61 / 60%);
        margin-bottom: 0;

        .row {
            &.insideHeader {
                h1 {
                    color: $white;
                    @include media-breakpoint-down(lg){
                        padding-bottom: 16px;
                    }
                }
            }
        }
    }

    &.mediumhero {
        background-image: url('./../images/medium-header.jpg');
        height: 424px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        box-shadow: inset 0 0 0 1000px rgb(0 0 0 / 40%);
        margin-bottom: 0;

        .row {
            &.insideHeader {
                h1 {
                    color: $white;
                    margin-bottom: 40px;
                }
            }
        }
    }
}