.double-images {
    position: relative;
    .row {
            padding-bottom: 4.0rem;
        }        

    &:after {
        content: "";
        display: block;
        width: 60%;
        height: 70%;
        background-color: $light-green;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
    }

    @media (max-width: 992px) {
        background: $light-green;

        &:after {
            display: none;
        }
    }
}