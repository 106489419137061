.team {
    .container {
        &:nth-child(1) {
            //margin-bottom: 40px;
        }
    }
    .col {
        margin-top: 0;
        .card-body {
            padding-top: 0 !important;
        }
    }

    p, .richtext {
        padding-bottom: 3.2rem;
        margin-bottom: unset;
    }

    .title {
        font-size: 2.4rem;
        font-weight: 700;
        //padding-bottom: 4.0rem;
    }

    .card {
        background-color: transparent;
        border: 0;

        .card-body {
            padding: 3.2rem 0;

            h5 {
                font-family: $font-secondary;
                font-size: 1.4rem;
                line-height: 150%;
                font-weight: 700;
                margin-bottom: 8px;
            }

            p, .richtext {
                font-family: $font-secondary;

                &.card-text {
                    font-size: 1.4rem;
                    line-height: 150%;
                    font-style: italic;
                    padding-bottom: 0;
                }
            }
        }
    }
}