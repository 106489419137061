.title-text {
    &.pyramid {
        p, .richtext {
            &.medium {
                font-family: $font-primary;
                max-width: 830px;
            }
        }
    }

    &.max-w-830 {

        h2,
        p, .richtext {
            max-width: 830px;
        }
    }
}

.sana-alimentazione {

    .position-relative {
        h3 {
            font-weight: 600;
            span {
                color: $basic-brown;
            }
        }
    }
    

    .box-left {
        //padding: 72px 42px 56px 64px;
        padding: 48px;

        @media(max-width: 992px) {
            padding: 40px 16px;
        }

        .double-title {
            margin: 0;
            font-weight: 400;
            margin-bottom: 24px;

            .first-title {
                font-family: $font-primary;
                font-size: 40px;
            }

            .second-title {
                font-family: $font-primary;
                font-size: 50px;
            }
        }


    }

    .box-right {
        margin-top: 40px;
        position: relative;
        //padding: 56px 32px 48px 56px;
        padding: 48px;

        h3 {
            font-size: 2.4rem;
            line-height: 32px;
        }

        @media(max-width: 992px) {
            margin-top: 0;
            margin-bottom: 120px;
            padding: 40px 16px;
        }


        .einstein {
            position: absolute;
            top: -142px;
            right: -11px;
            max-width: 280px;
            mix-blend-mode: multiply;

            @media(max-width: 992px) {
                top: unset;
                bottom: -86px;
                ;
                right: -1px;
                max-width: 170px;
                transform: rotate(180deg);
            }
        }
    }
}

.text-selected {
    p, .richtext {
        font-size: 2.4rem;
        line-height: 32px;
        max-width: 830px;
        font-weight: 600;

        span {
            color: $basic-brown;
        }

        &:not(:last-child) {
            margin-bottom: 32px;
        }
    }
}

.pyramid-figure {
    .img-desktop {
        @media(max-width: 992px) {
            display: none;
        }
    }

    .img-mobile {
        display: none;

        @media(max-width: 992px) {
            display: block;
        }
    }
}

.occhio-bilancia {
    position: relative;
    margin-bottom: 40px;

    .kcal {
        padding: 48px;
        position: relative;
    }

    p, .richtext {
        font-size: 2.4rem;
        max-width: 830px;
        text-align: left;
        font-weight: 600;
        line-height: 32px;
        margin-bottom: 48px;
        &.medium {
            font-size: 2.4rem;
            max-width: 830px;
            line-height: 32px;
            font-family: $font-primary;
            font-weight: 400;
            margin-bottom: 0;
        }

        span {
            color: $basic-brown;
        }

    }

    .einstein {
        max-width: 280px;
        position: absolute;
        bottom: -143px;
        left: -40px;
        //transform: rotate(180deg);
        margin-left: 80px;
        mix-blend-mode: multiply;

        @media(max-width: 992px) {
            margin-bottom: 130px;
        }

        @media(max-width: 992px) {
            right: 10px;
            top: 99%;
            max-width: 170px;
        }
    }

    .bilancia {
        position: absolute;
        right: 10px;
        top: -86px;
        //max-width: 130px;

        @include media-breakpoint-up(lg){
            max-width: 200px;
            right: 54px;
            top: -86px;
        }

        @media screen and (min-width: 992px) and (max-width: 1200px) {
            max-width: 150px;
            top: -100px;
        }

        @media screen and (min-width: 0) and (max-width: 575px) {
            max-width: 80px;
            top: -34px;
        }

        @media screen and (min-width: 576px) and (max-width: 991px) {
            max-width: 130px;
        }
    }
}