.sds {
    padding-bottom: 80px;
    @include media-breakpoint-down(lg) {
        padding-top: 40px;
    }

    .image {
        @include media-breakpoint-down(lg) {
            overflow: hidden;
            height: 200px;
            max-width: unset;
            width: 100%;
            display: flex;
            position: relative;
            justify-content: center;
        }
        img {
            @include media-breakpoint-down(lg) {
                width: unset !important;
                max-width: unset !important;
            }
        }
    }

    h2 {
        padding-top: 7.2rem;
        @include media-breakpoint-down(lg) {
            padding-top: 3.2rem;
            text-align: left;
        }
    }

    p, .richtext {
        @include media-breakpoint-down(lg) {
            text-align: left;
        }
    }

    .box {
        max-width: 640px;

        p, .richtext {
            margin-bottom: 24px;
        }
    }
}