.pagination {

    .page-numbers {
        display: flex;
        justify-content: flex-end;
    }
    
    ul {
        display: flex;
        justify-content: flex-end;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
           display: flex; 
           margin: 0 8px; 

           &.current-page {
            justify-content: center;
            align-items: center;
            background: $green;
            border-radius: 50%;
            width: 26px;
            height: 26px;

            a {
                color: $white;
            }
           }
           a {
                text-align: center;
                color: $black;
                width: 26px;
                text-decoration: none;
           }
        }
        .d-inline {
            display: inline;
        }
    }
    .previous, .next {
        display: inline-block;
        border: none;
        background: $bgBody;
    }
}