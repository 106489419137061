
.einstein-right {
    margin-bottom: 0;
    @media(max-width: 992px) {
        background: $light-green;
    }
    .block-green {
        min-height: 555px;
        position: relative;
        padding: 56px 72px;

        @media(max-width: 992px) {
            padding: 40px 16px;
        }
        
        .einstein {
            position: absolute;
            top: 22%;
            left: 84%;
            transform: rotate(90deg);
            mix-blend-mode: multiply;

            @media(max-width: 1399px) {
                max-width: 100%;
                left: 81%;
            }

            @media(max-width: 1199px) {
                max-width: 100%;
                left: 77%;
            }

            @media(max-width: 992px) {
                display: none;
            }
        }
    }
}

.einstein-idea {
    background: linear-gradient(to left, transparent 70%, $light-beige 30%);

    .container {
        @media(max-width: 992px) {
            padding: 0;
            max-width: 100%;
        }
    }

    @media(max-width: 992px) {
        background: transparent;
    }
    .block-img {
        background: $light-beige;
        position: relative;
        height: 100%;
        display: flex;
        justify-content: flex-end;

        @media(max-width: 992px) {
            background: transparent;
            margin-bottom: 24px;
        }
        
        .einstein {
            transform: translateX(15px);

            @media(max-width: 992px) {
                display: none;
            }
        }
        .einstein-mobile {
            display: none;

            @media(max-width: 992px) {
                display: block;
                transform: translateY(-40px);
                mix-blend-mode: multiply;
            }
            @media(max-width: 768px) {
                transform: translateY(-30px);
            }
        }
        
    }
    .block-text {
        height: 100%;
        p, .richtext {
            max-width: 460px;
            margin: auto;
            
            @media(max-width: 992px) {
                max-width: 100%;
                padding: 0 16px;
            }
        }
    }
}