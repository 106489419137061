//font family
$font-primary: 'DM Serif Display', serif;
$font-secondary: 'Poppins', sans-serif;
$font-tertiary: 'Lora', serif;

//font-colors palette figma
$dark: #241733;
$light-grey: #5E656A;
$green: #2B9540;
$light-green: #E1EBE3;
$basic-brown: #AE8030;
$dark-green: #557A5C;
$beige: #CEC39F;
$light-beige: #F8F3E0;
$lightplus-beige:#EAE2C8;
$white: #FFFFFF;
$bgBody: #FBFAFC;
$green-sds: #62E07B;
$beige-sds: #FFF2C0;
$light-red-sds: #A61D30;
$dark-yellow-sds: #FFB800;
$red-sds: #E83851;
$brown-sds: #CC7F32;
$pink-sds: #DD5BA1;
$gold-sds: #DCA63C;
$blue-sds: #6D8BC5;
$dark-brown: #42352F;
$black: $dark;

//color svg 

$ic-yellow: #FFB800;
$ic-green: #62E07B;
$ic-brown: #CC7F32;
$ic-red: #E83851;
$ic-blue: #6D8BC5;
$ic-gold: #DCA63C;
$ic-bordeaux: #A61D30;
$ic-pink: #DD5BA1;
