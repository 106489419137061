section {
    &.hero-plus-triple-cards {
        background-image: url(./../images/hero-plus-triple.jpg);
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        height: 640px;
        @include media-breakpoint-down(lg) {
            background-position: center center;
        }
    }

    &.triple-cards {
        margin-top: -360px;

        .col {
            @include media-breakpoint-down(lg) {
                margin-bottom: 24px;
            }
            &:last-child {
                margin-bottom:0
            }
        }

        .w-scale {
            width: 75%;
            margin-top: 72px;
            margin-bottom: 48px;

            @include media-breakpoint-down(lg) {
                width: 100%;
            }
        }

        .card {
            border-radius: 0;
            border: 10px solid #fff;
            min-height: 450px;
            @include media-breakpoint-down(lg) {
                //border: 0;
                box-shadow: 0px 0px 40px rgb(0 0 0 / 10%);
            }

            &.mb-40 {
                @include media-breakpoint-down(lg) {
                    margin-bottom: 40px;
                }
            }

            .card-body {
                padding-top: 2.4rem;
                .btn {
                    &.btn-secondary {
                        margin-left: 0;
                        padding-left: 0;
                        text-align: left;
                        // @include media-breakpoint-down(lg) {
                        //     display: none;
                        // }
                    }
                }

                .card-title {
                    font-size: 2.4rem;
                    font-weight: 700;
                    line-height: 32px;
                    padding-bottom: 24px;
                    font-family: $font-secondary;
                    min-height: 80px;
                    margin-bottom: 0;
                    @include media-breakpoint-down(lg) {
                        min-height: unset;
                    }
                }

                p, .richtext {
                    &.card-text {
                        color: $dark;
                        font-size: 1.6rem;
                        line-height: 24px;
                        margin-bottom: 24px;
                        font-family: $font-secondary;
                        height: 70px;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
                        white-space: pre-wrap; /* let the text wrap preserving spaces */
                    }
                }
            }
        }
    }
}