/* Linear */

.two-boxes {
    h2 {
        font-size: 3.2rem;
    }
    .white {
        padding: 40px;
    }
    &.staggered {
        &.sticky {
            .stick-txt, .stick-img {
                display: block;
            }
        }
        .row {
            div {
                &:nth-child(1) {
                    margin-top: 80px !important;
                    @include media-breakpoint-down(lg){
                        margin-top: 40px !important;
                    }
                }
                &:nth-child(2) {
                    margin-bottom: 24px !important;
                }
            }
        }
    }
    .row {
        div {
            & .richtext, h2 {
                //padding: 7.2rem;
                @include media-breakpoint-down(lg){
                    padding: 4rem 1.6rem 3.2rem
                }
            }            
        }
        &.flex-row-reverse {
            .stick-txt {
                display: none;
                right: -60px;
                left: unset;      
            }
            .stick-img {
                display: none;
                top: -70px;
                left: -60px;  
                right: unset;   
            }
        }
        p, .richtext {
            margin-bottom: 4.0rem;
        }
        .stick-txt {
            display: none;
            position: absolute;
            top: -70px;
            left: -60px;        
            width: auto;
        }
        .stick-img {
            display: none;
            position: absolute;
            top: -35px;
            right: -60px;        
            width: auto;
        }
    }
}