section {
    &.questions-rhymes, &.questions-phrases {
        .words {
            .items {
                &:first-of-type {
                    padding-left: 0;    
                }
                font-size: 2.4rem;
                line-height: 130%;
                font-weight: 600;
                color: $green;
                padding: 24px;
            }
        }
         margin-bottom: 2.4rem;
         .title {
            padding-bottom: 2.4rem;
            font-size: 4.0rem;
            line-height: 120%;
            color: $green;
            font-family: $font-primary;
         }
         .question {
            background-color: $white;
            max-width: 70%;
            text-align: center;
            color: #124BB8;
            font-size: 2.4rem;
            line-height: 130%;
            font-weight: 600;
            padding: 6.4rem 0;
            span {
                border-bottom: 3px solid $green;
                width: 100px;
                display: inline-block;
            }
         }
    }
    &.questions-col-direction {
        
        .list {
            padding: 6.4rem;
            .box {
                padding-bottom: 4.0rem;
                .question {
                    max-width: unset;
                    text-align: left;
                    padding: 0 0 0.8rem 0;
                }
                .points {
                    font-family: $font-tertiary;
                    font-weight: 600;
                    color: #5E656A;
                }
            }
        }
    }
    &.answers {
        .container {
            padding: 2.4rem !important;
            padding-bottom: 0 !important;
            .row {
                max-width: 830px;
            }
            .list {
                //padding: 3.2rem;
                .box {
                    margin-bottom: 2.4em;
                    font-family: $font-primary;
                    padding: 3.2rem;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                    .numbers {
                        font-size: 6.4rem;
                        font-weight: 500;
                        color: $basic-brown;
                    }
                    .question {
                        font-size: 2.4rem;
                        line-height: 3.2rem;
                        color: #124BB8;
                        max-width: unset;
                        text-align: left;
                        padding: 0 0 0.8rem 0;
                        font-weight: 500;
                    }
                    .points {
                        font-family: $font-secondary;
                        font-weight: 400;
                        color: #124BB8;
                    }
                }
            }
        }
         margin-bottom: 2.4rem;
         .title {
            padding-bottom: 2.4rem;
            font-size: 4.0rem;
            line-height: 120%;
            color: $black;
            font-family: $font-primary;
         }
    }
    &.questions-rhymes {
        .sticky {
            position: absolute;
            top: -40px;
            right: 39px;
            img {
                width: 290px;
                mix-blend-mode: multiply;
            }
        }
    }
}