section {
    &.chef-community-main {
        margin-bottom: 0;
        padding-top: 80px;
        padding-bottom: 80px;
        @include media-breakpoint-down(lg){
            padding-top: 40px;
            padding-bottom: 0px;
        }

        a {
            color: $black;
            text-decoration: none;
        }

        .btn-primary {
            @include media-breakpoint-down(lg){
                margin-bottom: 2.4rem;
            }

            @include media-breakpoint-down(lg){
                margin-bottom: 3.2rem;
            }
        }

        .item-text {
            background: #FFFFFF;
            padding: 24px;
            min-height: 112px;
            @include media-breakpoint-down(lg){
                min-height: unset;
                margin-bottom: 24px;
            }

            p, .richtext {
                font-family: $font-primary;
                font-size: 24px;
                line-height: 40px;
                margin-bottom: 0;
                padding-bottom: 0;
                line-height: 32px;
                @include media-breakpoint-down(lg){
                    padding-bottom: 0 !important;
                }
            }
        }

        p, .richtext {
            padding-bottom: 4.0rem;
            margin-bottom: 0;
            //max-width: 80%;
        }
    }
}