.slider-full {
    .slick-slider {
        margin-bottom: 0;
        .slick-arrow {
            @include media-breakpoint-down(lg){
                display: none!important;
            }
        }
    }
    .slick-slide {
        position: relative;
    }

    .slick-dots {
        padding-top: 2.4rem;
        padding-bottom: 2.4rem;
        bottom: 0;
        margin: auto;
    }

    .full-slide-text {
        max-width: 40%;
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 6.4rem;

        @media(max-width: 768px) {
            max-width: 100%;
            margin: 2.4rem;
        }

        h2 {
            @media(max-width: 768px) {
                font-size: 18px;
            }
        }
    }
}