section {
    &.chef-map {
        .infos-map {
            h2 {
                &.medium {
                    padding-bottom: 16px;
                }
            }

            ul {
                li {
                    padding-bottom: 2.4rem;

                    p, .richtext {
                        &.fw-bold {
                            padding-bottom: 0.8rem;
                        }

                        &.fw-italic {
                            padding-bottom: 0.8rem;
                        }
                    }

                }
            }

            p, .richtext {
                &.fw-bold {
                    padding-bottom: 4.0rem;
                }

                &.medium {
                    padding-bottom: 4.0rem;
                    margin-bottom: 0;
                }



                // .fw-bold {
                //     padding-bottom: 0.8rem;
                //     font-weight: 600 !important;
                // }
            }
        }
    }
}