.filters-map {
    margin-bottom: 0;
    span {
        color: $green;
        cursor: pointer;
        display: inline-block;

        &.pin {
            font-size: 1.8rem;
            font-weight: 600;

            span {
                pointer-events: none;
            }

            svg {
                pointer-events: none;
                width: 24px;
                height: 24px;
                margin-left: 16px;

                &.iclist {
                    transform: scale(.75);
                }
            }
        }
    }

    .accordion {
        width: 240px;

        .accordion-item {

            .accordion-body {
                position: absolute;
                background-color: $white;
                width: 240px;
                z-index: 1;

                ul {


                    li {
                        font-size: 1.6rem;
                        font-weight: 400;
                        line-height: 28px;
                        border-bottom: 1px solid #ccc;
                        list-style-type: none;
                        margin-bottom: 10px;
                    }
                }
            }

            h2 {
                font-size: 1.6rem;
                font-family: $font-secondary;
                font-weight: 700;
                background-color: transparent;

                button {
                    &.accordion-button {
                        padding-left: 0;
                        z-index: 2;
                        font-weight: 600;
                        font-size: 1.8rem;

                        &:focus {
                            box-shadow: none !important;
                            border: none !important;
                        }

                        &:not(.collapsed) {
                            box-shadow: unset !important;
                            outline: 16px solid $white;
                            background-color: $white;
                        }
                    }

                    color: $green;

                    &::after {
                        background-image: url("./../images/green-arrow-down.png") !important;
                    }
                }
            }

            .accordion-collapse {

                &.collapsing,
                &.show {
                    .accordion-body {
                        outline: 16px solid $white;
                    }
                }
            }
        }
    }
}



.fade-in {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: .6s;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}