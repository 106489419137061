@charset "UTF-8";
/*BS :ROOT VARS*/
/*START FULL BOOTSTRAP IMPORT HERE*/
/*END FULL BOOTSTRAP IMPORT HERE*/
/*START IMPORT SINGLE BOOTSTRAP COMPONENTS*/
@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #2B9540;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #FFFFFF;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #2B9540;
  --bs-secondary: #93328e;
  --bs-success: #93328e;
  --bs-info: #93328e;
  --bs-warning: #93328e;
  --bs-danger: #93328e;
  --bs-light: #f8f9fa;
  --bs-dark: #241733;
  --bs-primary-rgb: 43, 149, 64;
  --bs-secondary-rgb: 147, 50, 142;
  --bs-success-rgb: 147, 50, 142;
  --bs-info-rgb: 147, 50, 142;
  --bs-warning-rgb: 147, 50, 142;
  --bs-danger-rgb: 147, 50, 142;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 36, 23, 51;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 36, 23, 51;
  --bs-body-color-rgb: 0, 0, 0;
  --bs-body-bg-rgb: 251, 250, 252;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-root-font-size: 62.5%;
  --bs-body-font-family: Open Sans, sans-serif;
  --bs-body-font-size: 1.6rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.44;
  --bs-body-color: #000;
  --bs-body-text-align: left;
  --bs-body-bg: #FBFAFC;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  font-size: var(--bs-root-font-size);
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(36, 23, 51, 0);
}

hr {
  margin: 2.4rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 1.2rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.525rem + 3.3vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 4rem;
  }
}

h2, .h2 {
  font-size: calc(1.445rem + 2.34vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 3.2rem;
  }
}

h3, .h3 {
  font-size: calc(1.405rem + 1.86vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 2.8rem;
  }
}

h4, .h4 {
  font-size: calc(1.365rem + 1.38vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 2.4rem;
  }
}

h5, .h5 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h5, .h5 {
    font-size: 2rem;
  }
}

h6, .h6 {
  font-size: calc(1.285rem + 0.42vw);
}
@media (min-width: 1200px) {
  h6, .h6 {
    font-size: 1.6rem;
  }
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #2B9540;
  text-decoration: underline;
}
a:hover {
  color: #227733;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #FFFFFF;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: calc(1.325rem + 0.9vw);
  font-weight: 300;
}
@media (min-width: 1200px) {
  .lead {
    font-size: 2rem;
  }
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 2.4rem;
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  .blockquote {
    font-size: 2rem;
  }
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -2.4rem;
  margin-bottom: 2.4rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #FBFAFC;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 1.2rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container-xs {
  width: 100%;
  padding-right: var(--bs-gutter-x, 1.6rem);
  padding-left: var(--bs-gutter-x, 1.6rem);
  margin-right: auto;
  margin-left: auto;
}

.container {
  max-width: 477px;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 3.2rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.6rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.6rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 1.2rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 1.2rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 2.4rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 2.4rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 3.6rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 3.6rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 7.2rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 7.2rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 14.4rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 14.4rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.6rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.6rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 1.2rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 1.2rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 2.4rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 2.4rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 3.6rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 3.6rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 7.2rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 7.2rem;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 14.4rem;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 14.4rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.6rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.6rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 1.2rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 1.2rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 2.4rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 2.4rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 3.6rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 3.6rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 7.2rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 7.2rem;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 14.4rem;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 14.4rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.6rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.6rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 1.2rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 1.2rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 2.4rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 2.4rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 3.6rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 3.6rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 7.2rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 7.2rem;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 14.4rem;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 14.4rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.6rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.6rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 1.2rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 1.2rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 2.4rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 2.4rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 3.6rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 3.6rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 7.2rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 7.2rem;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 14.4rem;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 14.4rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.6rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.6rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 1.2rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 1.2rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 2.4rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 2.4rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 3.6rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 3.6rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 7.2rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 7.2rem;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 14.4rem;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 14.4rem;
  }
}
@media (min-width: 1600px) {
  .col-xxxl {
    flex: 1 0 0%;
  }
  .row-cols-xxxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxxl-0 {
    margin-left: 0;
  }
  .offset-xxxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxxl-3 {
    margin-left: 25%;
  }
  .offset-xxxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxxl-6 {
    margin-left: 50%;
  }
  .offset-xxxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxxl-9 {
    margin-left: 75%;
  }
  .offset-xxxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxxl-0,
  .gx-xxxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxxl-0,
  .gy-xxxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxxl-1,
  .gx-xxxl-1 {
    --bs-gutter-x: 0.6rem;
  }
  .g-xxxl-1,
  .gy-xxxl-1 {
    --bs-gutter-y: 0.6rem;
  }
  .g-xxxl-2,
  .gx-xxxl-2 {
    --bs-gutter-x: 1.2rem;
  }
  .g-xxxl-2,
  .gy-xxxl-2 {
    --bs-gutter-y: 1.2rem;
  }
  .g-xxxl-3,
  .gx-xxxl-3 {
    --bs-gutter-x: 2.4rem;
  }
  .g-xxxl-3,
  .gy-xxxl-3 {
    --bs-gutter-y: 2.4rem;
  }
  .g-xxxl-4,
  .gx-xxxl-4 {
    --bs-gutter-x: 3.6rem;
  }
  .g-xxxl-4,
  .gy-xxxl-4 {
    --bs-gutter-y: 3.6rem;
  }
  .g-xxxl-5,
  .gx-xxxl-5 {
    --bs-gutter-x: 7.2rem;
  }
  .g-xxxl-5,
  .gy-xxxl-5 {
    --bs-gutter-y: 7.2rem;
  }
  .g-xxxl-6,
  .gx-xxxl-6 {
    --bs-gutter-x: 14.4rem;
  }
  .g-xxxl-6,
  .gy-xxxl-6 {
    --bs-gutter-y: 14.4rem;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #000;
  --bs-table-striped-bg: rgba(36, 23, 51, 0.05);
  --bs-table-active-color: #000;
  --bs-table-active-bg: rgba(36, 23, 51, 0.1);
  --bs-table-hover-color: #000;
  --bs-table-hover-bg: rgba(36, 23, 51, 0.075);
  width: 100%;
  margin-bottom: 2.4rem;
  color: #000;
  vertical-align: top;
  border-color: #dee2e6;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:first-child) {
  border-top: 2px solid currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #d5ead9;
  --bs-table-striped-bg: #ccdfd1;
  --bs-table-striped-color: #241733;
  --bs-table-active-bg: #c3d5c8;
  --bs-table-active-color: #241733;
  --bs-table-hover-bg: #c8dacd;
  --bs-table-hover-color: #241733;
  color: #241733;
  border-color: #c3d5c8;
}

.table-secondary {
  --bs-table-bg: #e9d6e8;
  --bs-table-striped-bg: #dfccdf;
  --bs-table-striped-color: #241733;
  --bs-table-active-bg: #d5c3d6;
  --bs-table-active-color: #241733;
  --bs-table-hover-bg: #dac8da;
  --bs-table-hover-color: #241733;
  color: #241733;
  border-color: #d5c3d6;
}

.table-success {
  --bs-table-bg: #e9d6e8;
  --bs-table-striped-bg: #dfccdf;
  --bs-table-striped-color: #241733;
  --bs-table-active-bg: #d5c3d6;
  --bs-table-active-color: #241733;
  --bs-table-hover-bg: #dac8da;
  --bs-table-hover-color: #241733;
  color: #241733;
  border-color: #d5c3d6;
}

.table-info {
  --bs-table-bg: #e9d6e8;
  --bs-table-striped-bg: #dfccdf;
  --bs-table-striped-color: #241733;
  --bs-table-active-bg: #d5c3d6;
  --bs-table-active-color: #241733;
  --bs-table-hover-bg: #dac8da;
  --bs-table-hover-color: #241733;
  color: #241733;
  border-color: #d5c3d6;
}

.table-warning {
  --bs-table-bg: #e9d6e8;
  --bs-table-striped-bg: #dfccdf;
  --bs-table-striped-color: #241733;
  --bs-table-active-bg: #d5c3d6;
  --bs-table-active-color: #241733;
  --bs-table-hover-bg: #dac8da;
  --bs-table-hover-color: #241733;
  color: #241733;
  border-color: #d5c3d6;
}

.table-danger {
  --bs-table-bg: #e9d6e8;
  --bs-table-striped-bg: #dfccdf;
  --bs-table-striped-color: #241733;
  --bs-table-active-bg: #d5c3d6;
  --bs-table-active-color: #241733;
  --bs-table-hover-bg: #dac8da;
  --bs-table-hover-color: #241733;
  color: #241733;
  border-color: #d5c3d6;
}

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #edeef0;
  --bs-table-striped-color: #241733;
  --bs-table-active-bg: #e3e2e6;
  --bs-table-active-color: #241733;
  --bs-table-hover-bg: #e8e8eb;
  --bs-table-hover-color: #241733;
  color: #241733;
  border-color: #e3e2e6;
}

.table-dark {
  --bs-table-bg: #241733;
  --bs-table-striped-bg: #2f233d;
  --bs-table-striped-color: #FFFFFF;
  --bs-table-active-bg: #3a2e47;
  --bs-table-active-color: #FFFFFF;
  --bs-table-hover-bg: #342842;
  --bs-table-hover-color: #FFFFFF;
  color: #FFFFFF;
  border-color: #3a2e47;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1599.98px) {
  .table-responsive-xxxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.44;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  .col-form-label-lg {
    font-size: 2rem;
  }
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: calc(1.265rem + 0.18vw);
}
@media (min-width: 1200px) {
  .col-form-label-sm {
    font-size: 1.4rem;
  }
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-family: monospace;
  font-size: calc(1.285rem + 0.42vw);
  font-weight: 400;
  line-height: 1.44;
  color: #000;
  background-color: #FBFAFC;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (min-width: 1200px) {
  .form-control {
    font-size: 1.6rem;
  }
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #000;
  background-color: #FBFAFC;
  border-color: #95caa0;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(43, 149, 64, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.44em;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #000;
  background-color: #e9ecef;
  background-image: var(--bs-gradient);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}
.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #000;
  background-color: #e9ecef;
  background-image: var(--bs-gradient);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.44;
  color: #000;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.44em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: calc(1.265rem + 0.18vw);
  border-radius: 0.2rem;
}
@media (min-width: 1200px) {
  .form-control-sm {
    font-size: 1.4rem;
  }
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.44em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: calc(1.325rem + 0.9vw);
  border-radius: 0.3rem;
}
@media (min-width: 1200px) {
  .form-control-lg {
    font-size: 2rem;
  }
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.44em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.44em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.44em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.44em;
  border-radius: 0.25rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.44em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-family: monospace;
  font-size: calc(1.285rem + 0.42vw);
  font-weight: 400;
  line-height: 1.44;
  color: #000;
  background-color: #FBFAFC;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (min-width: 1200px) {
  .form-select {
    font-size: 1.6rem;
  }
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #95caa0;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(43, 149, 64, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: calc(1.265rem + 0.18vw);
  border-radius: 0.2rem;
}
@media (min-width: 1200px) {
  .form-select-sm {
    font-size: 1.4rem;
  }
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: calc(1.325rem + 0.9vw);
  border-radius: 0.3rem;
}
@media (min-width: 1200px) {
  .form-select-lg {
    font-size: 2rem;
  }
}

.form-check {
  display: block;
  min-height: 2.304rem;
  padding-left: 1.8em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.8em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.22em;
  vertical-align: top;
  background-color: #2B9540;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: #2B9540;
  appearance: none;
  color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #95caa0;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(43, 149, 64, 0.25);
}
.form-check-input:checked {
  background-color: #2B9540;
  border-color: #2B9540;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FAFAFA' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"), var(--bs-gradient);
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23FAFAFA'/%3e%3c/svg%3e"), var(--bs-gradient);
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #2B9540;
  border-color: #2B9540;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"), var(--bs-gradient);
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%2836, 23, 51, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2395caa0'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFFFFF'/%3e%3c/svg%3e"), var(--bs-gradient);
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #FBFAFC, 0 0 0 0.25rem rgba(43, 149, 64, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #FBFAFC, 0 0 0 0.25rem rgba(43, 149, 64, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #2B9540;
  background-image: var(--bs-gradient);
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #bfdfc6;
  background-image: var(--bs-gradient);
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #2B9540;
  background-image: var(--bs-gradient);
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #bfdfc6;
  background-image: var(--bs-gradient);
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: calc(1.285rem + 0.42vw);
  font-weight: 400;
  line-height: 1.44;
  color: #000;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
@media (min-width: 1200px) {
  .input-group-text {
    font-size: 1.6rem;
  }
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: calc(1.325rem + 0.9vw);
  border-radius: 0.3rem;
}
@media (min-width: 1200px) {
  .input-group-lg > .form-control,
  .input-group-lg > .form-select,
  .input-group-lg > .input-group-text,
  .input-group-lg > .btn {
    font-size: 2rem;
  }
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: calc(1.265rem + 0.18vw);
  border-radius: 0.2rem;
}
@media (min-width: 1200px) {
  .input-group-sm > .form-control,
  .input-group-sm > .form-select,
  .input-group-sm > .input-group-text,
  .input-group-sm > .btn {
    font-size: 1.4rem;
  }
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #93328e;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.6rem 1.2rem;
  margin-top: 0.1rem;
  font-size: calc(1.265rem + 0.18vw);
  color: #FFFFFF;
  background-color: rgba(147, 50, 142, 0.9);
  border-radius: 0.25rem;
}
@media (min-width: 1200px) {
  .valid-tooltip {
    font-size: 1.4rem;
  }
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #93328e;
  padding-right: calc(1.44em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2393328e' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.36em + 0.1875rem) center;
  background-size: calc(0.72em + 0.375rem) calc(0.72em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #93328e;
  box-shadow: 0 0 0 0.25rem rgba(147, 50, 142, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.44em + 0.75rem);
  background-position: top calc(0.36em + 0.1875rem) right calc(0.36em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #93328e;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2393328e' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.72em + 0.375rem) calc(0.72em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #93328e;
  box-shadow: 0 0 0 0.25rem rgba(147, 50, 142, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #93328e;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #93328e;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(147, 50, 142, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #93328e;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #93328e;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.6rem 1.2rem;
  margin-top: 0.1rem;
  font-size: calc(1.265rem + 0.18vw);
  color: #FFFFFF;
  background-color: rgba(147, 50, 142, 0.9);
  border-radius: 0.25rem;
}
@media (min-width: 1200px) {
  .invalid-tooltip {
    font-size: 1.4rem;
  }
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #93328e;
  padding-right: calc(1.44em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%2393328e'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%2393328e' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.36em + 0.1875rem) center;
  background-size: calc(0.72em + 0.375rem) calc(0.72em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #93328e;
  box-shadow: 0 0 0 0.25rem rgba(147, 50, 142, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.44em + 0.75rem);
  background-position: top calc(0.36em + 0.1875rem) right calc(0.36em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #93328e;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%2393328e'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%2393328e' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.72em + 0.375rem) calc(0.72em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #93328e;
  box-shadow: 0 0 0 0.25rem rgba(147, 50, 142, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #93328e;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #93328e;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(147, 50, 142, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #93328e;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-family: monospace;
  font-weight: 400;
  line-height: 1.44;
  color: #000;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: calc(1.285rem + 0.42vw);
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (min-width: 1200px) {
  .btn {
    font-size: 1.6rem;
  }
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #000;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(43, 149, 64, 0.25);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #241733;
  background-color: #2B9540;
  background-image: var(--bs-gradient);
  border-color: #2B9540;
}
.btn-primary:hover {
  color: #241733;
  background-color: #4ba55d;
  background-image: var(--bs-gradient);
  border-color: #40a053;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #241733;
  background-color: #4ba55d;
  background-image: var(--bs-gradient);
  border-color: #40a053;
  box-shadow: 0 0 0 0.25rem rgba(42, 130, 62, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #241733;
  background-color: #55aa66;
  background-image: none;
  border-color: #40a053;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(42, 130, 62, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #241733;
  background-color: #2B9540;
  background-image: none;
  border-color: #2B9540;
}

.btn-secondary {
  color: #FFFFFF;
  background-color: #93328e;
  background-image: var(--bs-gradient);
  border-color: #93328e;
}
.btn-secondary:hover {
  color: #FFFFFF;
  background-color: #7d2b79;
  background-image: var(--bs-gradient);
  border-color: #762872;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #FFFFFF;
  background-color: #7d2b79;
  background-image: var(--bs-gradient);
  border-color: #762872;
  box-shadow: 0 0 0 0.25rem rgba(163, 81, 159, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #FFFFFF;
  background-color: #762872;
  background-image: none;
  border-color: #6e266b;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(163, 81, 159, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #FFFFFF;
  background-color: #93328e;
  background-image: none;
  border-color: #93328e;
}

.btn-success {
  color: #FFFFFF;
  background-color: #93328e;
  background-image: var(--bs-gradient);
  border-color: #93328e;
}
.btn-success:hover {
  color: #FFFFFF;
  background-color: #7d2b79;
  background-image: var(--bs-gradient);
  border-color: #762872;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #FFFFFF;
  background-color: #7d2b79;
  background-image: var(--bs-gradient);
  border-color: #762872;
  box-shadow: 0 0 0 0.25rem rgba(163, 81, 159, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #FFFFFF;
  background-color: #762872;
  background-image: none;
  border-color: #6e266b;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(163, 81, 159, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #FFFFFF;
  background-color: #93328e;
  background-image: none;
  border-color: #93328e;
}

.btn-info {
  color: #FFFFFF;
  background-color: #93328e;
  background-image: var(--bs-gradient);
  border-color: #93328e;
}
.btn-info:hover {
  color: #FFFFFF;
  background-color: #7d2b79;
  background-image: var(--bs-gradient);
  border-color: #762872;
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #FFFFFF;
  background-color: #7d2b79;
  background-image: var(--bs-gradient);
  border-color: #762872;
  box-shadow: 0 0 0 0.25rem rgba(163, 81, 159, 0.5);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #FFFFFF;
  background-color: #762872;
  background-image: none;
  border-color: #6e266b;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(163, 81, 159, 0.5);
}
.btn-info:disabled, .btn-info.disabled {
  color: #FFFFFF;
  background-color: #93328e;
  background-image: none;
  border-color: #93328e;
}

.btn-warning {
  color: #FFFFFF;
  background-color: #93328e;
  background-image: var(--bs-gradient);
  border-color: #93328e;
}
.btn-warning:hover {
  color: #FFFFFF;
  background-color: #7d2b79;
  background-image: var(--bs-gradient);
  border-color: #762872;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #FFFFFF;
  background-color: #7d2b79;
  background-image: var(--bs-gradient);
  border-color: #762872;
  box-shadow: 0 0 0 0.25rem rgba(163, 81, 159, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #FFFFFF;
  background-color: #762872;
  background-image: none;
  border-color: #6e266b;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(163, 81, 159, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #FFFFFF;
  background-color: #93328e;
  background-image: none;
  border-color: #93328e;
}

.btn-danger {
  color: #FFFFFF;
  background-color: #93328e;
  background-image: var(--bs-gradient);
  border-color: #93328e;
}
.btn-danger:hover {
  color: #FFFFFF;
  background-color: #7d2b79;
  background-image: var(--bs-gradient);
  border-color: #762872;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #FFFFFF;
  background-color: #7d2b79;
  background-image: var(--bs-gradient);
  border-color: #762872;
  box-shadow: 0 0 0 0.25rem rgba(163, 81, 159, 0.5);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #FFFFFF;
  background-color: #762872;
  background-image: none;
  border-color: #6e266b;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(163, 81, 159, 0.5);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #FFFFFF;
  background-color: #93328e;
  background-image: none;
  border-color: #93328e;
}

.btn-light {
  color: #241733;
  background-color: #f8f9fa;
  background-image: var(--bs-gradient);
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #241733;
  background-color: #f9fafb;
  background-image: var(--bs-gradient);
  border-color: #f9fafb;
}
.btn-check:focus + .btn-light, .btn-light:focus {
  color: #241733;
  background-color: #f9fafb;
  background-image: var(--bs-gradient);
  border-color: #f9fafb;
  box-shadow: 0 0 0 0.25rem rgba(216, 215, 220, 0.5);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #241733;
  background-color: #f9fafb;
  background-image: none;
  border-color: #f9fafb;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(216, 215, 220, 0.5);
}
.btn-light:disabled, .btn-light.disabled {
  color: #241733;
  background-color: #f8f9fa;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-dark {
  color: #FFFFFF;
  background-color: #241733;
  background-image: var(--bs-gradient);
  border-color: #241733;
}
.btn-dark:hover {
  color: #FFFFFF;
  background-color: #1f142b;
  background-image: var(--bs-gradient);
  border-color: #1d1229;
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #FFFFFF;
  background-color: #1f142b;
  background-image: var(--bs-gradient);
  border-color: #1d1229;
  box-shadow: 0 0 0 0.25rem rgba(69, 58, 82, 0.5);
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #FFFFFF;
  background-color: #1d1229;
  background-image: none;
  border-color: #1b1126;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(69, 58, 82, 0.5);
}
.btn-dark:disabled, .btn-dark.disabled {
  color: #FFFFFF;
  background-color: #241733;
  background-image: none;
  border-color: #241733;
}

.btn-outline-primary {
  color: #2B9540;
  border-color: #2B9540;
}
.btn-outline-primary:hover {
  color: #241733;
  background-color: #2B9540;
  border-color: #2B9540;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(43, 149, 64, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #241733;
  background-color: #2B9540;
  border-color: #2B9540;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(43, 149, 64, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #2B9540;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #93328e;
  border-color: #93328e;
}
.btn-outline-secondary:hover {
  color: #FFFFFF;
  background-color: #93328e;
  border-color: #93328e;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(147, 50, 142, 0.5);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #FFFFFF;
  background-color: #93328e;
  border-color: #93328e;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(147, 50, 142, 0.5);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #93328e;
  background-color: transparent;
}

.btn-outline-success {
  color: #93328e;
  border-color: #93328e;
}
.btn-outline-success:hover {
  color: #FFFFFF;
  background-color: #93328e;
  border-color: #93328e;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(147, 50, 142, 0.5);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #FFFFFF;
  background-color: #93328e;
  border-color: #93328e;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(147, 50, 142, 0.5);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #93328e;
  background-color: transparent;
}

.btn-outline-info {
  color: #93328e;
  border-color: #93328e;
}
.btn-outline-info:hover {
  color: #FFFFFF;
  background-color: #93328e;
  border-color: #93328e;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(147, 50, 142, 0.5);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #FFFFFF;
  background-color: #93328e;
  border-color: #93328e;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(147, 50, 142, 0.5);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #93328e;
  background-color: transparent;
}

.btn-outline-warning {
  color: #93328e;
  border-color: #93328e;
}
.btn-outline-warning:hover {
  color: #FFFFFF;
  background-color: #93328e;
  border-color: #93328e;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(147, 50, 142, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #FFFFFF;
  background-color: #93328e;
  border-color: #93328e;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(147, 50, 142, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #93328e;
  background-color: transparent;
}

.btn-outline-danger {
  color: #93328e;
  border-color: #93328e;
}
.btn-outline-danger:hover {
  color: #FFFFFF;
  background-color: #93328e;
  border-color: #93328e;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(147, 50, 142, 0.5);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #FFFFFF;
  background-color: #93328e;
  border-color: #93328e;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(147, 50, 142, 0.5);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #93328e;
  background-color: transparent;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #241733;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #241733;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-dark {
  color: #241733;
  border-color: #241733;
}
.btn-outline-dark:hover {
  color: #FFFFFF;
  background-color: #241733;
  border-color: #241733;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(36, 23, 51, 0.5);
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #FFFFFF;
  background-color: #241733;
  border-color: #241733;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(36, 23, 51, 0.5);
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #241733;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #2B9540;
  text-decoration: underline;
}
.btn-link:hover {
  color: #227733;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: calc(1.325rem + 0.9vw);
  border-radius: 0.3rem;
}
@media (min-width: 1200px) {
  .btn-lg, .btn-group-lg > .btn {
    font-size: 2rem;
  }
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: calc(1.265rem + 0.18vw);
  border-radius: 0.2rem;
}
@media (min-width: 1200px) {
  .btn-sm, .btn-group-sm > .btn {
    font-size: 1.4rem;
  }
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: calc(1.285rem + 0.42vw);
  color: #000;
  text-align: left;
  list-style: none;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid rgba(36, 23, 51, 0.15);
  border-radius: 0.25rem;
}
@media (min-width: 1200px) {
  .dropdown-menu {
    font-size: 1.6rem;
  }
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1600px) {
  .dropdown-menu-xxxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 1.2rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(36, 23, 51, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.6rem 2.4rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #1e2125;
  background-color: #e9ecef;
  background-image: var(--bs-gradient);
}
.dropdown-item.active, .dropdown-item:active {
  color: #FFFFFF;
  text-decoration: none;
  background-color: #2B9540;
  background-image: var(--bs-gradient);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
  background-image: none;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 2.4rem;
  margin-bottom: 0;
  font-size: calc(1.265rem + 0.18vw);
  color: #6c757d;
  white-space: nowrap;
}
@media (min-width: 1200px) {
  .dropdown-header {
    font-size: 1.4rem;
  }
}

.dropdown-item-text {
  display: block;
  padding: 0.6rem 2.4rem;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(36, 23, 51, 0.15);
}
.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #FFFFFF;
  background-color: rgba(255, 255, 255, 0.15);
  background-image: var(--bs-gradient);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #FFFFFF;
  background-color: #2B9540;
  background-image: var(--bs-gradient);
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(36, 23, 51, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #2B9540;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #227733;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #FBFAFC;
  border-color: #dee2e6 #dee2e6 #FBFAFC;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #FFFFFF;
  background-color: #2B9540;
  background-image: var(--bs-gradient);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  background-image: var(--bs-gradient);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-xs,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.212rem;
  padding-bottom: 0.212rem;
  margin-right: 1rem;
  font-size: calc(1.325rem + 0.9vw);
  text-decoration: none;
  white-space: nowrap;
}
@media (min-width: 1200px) {
  .navbar-brand {
    font-size: 2rem;
  }
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: calc(1.325rem + 0.9vw);
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (min-width: 1200px) {
  .navbar-toggler {
    font-size: 2rem;
  }
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-sm .offcanvas-top,
  .navbar-expand-sm .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-md .offcanvas-top,
  .navbar-expand-md .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-lg .offcanvas-top,
  .navbar-expand-lg .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xl .offcanvas-top,
  .navbar-expand-xl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xxl .offcanvas-top,
  .navbar-expand-xxl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1600px) {
  .navbar-expand-xxxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxxl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxxl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xxxl .offcanvas-top,
  .navbar-expand-xxxl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xxxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  transition: none;
  transform: none;
}
.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}
.navbar-expand .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-light .navbar-brand {
  color: rgba(36, 23, 51, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(36, 23, 51, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(36, 23, 51, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(36, 23, 51, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(36, 23, 51, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(36, 23, 51, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(36, 23, 51, 0.55);
  border-color: rgba(36, 23, 51, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2836, 23, 51, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(36, 23, 51, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(36, 23, 51, 0.9);
}

.navbar-dark .navbar-brand {
  color: #FFFFFF;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #FFFFFF;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #FFFFFF;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #FFFFFF;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #FFFFFF;
  background-clip: border-box;
  border: 1px solid rgba(36, 23, 51, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 2.4rem 2.4rem;
}

.card-title {
  margin-bottom: 1.2rem;
}

.card-subtitle {
  margin-top: -0.6rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: 2.4rem;
}

.card-header {
  padding: 1.2rem 2.4rem;
  margin-bottom: 0;
  background-color: rgba(36, 23, 51, 0.03);
  border-bottom: 1px solid rgba(36, 23, 51, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 1.2rem 2.4rem;
  background-color: rgba(36, 23, 51, 0.03);
  border-top: 1px solid rgba(36, 23, 51, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -1.2rem;
  margin-bottom: -1.2rem;
  margin-left: -1.2rem;
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: #FFFFFF;
  border-bottom-color: #FFFFFF;
}

.card-header-pills {
  margin-right: -1.2rem;
  margin-left: -1.2rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 2.4rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 1.6rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: calc(1.285rem + 0.42vw);
  color: #000;
  text-align: left;
  background-color: #FBFAFC;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (min-width: 1200px) {
  .accordion-button {
    font-size: 1.6rem;
  }
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #27863a;
  background-color: #eaf4ec;
  box-shadow: inset 0 -1px 0 rgba(36, 23, 51, 0.125);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2327863a'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #95caa0;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(43, 149, 64, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #FBFAFC;
  border: 1px solid rgba(36, 23, 51, 0.125);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #2B9540;
  text-decoration: none;
  background-color: #FFFFFF;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #227733;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  color: #227733;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(43, 149, 64, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #FFFFFF;
  background-color: #2B9540;
  background-image: var(--bs-gradient);
  border-color: #2B9540;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #FFFFFF;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  .pagination-lg .page-link {
    font-size: 2rem;
  }
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: calc(1.265rem + 0.18vw);
}
@media (min-width: 1200px) {
  .pagination-sm .page-link {
    font-size: 1.4rem;
  }
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #FFFFFF;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  background-image: var(--bs-gradient);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 2.4rem 2.4rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 7.2rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 3rem 2.4rem;
}

.alert-primary {
  color: #1a5926;
  background-color: #d5ead9;
  background-image: var(--bs-gradient);
  border-color: #bfdfc6;
}
.alert-primary .alert-link {
  color: #15471e;
}

.alert-secondary {
  color: #581e55;
  background-color: #e9d6e8;
  background-image: var(--bs-gradient);
  border-color: #dfc2dd;
}
.alert-secondary .alert-link {
  color: #461844;
}

.alert-success {
  color: #581e55;
  background-color: #e9d6e8;
  background-image: var(--bs-gradient);
  border-color: #dfc2dd;
}
.alert-success .alert-link {
  color: #461844;
}

.alert-info {
  color: #581e55;
  background-color: #e9d6e8;
  background-image: var(--bs-gradient);
  border-color: #dfc2dd;
}
.alert-info .alert-link {
  color: #461844;
}

.alert-warning {
  color: #581e55;
  background-color: #e9d6e8;
  background-image: var(--bs-gradient);
  border-color: #dfc2dd;
}
.alert-warning .alert-link {
  color: #461844;
}

.alert-danger {
  color: #581e55;
  background-color: #e9d6e8;
  background-image: var(--bs-gradient);
  border-color: #dfc2dd;
}
.alert-danger .alert-link {
  color: #461844;
}

.alert-light {
  color: #797183;
  background-color: #fefefe;
  background-image: var(--bs-gradient);
  border-color: #fdfdfe;
}
.alert-light .alert-link {
  color: #615a69;
}

.alert-dark {
  color: #160e1f;
  background-color: #d3d1d6;
  background-image: var(--bs-gradient);
  border-color: #bdb9c2;
}
.alert-dark .alert-link {
  color: #120b19;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 1.2rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #FFFFFF;
  text-align: center;
  white-space: nowrap;
  background-color: #2B9540;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #000;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 1.2rem 2.4rem;
  color: #212529;
  text-decoration: none;
  background-color: #FFFFFF;
  border: 1px solid rgba(36, 23, 51, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #FFFFFF;
}
.list-group-item.active {
  z-index: 2;
  color: #FFFFFF;
  background-color: #2B9540;
  border-color: #2B9540;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1600px) {
  .list-group-horizontal-xxxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #1a5926;
  background-color: #d5ead9;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #1a5926;
  background-color: #c0d3c3;
}
.list-group-item-primary.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #1a5926;
  border-color: #1a5926;
}

.list-group-item-secondary {
  color: #581e55;
  background-color: #e9d6e8;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #581e55;
  background-color: #d2c1d1;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #581e55;
  border-color: #581e55;
}

.list-group-item-success {
  color: #581e55;
  background-color: #e9d6e8;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #581e55;
  background-color: #d2c1d1;
}
.list-group-item-success.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #581e55;
  border-color: #581e55;
}

.list-group-item-info {
  color: #581e55;
  background-color: #e9d6e8;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #581e55;
  background-color: #d2c1d1;
}
.list-group-item-info.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #581e55;
  border-color: #581e55;
}

.list-group-item-warning {
  color: #581e55;
  background-color: #e9d6e8;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #581e55;
  background-color: #d2c1d1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #581e55;
  border-color: #581e55;
}

.list-group-item-danger {
  color: #581e55;
  background-color: #e9d6e8;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #581e55;
  background-color: #d2c1d1;
}
.list-group-item-danger.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #581e55;
  border-color: #581e55;
}

.list-group-item-light {
  color: #797183;
  background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #797183;
  background-color: #e5e5e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #797183;
  border-color: #797183;
}

.list-group-item-dark {
  color: #160e1f;
  background-color: #d3d1d6;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #160e1f;
  background-color: #bebcc1;
}
.list-group-item-dark.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #160e1f;
  border-color: #160e1f;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #241733;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23241733'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #241733;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(43, 149, 64, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(36, 23, 51, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(36, 23, 51, 0.15);
  border-radius: 0.25rem;
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 1.6rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(36, 23, 51, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid rgba(36, 23, 51, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #241733;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 2.4rem 2.4rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .btn-close {
  padding: 1.2rem 1.2rem;
  margin: -1.2rem -1.2rem -1.2rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.44;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 2.4rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 2.15rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1599.98px) {
  .modal-fullscreen-xxxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxxl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.44;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: calc(1.265rem + 0.18vw);
  word-wrap: break-word;
  opacity: 0;
}
@media (min-width: 1200px) {
  .tooltip {
    font-size: 1.4rem;
  }
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #241733;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #241733;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #241733;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #241733;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.6rem 1.2rem;
  color: #FFFFFF;
  text-align: center;
  background-color: #241733;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.44;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: calc(1.265rem + 0.18vw);
  word-wrap: break-word;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid rgba(36, 23, 51, 0.2);
  border-radius: 0.3rem;
}
@media (min-width: 1200px) {
  .popover {
    font-size: 1.4rem;
  }
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(36, 23, 51, 0.25);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #FFFFFF;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(36, 23, 51, 0.25);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #FFFFFF;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(36, 23, 51, 0.25);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #FFFFFF;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(36, 23, 51, 0.25);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #FFFFFF;
}

.popover-header {
  padding: 0.5rem 2.4rem;
  margin-bottom: 0;
  font-size: calc(1.285rem + 0.42vw);
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(36, 23, 51, 0.2);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
@media (min-width: 1200px) {
  .popover-header {
    font-size: 1.6rem;
  }
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 2.4rem 2.4rem;
  color: #000;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #FFFFFF;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #FFFFFF;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
  background-image: linear-gradient(90deg, rgba(36, 23, 51, 0.25), rgba(36, 23, 51, 0.001));
}

.carousel-control-next {
  right: 0;
  background-image: linear-gradient(270deg, rgba(36, 23, 51, 0.25), rgba(36, 23, 51, 0.001));
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #FFFFFF;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #241733;
}
.carousel-dark .carousel-caption {
  color: #241733;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #FFFFFF;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #241733;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.4rem 2.4rem;
}
.offcanvas-header .btn-close {
  padding: 1.2rem 1.2rem;
  margin-top: -1.2rem;
  margin-right: -1.2rem;
  margin-bottom: -1.2rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.44;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 2.4rem 2.4rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(36, 23, 51, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(36, 23, 51, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(36, 23, 51, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(36, 23, 51, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #2B9540;
}
.link-primary:hover, .link-primary:focus {
  color: #55aa66;
}

.link-secondary {
  color: #93328e;
}
.link-secondary:hover, .link-secondary:focus {
  color: #762872;
}

.link-success {
  color: #93328e;
}
.link-success:hover, .link-success:focus {
  color: #762872;
}

.link-info {
  color: #93328e;
}
.link-info:hover, .link-info:focus {
  color: #762872;
}

.link-warning {
  color: #93328e;
}
.link-warning:hover, .link-warning:focus {
  color: #762872;
}

.link-danger {
  color: #93328e;
}
.link-danger:hover, .link-danger:focus {
  color: #762872;
}

.link-light {
  color: #f8f9fa;
}
.link-light:hover, .link-light:focus {
  color: #f9fafb;
}

.link-dark {
  color: #241733;
}
.link-dark:hover, .link-dark:focus {
  color: #1d1229;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1600px) {
  .sticky-xxxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(36, 23, 51, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(36, 23, 51, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(36, 23, 51, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #2B9540 !important;
}

.border-secondary {
  border-color: #93328e !important;
}

.border-success {
  border-color: #93328e !important;
}

.border-info {
  border-color: #93328e !important;
}

.border-warning {
  border-color: #93328e !important;
}

.border-danger {
  border-color: #93328e !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #241733 !important;
}

.border-white {
  border-color: #FFFFFF !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.6rem !important;
}

.gap-2 {
  gap: 1.2rem !important;
}

.gap-3 {
  gap: 2.4rem !important;
}

.gap-4 {
  gap: 3.6rem !important;
}

.gap-5 {
  gap: 7.2rem !important;
}

.gap-6 {
  gap: 14.4rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.6rem !important;
}

.m-2 {
  margin: 1.2rem !important;
}

.m-3 {
  margin: 2.4rem !important;
}

.m-4 {
  margin: 3.6rem !important;
}

.m-5 {
  margin: 7.2rem !important;
}

.m-6 {
  margin: 14.4rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.6rem !important;
  margin-left: 0.6rem !important;
}

.mx-2 {
  margin-right: 1.2rem !important;
  margin-left: 1.2rem !important;
}

.mx-3 {
  margin-right: 2.4rem !important;
  margin-left: 2.4rem !important;
}

.mx-4 {
  margin-right: 3.6rem !important;
  margin-left: 3.6rem !important;
}

.mx-5 {
  margin-right: 7.2rem !important;
  margin-left: 7.2rem !important;
}

.mx-6 {
  margin-right: 14.4rem !important;
  margin-left: 14.4rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.6rem !important;
  margin-bottom: 0.6rem !important;
}

.my-2 {
  margin-top: 1.2rem !important;
  margin-bottom: 1.2rem !important;
}

.my-3 {
  margin-top: 2.4rem !important;
  margin-bottom: 2.4rem !important;
}

.my-4 {
  margin-top: 3.6rem !important;
  margin-bottom: 3.6rem !important;
}

.my-5 {
  margin-top: 7.2rem !important;
  margin-bottom: 7.2rem !important;
}

.my-6 {
  margin-top: 14.4rem !important;
  margin-bottom: 14.4rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.6rem !important;
}

.mt-2 {
  margin-top: 1.2rem !important;
}

.mt-3 {
  margin-top: 2.4rem !important;
}

.mt-4 {
  margin-top: 3.6rem !important;
}

.mt-5 {
  margin-top: 7.2rem !important;
}

.mt-6 {
  margin-top: 14.4rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.6rem !important;
}

.me-2 {
  margin-right: 1.2rem !important;
}

.me-3 {
  margin-right: 2.4rem !important;
}

.me-4 {
  margin-right: 3.6rem !important;
}

.me-5 {
  margin-right: 7.2rem !important;
}

.me-6 {
  margin-right: 14.4rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.6rem !important;
}

.mb-2 {
  margin-bottom: 1.2rem !important;
}

.mb-3 {
  margin-bottom: 2.4rem !important;
}

.mb-4 {
  margin-bottom: 3.6rem !important;
}

.mb-5 {
  margin-bottom: 7.2rem !important;
}

.mb-6 {
  margin-bottom: 14.4rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.6rem !important;
}

.ms-2 {
  margin-left: 1.2rem !important;
}

.ms-3 {
  margin-left: 2.4rem !important;
}

.ms-4 {
  margin-left: 3.6rem !important;
}

.ms-5 {
  margin-left: 7.2rem !important;
}

.ms-6 {
  margin-left: 14.4rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.6rem !important;
}

.m-n2 {
  margin: -1.2rem !important;
}

.m-n3 {
  margin: -2.4rem !important;
}

.m-n4 {
  margin: -3.6rem !important;
}

.m-n5 {
  margin: -7.2rem !important;
}

.m-n6 {
  margin: -14.4rem !important;
}

.mx-n1 {
  margin-right: -0.6rem !important;
  margin-left: -0.6rem !important;
}

.mx-n2 {
  margin-right: -1.2rem !important;
  margin-left: -1.2rem !important;
}

.mx-n3 {
  margin-right: -2.4rem !important;
  margin-left: -2.4rem !important;
}

.mx-n4 {
  margin-right: -3.6rem !important;
  margin-left: -3.6rem !important;
}

.mx-n5 {
  margin-right: -7.2rem !important;
  margin-left: -7.2rem !important;
}

.mx-n6 {
  margin-right: -14.4rem !important;
  margin-left: -14.4rem !important;
}

.my-n1 {
  margin-top: -0.6rem !important;
  margin-bottom: -0.6rem !important;
}

.my-n2 {
  margin-top: -1.2rem !important;
  margin-bottom: -1.2rem !important;
}

.my-n3 {
  margin-top: -2.4rem !important;
  margin-bottom: -2.4rem !important;
}

.my-n4 {
  margin-top: -3.6rem !important;
  margin-bottom: -3.6rem !important;
}

.my-n5 {
  margin-top: -7.2rem !important;
  margin-bottom: -7.2rem !important;
}

.my-n6 {
  margin-top: -14.4rem !important;
  margin-bottom: -14.4rem !important;
}

.mt-n1 {
  margin-top: -0.6rem !important;
}

.mt-n2 {
  margin-top: -1.2rem !important;
}

.mt-n3 {
  margin-top: -2.4rem !important;
}

.mt-n4 {
  margin-top: -3.6rem !important;
}

.mt-n5 {
  margin-top: -7.2rem !important;
}

.mt-n6 {
  margin-top: -14.4rem !important;
}

.me-n1 {
  margin-right: -0.6rem !important;
}

.me-n2 {
  margin-right: -1.2rem !important;
}

.me-n3 {
  margin-right: -2.4rem !important;
}

.me-n4 {
  margin-right: -3.6rem !important;
}

.me-n5 {
  margin-right: -7.2rem !important;
}

.me-n6 {
  margin-right: -14.4rem !important;
}

.mb-n1 {
  margin-bottom: -0.6rem !important;
}

.mb-n2 {
  margin-bottom: -1.2rem !important;
}

.mb-n3 {
  margin-bottom: -2.4rem !important;
}

.mb-n4 {
  margin-bottom: -3.6rem !important;
}

.mb-n5 {
  margin-bottom: -7.2rem !important;
}

.mb-n6 {
  margin-bottom: -14.4rem !important;
}

.ms-n1 {
  margin-left: -0.6rem !important;
}

.ms-n2 {
  margin-left: -1.2rem !important;
}

.ms-n3 {
  margin-left: -2.4rem !important;
}

.ms-n4 {
  margin-left: -3.6rem !important;
}

.ms-n5 {
  margin-left: -7.2rem !important;
}

.ms-n6 {
  margin-left: -14.4rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.6rem !important;
}

.p-2 {
  padding: 1.2rem !important;
}

.p-3 {
  padding: 2.4rem !important;
}

.p-4 {
  padding: 3.6rem !important;
}

.p-5 {
  padding: 7.2rem !important;
}

.p-6 {
  padding: 14.4rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.6rem !important;
  padding-left: 0.6rem !important;
}

.px-2 {
  padding-right: 1.2rem !important;
  padding-left: 1.2rem !important;
}

.px-3 {
  padding-right: 2.4rem !important;
  padding-left: 2.4rem !important;
}

.px-4 {
  padding-right: 3.6rem !important;
  padding-left: 3.6rem !important;
}

.px-5 {
  padding-right: 7.2rem !important;
  padding-left: 7.2rem !important;
}

.px-6 {
  padding-right: 14.4rem !important;
  padding-left: 14.4rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.6rem !important;
  padding-bottom: 0.6rem !important;
}

.py-2 {
  padding-top: 1.2rem !important;
  padding-bottom: 1.2rem !important;
}

.py-3 {
  padding-top: 2.4rem !important;
  padding-bottom: 2.4rem !important;
}

.py-4 {
  padding-top: 3.6rem !important;
  padding-bottom: 3.6rem !important;
}

.py-5 {
  padding-top: 7.2rem !important;
  padding-bottom: 7.2rem !important;
}

.py-6 {
  padding-top: 14.4rem !important;
  padding-bottom: 14.4rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.6rem !important;
}

.pt-2 {
  padding-top: 1.2rem !important;
}

.pt-3 {
  padding-top: 2.4rem !important;
}

.pt-4 {
  padding-top: 3.6rem !important;
}

.pt-5 {
  padding-top: 7.2rem !important;
}

.pt-6 {
  padding-top: 14.4rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.6rem !important;
}

.pe-2 {
  padding-right: 1.2rem !important;
}

.pe-3 {
  padding-right: 2.4rem !important;
}

.pe-4 {
  padding-right: 3.6rem !important;
}

.pe-5 {
  padding-right: 7.2rem !important;
}

.pe-6 {
  padding-right: 14.4rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.6rem !important;
}

.pb-2 {
  padding-bottom: 1.2rem !important;
}

.pb-3 {
  padding-bottom: 2.4rem !important;
}

.pb-4 {
  padding-bottom: 3.6rem !important;
}

.pb-5 {
  padding-bottom: 7.2rem !important;
}

.pb-6 {
  padding-bottom: 14.4rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.6rem !important;
}

.ps-2 {
  padding-left: 1.2rem !important;
}

.ps-3 {
  padding-left: 2.4rem !important;
}

.ps-4 {
  padding-left: 3.6rem !important;
}

.ps-5 {
  padding-left: 7.2rem !important;
}

.ps-6 {
  padding-left: 14.4rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.525rem + 3.3vw) !important;
}

.fs-2 {
  font-size: calc(1.445rem + 2.34vw) !important;
}

.fs-3 {
  font-size: calc(1.405rem + 1.86vw) !important;
}

.fs-4 {
  font-size: calc(1.365rem + 1.38vw) !important;
}

.fs-5 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-6 {
  font-size: calc(1.285rem + 0.42vw) !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.44 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(36, 23, 51, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.6rem !important;
  }
  .gap-sm-2 {
    gap: 1.2rem !important;
  }
  .gap-sm-3 {
    gap: 2.4rem !important;
  }
  .gap-sm-4 {
    gap: 3.6rem !important;
  }
  .gap-sm-5 {
    gap: 7.2rem !important;
  }
  .gap-sm-6 {
    gap: 14.4rem !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.6rem !important;
  }
  .m-sm-2 {
    margin: 1.2rem !important;
  }
  .m-sm-3 {
    margin: 2.4rem !important;
  }
  .m-sm-4 {
    margin: 3.6rem !important;
  }
  .m-sm-5 {
    margin: 7.2rem !important;
  }
  .m-sm-6 {
    margin: 14.4rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.6rem !important;
    margin-left: 0.6rem !important;
  }
  .mx-sm-2 {
    margin-right: 1.2rem !important;
    margin-left: 1.2rem !important;
  }
  .mx-sm-3 {
    margin-right: 2.4rem !important;
    margin-left: 2.4rem !important;
  }
  .mx-sm-4 {
    margin-right: 3.6rem !important;
    margin-left: 3.6rem !important;
  }
  .mx-sm-5 {
    margin-right: 7.2rem !important;
    margin-left: 7.2rem !important;
  }
  .mx-sm-6 {
    margin-right: 14.4rem !important;
    margin-left: 14.4rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.6rem !important;
    margin-bottom: 0.6rem !important;
  }
  .my-sm-2 {
    margin-top: 1.2rem !important;
    margin-bottom: 1.2rem !important;
  }
  .my-sm-3 {
    margin-top: 2.4rem !important;
    margin-bottom: 2.4rem !important;
  }
  .my-sm-4 {
    margin-top: 3.6rem !important;
    margin-bottom: 3.6rem !important;
  }
  .my-sm-5 {
    margin-top: 7.2rem !important;
    margin-bottom: 7.2rem !important;
  }
  .my-sm-6 {
    margin-top: 14.4rem !important;
    margin-bottom: 14.4rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.6rem !important;
  }
  .mt-sm-2 {
    margin-top: 1.2rem !important;
  }
  .mt-sm-3 {
    margin-top: 2.4rem !important;
  }
  .mt-sm-4 {
    margin-top: 3.6rem !important;
  }
  .mt-sm-5 {
    margin-top: 7.2rem !important;
  }
  .mt-sm-6 {
    margin-top: 14.4rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.6rem !important;
  }
  .me-sm-2 {
    margin-right: 1.2rem !important;
  }
  .me-sm-3 {
    margin-right: 2.4rem !important;
  }
  .me-sm-4 {
    margin-right: 3.6rem !important;
  }
  .me-sm-5 {
    margin-right: 7.2rem !important;
  }
  .me-sm-6 {
    margin-right: 14.4rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.6rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 1.2rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 2.4rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 3.6rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 7.2rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 14.4rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.6rem !important;
  }
  .ms-sm-2 {
    margin-left: 1.2rem !important;
  }
  .ms-sm-3 {
    margin-left: 2.4rem !important;
  }
  .ms-sm-4 {
    margin-left: 3.6rem !important;
  }
  .ms-sm-5 {
    margin-left: 7.2rem !important;
  }
  .ms-sm-6 {
    margin-left: 14.4rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.6rem !important;
  }
  .m-sm-n2 {
    margin: -1.2rem !important;
  }
  .m-sm-n3 {
    margin: -2.4rem !important;
  }
  .m-sm-n4 {
    margin: -3.6rem !important;
  }
  .m-sm-n5 {
    margin: -7.2rem !important;
  }
  .m-sm-n6 {
    margin: -14.4rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.6rem !important;
    margin-left: -0.6rem !important;
  }
  .mx-sm-n2 {
    margin-right: -1.2rem !important;
    margin-left: -1.2rem !important;
  }
  .mx-sm-n3 {
    margin-right: -2.4rem !important;
    margin-left: -2.4rem !important;
  }
  .mx-sm-n4 {
    margin-right: -3.6rem !important;
    margin-left: -3.6rem !important;
  }
  .mx-sm-n5 {
    margin-right: -7.2rem !important;
    margin-left: -7.2rem !important;
  }
  .mx-sm-n6 {
    margin-right: -14.4rem !important;
    margin-left: -14.4rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.6rem !important;
    margin-bottom: -0.6rem !important;
  }
  .my-sm-n2 {
    margin-top: -1.2rem !important;
    margin-bottom: -1.2rem !important;
  }
  .my-sm-n3 {
    margin-top: -2.4rem !important;
    margin-bottom: -2.4rem !important;
  }
  .my-sm-n4 {
    margin-top: -3.6rem !important;
    margin-bottom: -3.6rem !important;
  }
  .my-sm-n5 {
    margin-top: -7.2rem !important;
    margin-bottom: -7.2rem !important;
  }
  .my-sm-n6 {
    margin-top: -14.4rem !important;
    margin-bottom: -14.4rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.6rem !important;
  }
  .mt-sm-n2 {
    margin-top: -1.2rem !important;
  }
  .mt-sm-n3 {
    margin-top: -2.4rem !important;
  }
  .mt-sm-n4 {
    margin-top: -3.6rem !important;
  }
  .mt-sm-n5 {
    margin-top: -7.2rem !important;
  }
  .mt-sm-n6 {
    margin-top: -14.4rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.6rem !important;
  }
  .me-sm-n2 {
    margin-right: -1.2rem !important;
  }
  .me-sm-n3 {
    margin-right: -2.4rem !important;
  }
  .me-sm-n4 {
    margin-right: -3.6rem !important;
  }
  .me-sm-n5 {
    margin-right: -7.2rem !important;
  }
  .me-sm-n6 {
    margin-right: -14.4rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.6rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -1.2rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -2.4rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -3.6rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -7.2rem !important;
  }
  .mb-sm-n6 {
    margin-bottom: -14.4rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.6rem !important;
  }
  .ms-sm-n2 {
    margin-left: -1.2rem !important;
  }
  .ms-sm-n3 {
    margin-left: -2.4rem !important;
  }
  .ms-sm-n4 {
    margin-left: -3.6rem !important;
  }
  .ms-sm-n5 {
    margin-left: -7.2rem !important;
  }
  .ms-sm-n6 {
    margin-left: -14.4rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.6rem !important;
  }
  .p-sm-2 {
    padding: 1.2rem !important;
  }
  .p-sm-3 {
    padding: 2.4rem !important;
  }
  .p-sm-4 {
    padding: 3.6rem !important;
  }
  .p-sm-5 {
    padding: 7.2rem !important;
  }
  .p-sm-6 {
    padding: 14.4rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.6rem !important;
    padding-left: 0.6rem !important;
  }
  .px-sm-2 {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important;
  }
  .px-sm-3 {
    padding-right: 2.4rem !important;
    padding-left: 2.4rem !important;
  }
  .px-sm-4 {
    padding-right: 3.6rem !important;
    padding-left: 3.6rem !important;
  }
  .px-sm-5 {
    padding-right: 7.2rem !important;
    padding-left: 7.2rem !important;
  }
  .px-sm-6 {
    padding-right: 14.4rem !important;
    padding-left: 14.4rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important;
  }
  .py-sm-2 {
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important;
  }
  .py-sm-3 {
    padding-top: 2.4rem !important;
    padding-bottom: 2.4rem !important;
  }
  .py-sm-4 {
    padding-top: 3.6rem !important;
    padding-bottom: 3.6rem !important;
  }
  .py-sm-5 {
    padding-top: 7.2rem !important;
    padding-bottom: 7.2rem !important;
  }
  .py-sm-6 {
    padding-top: 14.4rem !important;
    padding-bottom: 14.4rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.6rem !important;
  }
  .pt-sm-2 {
    padding-top: 1.2rem !important;
  }
  .pt-sm-3 {
    padding-top: 2.4rem !important;
  }
  .pt-sm-4 {
    padding-top: 3.6rem !important;
  }
  .pt-sm-5 {
    padding-top: 7.2rem !important;
  }
  .pt-sm-6 {
    padding-top: 14.4rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.6rem !important;
  }
  .pe-sm-2 {
    padding-right: 1.2rem !important;
  }
  .pe-sm-3 {
    padding-right: 2.4rem !important;
  }
  .pe-sm-4 {
    padding-right: 3.6rem !important;
  }
  .pe-sm-5 {
    padding-right: 7.2rem !important;
  }
  .pe-sm-6 {
    padding-right: 14.4rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.6rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 1.2rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 2.4rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 3.6rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 7.2rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 14.4rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.6rem !important;
  }
  .ps-sm-2 {
    padding-left: 1.2rem !important;
  }
  .ps-sm-3 {
    padding-left: 2.4rem !important;
  }
  .ps-sm-4 {
    padding-left: 3.6rem !important;
  }
  .ps-sm-5 {
    padding-left: 7.2rem !important;
  }
  .ps-sm-6 {
    padding-left: 14.4rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.6rem !important;
  }
  .gap-md-2 {
    gap: 1.2rem !important;
  }
  .gap-md-3 {
    gap: 2.4rem !important;
  }
  .gap-md-4 {
    gap: 3.6rem !important;
  }
  .gap-md-5 {
    gap: 7.2rem !important;
  }
  .gap-md-6 {
    gap: 14.4rem !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.6rem !important;
  }
  .m-md-2 {
    margin: 1.2rem !important;
  }
  .m-md-3 {
    margin: 2.4rem !important;
  }
  .m-md-4 {
    margin: 3.6rem !important;
  }
  .m-md-5 {
    margin: 7.2rem !important;
  }
  .m-md-6 {
    margin: 14.4rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.6rem !important;
    margin-left: 0.6rem !important;
  }
  .mx-md-2 {
    margin-right: 1.2rem !important;
    margin-left: 1.2rem !important;
  }
  .mx-md-3 {
    margin-right: 2.4rem !important;
    margin-left: 2.4rem !important;
  }
  .mx-md-4 {
    margin-right: 3.6rem !important;
    margin-left: 3.6rem !important;
  }
  .mx-md-5 {
    margin-right: 7.2rem !important;
    margin-left: 7.2rem !important;
  }
  .mx-md-6 {
    margin-right: 14.4rem !important;
    margin-left: 14.4rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.6rem !important;
    margin-bottom: 0.6rem !important;
  }
  .my-md-2 {
    margin-top: 1.2rem !important;
    margin-bottom: 1.2rem !important;
  }
  .my-md-3 {
    margin-top: 2.4rem !important;
    margin-bottom: 2.4rem !important;
  }
  .my-md-4 {
    margin-top: 3.6rem !important;
    margin-bottom: 3.6rem !important;
  }
  .my-md-5 {
    margin-top: 7.2rem !important;
    margin-bottom: 7.2rem !important;
  }
  .my-md-6 {
    margin-top: 14.4rem !important;
    margin-bottom: 14.4rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.6rem !important;
  }
  .mt-md-2 {
    margin-top: 1.2rem !important;
  }
  .mt-md-3 {
    margin-top: 2.4rem !important;
  }
  .mt-md-4 {
    margin-top: 3.6rem !important;
  }
  .mt-md-5 {
    margin-top: 7.2rem !important;
  }
  .mt-md-6 {
    margin-top: 14.4rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.6rem !important;
  }
  .me-md-2 {
    margin-right: 1.2rem !important;
  }
  .me-md-3 {
    margin-right: 2.4rem !important;
  }
  .me-md-4 {
    margin-right: 3.6rem !important;
  }
  .me-md-5 {
    margin-right: 7.2rem !important;
  }
  .me-md-6 {
    margin-right: 14.4rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.6rem !important;
  }
  .mb-md-2 {
    margin-bottom: 1.2rem !important;
  }
  .mb-md-3 {
    margin-bottom: 2.4rem !important;
  }
  .mb-md-4 {
    margin-bottom: 3.6rem !important;
  }
  .mb-md-5 {
    margin-bottom: 7.2rem !important;
  }
  .mb-md-6 {
    margin-bottom: 14.4rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.6rem !important;
  }
  .ms-md-2 {
    margin-left: 1.2rem !important;
  }
  .ms-md-3 {
    margin-left: 2.4rem !important;
  }
  .ms-md-4 {
    margin-left: 3.6rem !important;
  }
  .ms-md-5 {
    margin-left: 7.2rem !important;
  }
  .ms-md-6 {
    margin-left: 14.4rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.6rem !important;
  }
  .m-md-n2 {
    margin: -1.2rem !important;
  }
  .m-md-n3 {
    margin: -2.4rem !important;
  }
  .m-md-n4 {
    margin: -3.6rem !important;
  }
  .m-md-n5 {
    margin: -7.2rem !important;
  }
  .m-md-n6 {
    margin: -14.4rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.6rem !important;
    margin-left: -0.6rem !important;
  }
  .mx-md-n2 {
    margin-right: -1.2rem !important;
    margin-left: -1.2rem !important;
  }
  .mx-md-n3 {
    margin-right: -2.4rem !important;
    margin-left: -2.4rem !important;
  }
  .mx-md-n4 {
    margin-right: -3.6rem !important;
    margin-left: -3.6rem !important;
  }
  .mx-md-n5 {
    margin-right: -7.2rem !important;
    margin-left: -7.2rem !important;
  }
  .mx-md-n6 {
    margin-right: -14.4rem !important;
    margin-left: -14.4rem !important;
  }
  .my-md-n1 {
    margin-top: -0.6rem !important;
    margin-bottom: -0.6rem !important;
  }
  .my-md-n2 {
    margin-top: -1.2rem !important;
    margin-bottom: -1.2rem !important;
  }
  .my-md-n3 {
    margin-top: -2.4rem !important;
    margin-bottom: -2.4rem !important;
  }
  .my-md-n4 {
    margin-top: -3.6rem !important;
    margin-bottom: -3.6rem !important;
  }
  .my-md-n5 {
    margin-top: -7.2rem !important;
    margin-bottom: -7.2rem !important;
  }
  .my-md-n6 {
    margin-top: -14.4rem !important;
    margin-bottom: -14.4rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.6rem !important;
  }
  .mt-md-n2 {
    margin-top: -1.2rem !important;
  }
  .mt-md-n3 {
    margin-top: -2.4rem !important;
  }
  .mt-md-n4 {
    margin-top: -3.6rem !important;
  }
  .mt-md-n5 {
    margin-top: -7.2rem !important;
  }
  .mt-md-n6 {
    margin-top: -14.4rem !important;
  }
  .me-md-n1 {
    margin-right: -0.6rem !important;
  }
  .me-md-n2 {
    margin-right: -1.2rem !important;
  }
  .me-md-n3 {
    margin-right: -2.4rem !important;
  }
  .me-md-n4 {
    margin-right: -3.6rem !important;
  }
  .me-md-n5 {
    margin-right: -7.2rem !important;
  }
  .me-md-n6 {
    margin-right: -14.4rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.6rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -1.2rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -2.4rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -3.6rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -7.2rem !important;
  }
  .mb-md-n6 {
    margin-bottom: -14.4rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.6rem !important;
  }
  .ms-md-n2 {
    margin-left: -1.2rem !important;
  }
  .ms-md-n3 {
    margin-left: -2.4rem !important;
  }
  .ms-md-n4 {
    margin-left: -3.6rem !important;
  }
  .ms-md-n5 {
    margin-left: -7.2rem !important;
  }
  .ms-md-n6 {
    margin-left: -14.4rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.6rem !important;
  }
  .p-md-2 {
    padding: 1.2rem !important;
  }
  .p-md-3 {
    padding: 2.4rem !important;
  }
  .p-md-4 {
    padding: 3.6rem !important;
  }
  .p-md-5 {
    padding: 7.2rem !important;
  }
  .p-md-6 {
    padding: 14.4rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.6rem !important;
    padding-left: 0.6rem !important;
  }
  .px-md-2 {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important;
  }
  .px-md-3 {
    padding-right: 2.4rem !important;
    padding-left: 2.4rem !important;
  }
  .px-md-4 {
    padding-right: 3.6rem !important;
    padding-left: 3.6rem !important;
  }
  .px-md-5 {
    padding-right: 7.2rem !important;
    padding-left: 7.2rem !important;
  }
  .px-md-6 {
    padding-right: 14.4rem !important;
    padding-left: 14.4rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important;
  }
  .py-md-2 {
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important;
  }
  .py-md-3 {
    padding-top: 2.4rem !important;
    padding-bottom: 2.4rem !important;
  }
  .py-md-4 {
    padding-top: 3.6rem !important;
    padding-bottom: 3.6rem !important;
  }
  .py-md-5 {
    padding-top: 7.2rem !important;
    padding-bottom: 7.2rem !important;
  }
  .py-md-6 {
    padding-top: 14.4rem !important;
    padding-bottom: 14.4rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.6rem !important;
  }
  .pt-md-2 {
    padding-top: 1.2rem !important;
  }
  .pt-md-3 {
    padding-top: 2.4rem !important;
  }
  .pt-md-4 {
    padding-top: 3.6rem !important;
  }
  .pt-md-5 {
    padding-top: 7.2rem !important;
  }
  .pt-md-6 {
    padding-top: 14.4rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.6rem !important;
  }
  .pe-md-2 {
    padding-right: 1.2rem !important;
  }
  .pe-md-3 {
    padding-right: 2.4rem !important;
  }
  .pe-md-4 {
    padding-right: 3.6rem !important;
  }
  .pe-md-5 {
    padding-right: 7.2rem !important;
  }
  .pe-md-6 {
    padding-right: 14.4rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.6rem !important;
  }
  .pb-md-2 {
    padding-bottom: 1.2rem !important;
  }
  .pb-md-3 {
    padding-bottom: 2.4rem !important;
  }
  .pb-md-4 {
    padding-bottom: 3.6rem !important;
  }
  .pb-md-5 {
    padding-bottom: 7.2rem !important;
  }
  .pb-md-6 {
    padding-bottom: 14.4rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.6rem !important;
  }
  .ps-md-2 {
    padding-left: 1.2rem !important;
  }
  .ps-md-3 {
    padding-left: 2.4rem !important;
  }
  .ps-md-4 {
    padding-left: 3.6rem !important;
  }
  .ps-md-5 {
    padding-left: 7.2rem !important;
  }
  .ps-md-6 {
    padding-left: 14.4rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.6rem !important;
  }
  .gap-lg-2 {
    gap: 1.2rem !important;
  }
  .gap-lg-3 {
    gap: 2.4rem !important;
  }
  .gap-lg-4 {
    gap: 3.6rem !important;
  }
  .gap-lg-5 {
    gap: 7.2rem !important;
  }
  .gap-lg-6 {
    gap: 14.4rem !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.6rem !important;
  }
  .m-lg-2 {
    margin: 1.2rem !important;
  }
  .m-lg-3 {
    margin: 2.4rem !important;
  }
  .m-lg-4 {
    margin: 3.6rem !important;
  }
  .m-lg-5 {
    margin: 7.2rem !important;
  }
  .m-lg-6 {
    margin: 14.4rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.6rem !important;
    margin-left: 0.6rem !important;
  }
  .mx-lg-2 {
    margin-right: 1.2rem !important;
    margin-left: 1.2rem !important;
  }
  .mx-lg-3 {
    margin-right: 2.4rem !important;
    margin-left: 2.4rem !important;
  }
  .mx-lg-4 {
    margin-right: 3.6rem !important;
    margin-left: 3.6rem !important;
  }
  .mx-lg-5 {
    margin-right: 7.2rem !important;
    margin-left: 7.2rem !important;
  }
  .mx-lg-6 {
    margin-right: 14.4rem !important;
    margin-left: 14.4rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.6rem !important;
    margin-bottom: 0.6rem !important;
  }
  .my-lg-2 {
    margin-top: 1.2rem !important;
    margin-bottom: 1.2rem !important;
  }
  .my-lg-3 {
    margin-top: 2.4rem !important;
    margin-bottom: 2.4rem !important;
  }
  .my-lg-4 {
    margin-top: 3.6rem !important;
    margin-bottom: 3.6rem !important;
  }
  .my-lg-5 {
    margin-top: 7.2rem !important;
    margin-bottom: 7.2rem !important;
  }
  .my-lg-6 {
    margin-top: 14.4rem !important;
    margin-bottom: 14.4rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.6rem !important;
  }
  .mt-lg-2 {
    margin-top: 1.2rem !important;
  }
  .mt-lg-3 {
    margin-top: 2.4rem !important;
  }
  .mt-lg-4 {
    margin-top: 3.6rem !important;
  }
  .mt-lg-5 {
    margin-top: 7.2rem !important;
  }
  .mt-lg-6 {
    margin-top: 14.4rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.6rem !important;
  }
  .me-lg-2 {
    margin-right: 1.2rem !important;
  }
  .me-lg-3 {
    margin-right: 2.4rem !important;
  }
  .me-lg-4 {
    margin-right: 3.6rem !important;
  }
  .me-lg-5 {
    margin-right: 7.2rem !important;
  }
  .me-lg-6 {
    margin-right: 14.4rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.6rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 1.2rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 2.4rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 3.6rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 7.2rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 14.4rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.6rem !important;
  }
  .ms-lg-2 {
    margin-left: 1.2rem !important;
  }
  .ms-lg-3 {
    margin-left: 2.4rem !important;
  }
  .ms-lg-4 {
    margin-left: 3.6rem !important;
  }
  .ms-lg-5 {
    margin-left: 7.2rem !important;
  }
  .ms-lg-6 {
    margin-left: 14.4rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.6rem !important;
  }
  .m-lg-n2 {
    margin: -1.2rem !important;
  }
  .m-lg-n3 {
    margin: -2.4rem !important;
  }
  .m-lg-n4 {
    margin: -3.6rem !important;
  }
  .m-lg-n5 {
    margin: -7.2rem !important;
  }
  .m-lg-n6 {
    margin: -14.4rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.6rem !important;
    margin-left: -0.6rem !important;
  }
  .mx-lg-n2 {
    margin-right: -1.2rem !important;
    margin-left: -1.2rem !important;
  }
  .mx-lg-n3 {
    margin-right: -2.4rem !important;
    margin-left: -2.4rem !important;
  }
  .mx-lg-n4 {
    margin-right: -3.6rem !important;
    margin-left: -3.6rem !important;
  }
  .mx-lg-n5 {
    margin-right: -7.2rem !important;
    margin-left: -7.2rem !important;
  }
  .mx-lg-n6 {
    margin-right: -14.4rem !important;
    margin-left: -14.4rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.6rem !important;
    margin-bottom: -0.6rem !important;
  }
  .my-lg-n2 {
    margin-top: -1.2rem !important;
    margin-bottom: -1.2rem !important;
  }
  .my-lg-n3 {
    margin-top: -2.4rem !important;
    margin-bottom: -2.4rem !important;
  }
  .my-lg-n4 {
    margin-top: -3.6rem !important;
    margin-bottom: -3.6rem !important;
  }
  .my-lg-n5 {
    margin-top: -7.2rem !important;
    margin-bottom: -7.2rem !important;
  }
  .my-lg-n6 {
    margin-top: -14.4rem !important;
    margin-bottom: -14.4rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.6rem !important;
  }
  .mt-lg-n2 {
    margin-top: -1.2rem !important;
  }
  .mt-lg-n3 {
    margin-top: -2.4rem !important;
  }
  .mt-lg-n4 {
    margin-top: -3.6rem !important;
  }
  .mt-lg-n5 {
    margin-top: -7.2rem !important;
  }
  .mt-lg-n6 {
    margin-top: -14.4rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.6rem !important;
  }
  .me-lg-n2 {
    margin-right: -1.2rem !important;
  }
  .me-lg-n3 {
    margin-right: -2.4rem !important;
  }
  .me-lg-n4 {
    margin-right: -3.6rem !important;
  }
  .me-lg-n5 {
    margin-right: -7.2rem !important;
  }
  .me-lg-n6 {
    margin-right: -14.4rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.6rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -1.2rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -2.4rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -3.6rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -7.2rem !important;
  }
  .mb-lg-n6 {
    margin-bottom: -14.4rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.6rem !important;
  }
  .ms-lg-n2 {
    margin-left: -1.2rem !important;
  }
  .ms-lg-n3 {
    margin-left: -2.4rem !important;
  }
  .ms-lg-n4 {
    margin-left: -3.6rem !important;
  }
  .ms-lg-n5 {
    margin-left: -7.2rem !important;
  }
  .ms-lg-n6 {
    margin-left: -14.4rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.6rem !important;
  }
  .p-lg-2 {
    padding: 1.2rem !important;
  }
  .p-lg-3 {
    padding: 2.4rem !important;
  }
  .p-lg-4 {
    padding: 3.6rem !important;
  }
  .p-lg-5 {
    padding: 7.2rem !important;
  }
  .p-lg-6 {
    padding: 14.4rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.6rem !important;
    padding-left: 0.6rem !important;
  }
  .px-lg-2 {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important;
  }
  .px-lg-3 {
    padding-right: 2.4rem !important;
    padding-left: 2.4rem !important;
  }
  .px-lg-4 {
    padding-right: 3.6rem !important;
    padding-left: 3.6rem !important;
  }
  .px-lg-5 {
    padding-right: 7.2rem !important;
    padding-left: 7.2rem !important;
  }
  .px-lg-6 {
    padding-right: 14.4rem !important;
    padding-left: 14.4rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important;
  }
  .py-lg-2 {
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important;
  }
  .py-lg-3 {
    padding-top: 2.4rem !important;
    padding-bottom: 2.4rem !important;
  }
  .py-lg-4 {
    padding-top: 3.6rem !important;
    padding-bottom: 3.6rem !important;
  }
  .py-lg-5 {
    padding-top: 7.2rem !important;
    padding-bottom: 7.2rem !important;
  }
  .py-lg-6 {
    padding-top: 14.4rem !important;
    padding-bottom: 14.4rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.6rem !important;
  }
  .pt-lg-2 {
    padding-top: 1.2rem !important;
  }
  .pt-lg-3 {
    padding-top: 2.4rem !important;
  }
  .pt-lg-4 {
    padding-top: 3.6rem !important;
  }
  .pt-lg-5 {
    padding-top: 7.2rem !important;
  }
  .pt-lg-6 {
    padding-top: 14.4rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.6rem !important;
  }
  .pe-lg-2 {
    padding-right: 1.2rem !important;
  }
  .pe-lg-3 {
    padding-right: 2.4rem !important;
  }
  .pe-lg-4 {
    padding-right: 3.6rem !important;
  }
  .pe-lg-5 {
    padding-right: 7.2rem !important;
  }
  .pe-lg-6 {
    padding-right: 14.4rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.6rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 1.2rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 2.4rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 3.6rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 7.2rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 14.4rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.6rem !important;
  }
  .ps-lg-2 {
    padding-left: 1.2rem !important;
  }
  .ps-lg-3 {
    padding-left: 2.4rem !important;
  }
  .ps-lg-4 {
    padding-left: 3.6rem !important;
  }
  .ps-lg-5 {
    padding-left: 7.2rem !important;
  }
  .ps-lg-6 {
    padding-left: 14.4rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.6rem !important;
  }
  .gap-xl-2 {
    gap: 1.2rem !important;
  }
  .gap-xl-3 {
    gap: 2.4rem !important;
  }
  .gap-xl-4 {
    gap: 3.6rem !important;
  }
  .gap-xl-5 {
    gap: 7.2rem !important;
  }
  .gap-xl-6 {
    gap: 14.4rem !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.6rem !important;
  }
  .m-xl-2 {
    margin: 1.2rem !important;
  }
  .m-xl-3 {
    margin: 2.4rem !important;
  }
  .m-xl-4 {
    margin: 3.6rem !important;
  }
  .m-xl-5 {
    margin: 7.2rem !important;
  }
  .m-xl-6 {
    margin: 14.4rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.6rem !important;
    margin-left: 0.6rem !important;
  }
  .mx-xl-2 {
    margin-right: 1.2rem !important;
    margin-left: 1.2rem !important;
  }
  .mx-xl-3 {
    margin-right: 2.4rem !important;
    margin-left: 2.4rem !important;
  }
  .mx-xl-4 {
    margin-right: 3.6rem !important;
    margin-left: 3.6rem !important;
  }
  .mx-xl-5 {
    margin-right: 7.2rem !important;
    margin-left: 7.2rem !important;
  }
  .mx-xl-6 {
    margin-right: 14.4rem !important;
    margin-left: 14.4rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.6rem !important;
    margin-bottom: 0.6rem !important;
  }
  .my-xl-2 {
    margin-top: 1.2rem !important;
    margin-bottom: 1.2rem !important;
  }
  .my-xl-3 {
    margin-top: 2.4rem !important;
    margin-bottom: 2.4rem !important;
  }
  .my-xl-4 {
    margin-top: 3.6rem !important;
    margin-bottom: 3.6rem !important;
  }
  .my-xl-5 {
    margin-top: 7.2rem !important;
    margin-bottom: 7.2rem !important;
  }
  .my-xl-6 {
    margin-top: 14.4rem !important;
    margin-bottom: 14.4rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.6rem !important;
  }
  .mt-xl-2 {
    margin-top: 1.2rem !important;
  }
  .mt-xl-3 {
    margin-top: 2.4rem !important;
  }
  .mt-xl-4 {
    margin-top: 3.6rem !important;
  }
  .mt-xl-5 {
    margin-top: 7.2rem !important;
  }
  .mt-xl-6 {
    margin-top: 14.4rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.6rem !important;
  }
  .me-xl-2 {
    margin-right: 1.2rem !important;
  }
  .me-xl-3 {
    margin-right: 2.4rem !important;
  }
  .me-xl-4 {
    margin-right: 3.6rem !important;
  }
  .me-xl-5 {
    margin-right: 7.2rem !important;
  }
  .me-xl-6 {
    margin-right: 14.4rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.6rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 1.2rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 2.4rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 3.6rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 7.2rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 14.4rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.6rem !important;
  }
  .ms-xl-2 {
    margin-left: 1.2rem !important;
  }
  .ms-xl-3 {
    margin-left: 2.4rem !important;
  }
  .ms-xl-4 {
    margin-left: 3.6rem !important;
  }
  .ms-xl-5 {
    margin-left: 7.2rem !important;
  }
  .ms-xl-6 {
    margin-left: 14.4rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.6rem !important;
  }
  .m-xl-n2 {
    margin: -1.2rem !important;
  }
  .m-xl-n3 {
    margin: -2.4rem !important;
  }
  .m-xl-n4 {
    margin: -3.6rem !important;
  }
  .m-xl-n5 {
    margin: -7.2rem !important;
  }
  .m-xl-n6 {
    margin: -14.4rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.6rem !important;
    margin-left: -0.6rem !important;
  }
  .mx-xl-n2 {
    margin-right: -1.2rem !important;
    margin-left: -1.2rem !important;
  }
  .mx-xl-n3 {
    margin-right: -2.4rem !important;
    margin-left: -2.4rem !important;
  }
  .mx-xl-n4 {
    margin-right: -3.6rem !important;
    margin-left: -3.6rem !important;
  }
  .mx-xl-n5 {
    margin-right: -7.2rem !important;
    margin-left: -7.2rem !important;
  }
  .mx-xl-n6 {
    margin-right: -14.4rem !important;
    margin-left: -14.4rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.6rem !important;
    margin-bottom: -0.6rem !important;
  }
  .my-xl-n2 {
    margin-top: -1.2rem !important;
    margin-bottom: -1.2rem !important;
  }
  .my-xl-n3 {
    margin-top: -2.4rem !important;
    margin-bottom: -2.4rem !important;
  }
  .my-xl-n4 {
    margin-top: -3.6rem !important;
    margin-bottom: -3.6rem !important;
  }
  .my-xl-n5 {
    margin-top: -7.2rem !important;
    margin-bottom: -7.2rem !important;
  }
  .my-xl-n6 {
    margin-top: -14.4rem !important;
    margin-bottom: -14.4rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.6rem !important;
  }
  .mt-xl-n2 {
    margin-top: -1.2rem !important;
  }
  .mt-xl-n3 {
    margin-top: -2.4rem !important;
  }
  .mt-xl-n4 {
    margin-top: -3.6rem !important;
  }
  .mt-xl-n5 {
    margin-top: -7.2rem !important;
  }
  .mt-xl-n6 {
    margin-top: -14.4rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.6rem !important;
  }
  .me-xl-n2 {
    margin-right: -1.2rem !important;
  }
  .me-xl-n3 {
    margin-right: -2.4rem !important;
  }
  .me-xl-n4 {
    margin-right: -3.6rem !important;
  }
  .me-xl-n5 {
    margin-right: -7.2rem !important;
  }
  .me-xl-n6 {
    margin-right: -14.4rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.6rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -1.2rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -2.4rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -3.6rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -7.2rem !important;
  }
  .mb-xl-n6 {
    margin-bottom: -14.4rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.6rem !important;
  }
  .ms-xl-n2 {
    margin-left: -1.2rem !important;
  }
  .ms-xl-n3 {
    margin-left: -2.4rem !important;
  }
  .ms-xl-n4 {
    margin-left: -3.6rem !important;
  }
  .ms-xl-n5 {
    margin-left: -7.2rem !important;
  }
  .ms-xl-n6 {
    margin-left: -14.4rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.6rem !important;
  }
  .p-xl-2 {
    padding: 1.2rem !important;
  }
  .p-xl-3 {
    padding: 2.4rem !important;
  }
  .p-xl-4 {
    padding: 3.6rem !important;
  }
  .p-xl-5 {
    padding: 7.2rem !important;
  }
  .p-xl-6 {
    padding: 14.4rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.6rem !important;
    padding-left: 0.6rem !important;
  }
  .px-xl-2 {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important;
  }
  .px-xl-3 {
    padding-right: 2.4rem !important;
    padding-left: 2.4rem !important;
  }
  .px-xl-4 {
    padding-right: 3.6rem !important;
    padding-left: 3.6rem !important;
  }
  .px-xl-5 {
    padding-right: 7.2rem !important;
    padding-left: 7.2rem !important;
  }
  .px-xl-6 {
    padding-right: 14.4rem !important;
    padding-left: 14.4rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important;
  }
  .py-xl-2 {
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important;
  }
  .py-xl-3 {
    padding-top: 2.4rem !important;
    padding-bottom: 2.4rem !important;
  }
  .py-xl-4 {
    padding-top: 3.6rem !important;
    padding-bottom: 3.6rem !important;
  }
  .py-xl-5 {
    padding-top: 7.2rem !important;
    padding-bottom: 7.2rem !important;
  }
  .py-xl-6 {
    padding-top: 14.4rem !important;
    padding-bottom: 14.4rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.6rem !important;
  }
  .pt-xl-2 {
    padding-top: 1.2rem !important;
  }
  .pt-xl-3 {
    padding-top: 2.4rem !important;
  }
  .pt-xl-4 {
    padding-top: 3.6rem !important;
  }
  .pt-xl-5 {
    padding-top: 7.2rem !important;
  }
  .pt-xl-6 {
    padding-top: 14.4rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.6rem !important;
  }
  .pe-xl-2 {
    padding-right: 1.2rem !important;
  }
  .pe-xl-3 {
    padding-right: 2.4rem !important;
  }
  .pe-xl-4 {
    padding-right: 3.6rem !important;
  }
  .pe-xl-5 {
    padding-right: 7.2rem !important;
  }
  .pe-xl-6 {
    padding-right: 14.4rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.6rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 1.2rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 2.4rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 3.6rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 7.2rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 14.4rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.6rem !important;
  }
  .ps-xl-2 {
    padding-left: 1.2rem !important;
  }
  .ps-xl-3 {
    padding-left: 2.4rem !important;
  }
  .ps-xl-4 {
    padding-left: 3.6rem !important;
  }
  .ps-xl-5 {
    padding-left: 7.2rem !important;
  }
  .ps-xl-6 {
    padding-left: 14.4rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.6rem !important;
  }
  .gap-xxl-2 {
    gap: 1.2rem !important;
  }
  .gap-xxl-3 {
    gap: 2.4rem !important;
  }
  .gap-xxl-4 {
    gap: 3.6rem !important;
  }
  .gap-xxl-5 {
    gap: 7.2rem !important;
  }
  .gap-xxl-6 {
    gap: 14.4rem !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.6rem !important;
  }
  .m-xxl-2 {
    margin: 1.2rem !important;
  }
  .m-xxl-3 {
    margin: 2.4rem !important;
  }
  .m-xxl-4 {
    margin: 3.6rem !important;
  }
  .m-xxl-5 {
    margin: 7.2rem !important;
  }
  .m-xxl-6 {
    margin: 14.4rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.6rem !important;
    margin-left: 0.6rem !important;
  }
  .mx-xxl-2 {
    margin-right: 1.2rem !important;
    margin-left: 1.2rem !important;
  }
  .mx-xxl-3 {
    margin-right: 2.4rem !important;
    margin-left: 2.4rem !important;
  }
  .mx-xxl-4 {
    margin-right: 3.6rem !important;
    margin-left: 3.6rem !important;
  }
  .mx-xxl-5 {
    margin-right: 7.2rem !important;
    margin-left: 7.2rem !important;
  }
  .mx-xxl-6 {
    margin-right: 14.4rem !important;
    margin-left: 14.4rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.6rem !important;
    margin-bottom: 0.6rem !important;
  }
  .my-xxl-2 {
    margin-top: 1.2rem !important;
    margin-bottom: 1.2rem !important;
  }
  .my-xxl-3 {
    margin-top: 2.4rem !important;
    margin-bottom: 2.4rem !important;
  }
  .my-xxl-4 {
    margin-top: 3.6rem !important;
    margin-bottom: 3.6rem !important;
  }
  .my-xxl-5 {
    margin-top: 7.2rem !important;
    margin-bottom: 7.2rem !important;
  }
  .my-xxl-6 {
    margin-top: 14.4rem !important;
    margin-bottom: 14.4rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.6rem !important;
  }
  .mt-xxl-2 {
    margin-top: 1.2rem !important;
  }
  .mt-xxl-3 {
    margin-top: 2.4rem !important;
  }
  .mt-xxl-4 {
    margin-top: 3.6rem !important;
  }
  .mt-xxl-5 {
    margin-top: 7.2rem !important;
  }
  .mt-xxl-6 {
    margin-top: 14.4rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.6rem !important;
  }
  .me-xxl-2 {
    margin-right: 1.2rem !important;
  }
  .me-xxl-3 {
    margin-right: 2.4rem !important;
  }
  .me-xxl-4 {
    margin-right: 3.6rem !important;
  }
  .me-xxl-5 {
    margin-right: 7.2rem !important;
  }
  .me-xxl-6 {
    margin-right: 14.4rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.6rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 1.2rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 2.4rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 3.6rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 7.2rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 14.4rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.6rem !important;
  }
  .ms-xxl-2 {
    margin-left: 1.2rem !important;
  }
  .ms-xxl-3 {
    margin-left: 2.4rem !important;
  }
  .ms-xxl-4 {
    margin-left: 3.6rem !important;
  }
  .ms-xxl-5 {
    margin-left: 7.2rem !important;
  }
  .ms-xxl-6 {
    margin-left: 14.4rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.6rem !important;
  }
  .m-xxl-n2 {
    margin: -1.2rem !important;
  }
  .m-xxl-n3 {
    margin: -2.4rem !important;
  }
  .m-xxl-n4 {
    margin: -3.6rem !important;
  }
  .m-xxl-n5 {
    margin: -7.2rem !important;
  }
  .m-xxl-n6 {
    margin: -14.4rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.6rem !important;
    margin-left: -0.6rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -1.2rem !important;
    margin-left: -1.2rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -2.4rem !important;
    margin-left: -2.4rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -3.6rem !important;
    margin-left: -3.6rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -7.2rem !important;
    margin-left: -7.2rem !important;
  }
  .mx-xxl-n6 {
    margin-right: -14.4rem !important;
    margin-left: -14.4rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.6rem !important;
    margin-bottom: -0.6rem !important;
  }
  .my-xxl-n2 {
    margin-top: -1.2rem !important;
    margin-bottom: -1.2rem !important;
  }
  .my-xxl-n3 {
    margin-top: -2.4rem !important;
    margin-bottom: -2.4rem !important;
  }
  .my-xxl-n4 {
    margin-top: -3.6rem !important;
    margin-bottom: -3.6rem !important;
  }
  .my-xxl-n5 {
    margin-top: -7.2rem !important;
    margin-bottom: -7.2rem !important;
  }
  .my-xxl-n6 {
    margin-top: -14.4rem !important;
    margin-bottom: -14.4rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.6rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -1.2rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -2.4rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -3.6rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -7.2rem !important;
  }
  .mt-xxl-n6 {
    margin-top: -14.4rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.6rem !important;
  }
  .me-xxl-n2 {
    margin-right: -1.2rem !important;
  }
  .me-xxl-n3 {
    margin-right: -2.4rem !important;
  }
  .me-xxl-n4 {
    margin-right: -3.6rem !important;
  }
  .me-xxl-n5 {
    margin-right: -7.2rem !important;
  }
  .me-xxl-n6 {
    margin-right: -14.4rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.6rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -1.2rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -2.4rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -3.6rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -7.2rem !important;
  }
  .mb-xxl-n6 {
    margin-bottom: -14.4rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.6rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -1.2rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -2.4rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -3.6rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -7.2rem !important;
  }
  .ms-xxl-n6 {
    margin-left: -14.4rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.6rem !important;
  }
  .p-xxl-2 {
    padding: 1.2rem !important;
  }
  .p-xxl-3 {
    padding: 2.4rem !important;
  }
  .p-xxl-4 {
    padding: 3.6rem !important;
  }
  .p-xxl-5 {
    padding: 7.2rem !important;
  }
  .p-xxl-6 {
    padding: 14.4rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.6rem !important;
    padding-left: 0.6rem !important;
  }
  .px-xxl-2 {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important;
  }
  .px-xxl-3 {
    padding-right: 2.4rem !important;
    padding-left: 2.4rem !important;
  }
  .px-xxl-4 {
    padding-right: 3.6rem !important;
    padding-left: 3.6rem !important;
  }
  .px-xxl-5 {
    padding-right: 7.2rem !important;
    padding-left: 7.2rem !important;
  }
  .px-xxl-6 {
    padding-right: 14.4rem !important;
    padding-left: 14.4rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important;
  }
  .py-xxl-2 {
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important;
  }
  .py-xxl-3 {
    padding-top: 2.4rem !important;
    padding-bottom: 2.4rem !important;
  }
  .py-xxl-4 {
    padding-top: 3.6rem !important;
    padding-bottom: 3.6rem !important;
  }
  .py-xxl-5 {
    padding-top: 7.2rem !important;
    padding-bottom: 7.2rem !important;
  }
  .py-xxl-6 {
    padding-top: 14.4rem !important;
    padding-bottom: 14.4rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.6rem !important;
  }
  .pt-xxl-2 {
    padding-top: 1.2rem !important;
  }
  .pt-xxl-3 {
    padding-top: 2.4rem !important;
  }
  .pt-xxl-4 {
    padding-top: 3.6rem !important;
  }
  .pt-xxl-5 {
    padding-top: 7.2rem !important;
  }
  .pt-xxl-6 {
    padding-top: 14.4rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.6rem !important;
  }
  .pe-xxl-2 {
    padding-right: 1.2rem !important;
  }
  .pe-xxl-3 {
    padding-right: 2.4rem !important;
  }
  .pe-xxl-4 {
    padding-right: 3.6rem !important;
  }
  .pe-xxl-5 {
    padding-right: 7.2rem !important;
  }
  .pe-xxl-6 {
    padding-right: 14.4rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.6rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 1.2rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 2.4rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 3.6rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 7.2rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 14.4rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.6rem !important;
  }
  .ps-xxl-2 {
    padding-left: 1.2rem !important;
  }
  .ps-xxl-3 {
    padding-left: 2.4rem !important;
  }
  .ps-xxl-4 {
    padding-left: 3.6rem !important;
  }
  .ps-xxl-5 {
    padding-left: 7.2rem !important;
  }
  .ps-xxl-6 {
    padding-left: 14.4rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1600px) {
  .float-xxxl-start {
    float: left !important;
  }
  .float-xxxl-end {
    float: right !important;
  }
  .float-xxxl-none {
    float: none !important;
  }
  .d-xxxl-inline {
    display: inline !important;
  }
  .d-xxxl-inline-block {
    display: inline-block !important;
  }
  .d-xxxl-block {
    display: block !important;
  }
  .d-xxxl-grid {
    display: grid !important;
  }
  .d-xxxl-table {
    display: table !important;
  }
  .d-xxxl-table-row {
    display: table-row !important;
  }
  .d-xxxl-table-cell {
    display: table-cell !important;
  }
  .d-xxxl-flex {
    display: flex !important;
  }
  .d-xxxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxxl-none {
    display: none !important;
  }
  .flex-xxxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxxl-row {
    flex-direction: row !important;
  }
  .flex-xxxl-column {
    flex-direction: column !important;
  }
  .flex-xxxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xxxl-0 {
    gap: 0 !important;
  }
  .gap-xxxl-1 {
    gap: 0.6rem !important;
  }
  .gap-xxxl-2 {
    gap: 1.2rem !important;
  }
  .gap-xxxl-3 {
    gap: 2.4rem !important;
  }
  .gap-xxxl-4 {
    gap: 3.6rem !important;
  }
  .gap-xxxl-5 {
    gap: 7.2rem !important;
  }
  .gap-xxxl-6 {
    gap: 14.4rem !important;
  }
  .justify-content-xxxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxxl-center {
    justify-content: center !important;
  }
  .justify-content-xxxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxxl-center {
    align-items: center !important;
  }
  .align-items-xxxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxxl-center {
    align-content: center !important;
  }
  .align-content-xxxl-between {
    align-content: space-between !important;
  }
  .align-content-xxxl-around {
    align-content: space-around !important;
  }
  .align-content-xxxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxxl-auto {
    align-self: auto !important;
  }
  .align-self-xxxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxxl-center {
    align-self: center !important;
  }
  .align-self-xxxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxxl-stretch {
    align-self: stretch !important;
  }
  .order-xxxl-first {
    order: -1 !important;
  }
  .order-xxxl-0 {
    order: 0 !important;
  }
  .order-xxxl-1 {
    order: 1 !important;
  }
  .order-xxxl-2 {
    order: 2 !important;
  }
  .order-xxxl-3 {
    order: 3 !important;
  }
  .order-xxxl-4 {
    order: 4 !important;
  }
  .order-xxxl-5 {
    order: 5 !important;
  }
  .order-xxxl-last {
    order: 6 !important;
  }
  .m-xxxl-0 {
    margin: 0 !important;
  }
  .m-xxxl-1 {
    margin: 0.6rem !important;
  }
  .m-xxxl-2 {
    margin: 1.2rem !important;
  }
  .m-xxxl-3 {
    margin: 2.4rem !important;
  }
  .m-xxxl-4 {
    margin: 3.6rem !important;
  }
  .m-xxxl-5 {
    margin: 7.2rem !important;
  }
  .m-xxxl-6 {
    margin: 14.4rem !important;
  }
  .m-xxxl-auto {
    margin: auto !important;
  }
  .mx-xxxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxxl-1 {
    margin-right: 0.6rem !important;
    margin-left: 0.6rem !important;
  }
  .mx-xxxl-2 {
    margin-right: 1.2rem !important;
    margin-left: 1.2rem !important;
  }
  .mx-xxxl-3 {
    margin-right: 2.4rem !important;
    margin-left: 2.4rem !important;
  }
  .mx-xxxl-4 {
    margin-right: 3.6rem !important;
    margin-left: 3.6rem !important;
  }
  .mx-xxxl-5 {
    margin-right: 7.2rem !important;
    margin-left: 7.2rem !important;
  }
  .mx-xxxl-6 {
    margin-right: 14.4rem !important;
    margin-left: 14.4rem !important;
  }
  .mx-xxxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxxl-1 {
    margin-top: 0.6rem !important;
    margin-bottom: 0.6rem !important;
  }
  .my-xxxl-2 {
    margin-top: 1.2rem !important;
    margin-bottom: 1.2rem !important;
  }
  .my-xxxl-3 {
    margin-top: 2.4rem !important;
    margin-bottom: 2.4rem !important;
  }
  .my-xxxl-4 {
    margin-top: 3.6rem !important;
    margin-bottom: 3.6rem !important;
  }
  .my-xxxl-5 {
    margin-top: 7.2rem !important;
    margin-bottom: 7.2rem !important;
  }
  .my-xxxl-6 {
    margin-top: 14.4rem !important;
    margin-bottom: 14.4rem !important;
  }
  .my-xxxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxxl-1 {
    margin-top: 0.6rem !important;
  }
  .mt-xxxl-2 {
    margin-top: 1.2rem !important;
  }
  .mt-xxxl-3 {
    margin-top: 2.4rem !important;
  }
  .mt-xxxl-4 {
    margin-top: 3.6rem !important;
  }
  .mt-xxxl-5 {
    margin-top: 7.2rem !important;
  }
  .mt-xxxl-6 {
    margin-top: 14.4rem !important;
  }
  .mt-xxxl-auto {
    margin-top: auto !important;
  }
  .me-xxxl-0 {
    margin-right: 0 !important;
  }
  .me-xxxl-1 {
    margin-right: 0.6rem !important;
  }
  .me-xxxl-2 {
    margin-right: 1.2rem !important;
  }
  .me-xxxl-3 {
    margin-right: 2.4rem !important;
  }
  .me-xxxl-4 {
    margin-right: 3.6rem !important;
  }
  .me-xxxl-5 {
    margin-right: 7.2rem !important;
  }
  .me-xxxl-6 {
    margin-right: 14.4rem !important;
  }
  .me-xxxl-auto {
    margin-right: auto !important;
  }
  .mb-xxxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxxl-1 {
    margin-bottom: 0.6rem !important;
  }
  .mb-xxxl-2 {
    margin-bottom: 1.2rem !important;
  }
  .mb-xxxl-3 {
    margin-bottom: 2.4rem !important;
  }
  .mb-xxxl-4 {
    margin-bottom: 3.6rem !important;
  }
  .mb-xxxl-5 {
    margin-bottom: 7.2rem !important;
  }
  .mb-xxxl-6 {
    margin-bottom: 14.4rem !important;
  }
  .mb-xxxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxxl-1 {
    margin-left: 0.6rem !important;
  }
  .ms-xxxl-2 {
    margin-left: 1.2rem !important;
  }
  .ms-xxxl-3 {
    margin-left: 2.4rem !important;
  }
  .ms-xxxl-4 {
    margin-left: 3.6rem !important;
  }
  .ms-xxxl-5 {
    margin-left: 7.2rem !important;
  }
  .ms-xxxl-6 {
    margin-left: 14.4rem !important;
  }
  .ms-xxxl-auto {
    margin-left: auto !important;
  }
  .m-xxxl-n1 {
    margin: -0.6rem !important;
  }
  .m-xxxl-n2 {
    margin: -1.2rem !important;
  }
  .m-xxxl-n3 {
    margin: -2.4rem !important;
  }
  .m-xxxl-n4 {
    margin: -3.6rem !important;
  }
  .m-xxxl-n5 {
    margin: -7.2rem !important;
  }
  .m-xxxl-n6 {
    margin: -14.4rem !important;
  }
  .mx-xxxl-n1 {
    margin-right: -0.6rem !important;
    margin-left: -0.6rem !important;
  }
  .mx-xxxl-n2 {
    margin-right: -1.2rem !important;
    margin-left: -1.2rem !important;
  }
  .mx-xxxl-n3 {
    margin-right: -2.4rem !important;
    margin-left: -2.4rem !important;
  }
  .mx-xxxl-n4 {
    margin-right: -3.6rem !important;
    margin-left: -3.6rem !important;
  }
  .mx-xxxl-n5 {
    margin-right: -7.2rem !important;
    margin-left: -7.2rem !important;
  }
  .mx-xxxl-n6 {
    margin-right: -14.4rem !important;
    margin-left: -14.4rem !important;
  }
  .my-xxxl-n1 {
    margin-top: -0.6rem !important;
    margin-bottom: -0.6rem !important;
  }
  .my-xxxl-n2 {
    margin-top: -1.2rem !important;
    margin-bottom: -1.2rem !important;
  }
  .my-xxxl-n3 {
    margin-top: -2.4rem !important;
    margin-bottom: -2.4rem !important;
  }
  .my-xxxl-n4 {
    margin-top: -3.6rem !important;
    margin-bottom: -3.6rem !important;
  }
  .my-xxxl-n5 {
    margin-top: -7.2rem !important;
    margin-bottom: -7.2rem !important;
  }
  .my-xxxl-n6 {
    margin-top: -14.4rem !important;
    margin-bottom: -14.4rem !important;
  }
  .mt-xxxl-n1 {
    margin-top: -0.6rem !important;
  }
  .mt-xxxl-n2 {
    margin-top: -1.2rem !important;
  }
  .mt-xxxl-n3 {
    margin-top: -2.4rem !important;
  }
  .mt-xxxl-n4 {
    margin-top: -3.6rem !important;
  }
  .mt-xxxl-n5 {
    margin-top: -7.2rem !important;
  }
  .mt-xxxl-n6 {
    margin-top: -14.4rem !important;
  }
  .me-xxxl-n1 {
    margin-right: -0.6rem !important;
  }
  .me-xxxl-n2 {
    margin-right: -1.2rem !important;
  }
  .me-xxxl-n3 {
    margin-right: -2.4rem !important;
  }
  .me-xxxl-n4 {
    margin-right: -3.6rem !important;
  }
  .me-xxxl-n5 {
    margin-right: -7.2rem !important;
  }
  .me-xxxl-n6 {
    margin-right: -14.4rem !important;
  }
  .mb-xxxl-n1 {
    margin-bottom: -0.6rem !important;
  }
  .mb-xxxl-n2 {
    margin-bottom: -1.2rem !important;
  }
  .mb-xxxl-n3 {
    margin-bottom: -2.4rem !important;
  }
  .mb-xxxl-n4 {
    margin-bottom: -3.6rem !important;
  }
  .mb-xxxl-n5 {
    margin-bottom: -7.2rem !important;
  }
  .mb-xxxl-n6 {
    margin-bottom: -14.4rem !important;
  }
  .ms-xxxl-n1 {
    margin-left: -0.6rem !important;
  }
  .ms-xxxl-n2 {
    margin-left: -1.2rem !important;
  }
  .ms-xxxl-n3 {
    margin-left: -2.4rem !important;
  }
  .ms-xxxl-n4 {
    margin-left: -3.6rem !important;
  }
  .ms-xxxl-n5 {
    margin-left: -7.2rem !important;
  }
  .ms-xxxl-n6 {
    margin-left: -14.4rem !important;
  }
  .p-xxxl-0 {
    padding: 0 !important;
  }
  .p-xxxl-1 {
    padding: 0.6rem !important;
  }
  .p-xxxl-2 {
    padding: 1.2rem !important;
  }
  .p-xxxl-3 {
    padding: 2.4rem !important;
  }
  .p-xxxl-4 {
    padding: 3.6rem !important;
  }
  .p-xxxl-5 {
    padding: 7.2rem !important;
  }
  .p-xxxl-6 {
    padding: 14.4rem !important;
  }
  .px-xxxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxxl-1 {
    padding-right: 0.6rem !important;
    padding-left: 0.6rem !important;
  }
  .px-xxxl-2 {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important;
  }
  .px-xxxl-3 {
    padding-right: 2.4rem !important;
    padding-left: 2.4rem !important;
  }
  .px-xxxl-4 {
    padding-right: 3.6rem !important;
    padding-left: 3.6rem !important;
  }
  .px-xxxl-5 {
    padding-right: 7.2rem !important;
    padding-left: 7.2rem !important;
  }
  .px-xxxl-6 {
    padding-right: 14.4rem !important;
    padding-left: 14.4rem !important;
  }
  .py-xxxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxxl-1 {
    padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important;
  }
  .py-xxxl-2 {
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important;
  }
  .py-xxxl-3 {
    padding-top: 2.4rem !important;
    padding-bottom: 2.4rem !important;
  }
  .py-xxxl-4 {
    padding-top: 3.6rem !important;
    padding-bottom: 3.6rem !important;
  }
  .py-xxxl-5 {
    padding-top: 7.2rem !important;
    padding-bottom: 7.2rem !important;
  }
  .py-xxxl-6 {
    padding-top: 14.4rem !important;
    padding-bottom: 14.4rem !important;
  }
  .pt-xxxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxxl-1 {
    padding-top: 0.6rem !important;
  }
  .pt-xxxl-2 {
    padding-top: 1.2rem !important;
  }
  .pt-xxxl-3 {
    padding-top: 2.4rem !important;
  }
  .pt-xxxl-4 {
    padding-top: 3.6rem !important;
  }
  .pt-xxxl-5 {
    padding-top: 7.2rem !important;
  }
  .pt-xxxl-6 {
    padding-top: 14.4rem !important;
  }
  .pe-xxxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxxl-1 {
    padding-right: 0.6rem !important;
  }
  .pe-xxxl-2 {
    padding-right: 1.2rem !important;
  }
  .pe-xxxl-3 {
    padding-right: 2.4rem !important;
  }
  .pe-xxxl-4 {
    padding-right: 3.6rem !important;
  }
  .pe-xxxl-5 {
    padding-right: 7.2rem !important;
  }
  .pe-xxxl-6 {
    padding-right: 14.4rem !important;
  }
  .pb-xxxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxxl-1 {
    padding-bottom: 0.6rem !important;
  }
  .pb-xxxl-2 {
    padding-bottom: 1.2rem !important;
  }
  .pb-xxxl-3 {
    padding-bottom: 2.4rem !important;
  }
  .pb-xxxl-4 {
    padding-bottom: 3.6rem !important;
  }
  .pb-xxxl-5 {
    padding-bottom: 7.2rem !important;
  }
  .pb-xxxl-6 {
    padding-bottom: 14.4rem !important;
  }
  .ps-xxxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxxl-1 {
    padding-left: 0.6rem !important;
  }
  .ps-xxxl-2 {
    padding-left: 1.2rem !important;
  }
  .ps-xxxl-3 {
    padding-left: 2.4rem !important;
  }
  .ps-xxxl-4 {
    padding-left: 3.6rem !important;
  }
  .ps-xxxl-5 {
    padding-left: 7.2rem !important;
  }
  .ps-xxxl-6 {
    padding-left: 14.4rem !important;
  }
  .text-xxxl-start {
    text-align: left !important;
  }
  .text-xxxl-end {
    text-align: right !important;
  }
  .text-xxxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 4rem !important;
  }
  .fs-2 {
    font-size: 3.2rem !important;
  }
  .fs-3 {
    font-size: 2.8rem !important;
  }
  .fs-4 {
    font-size: 2.4rem !important;
  }
  .fs-5 {
    font-size: 2rem !important;
  }
  .fs-6 {
    font-size: 1.6rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
/*END IMPORT SINGLE BOOTSTRAP COMPONENTS*/
/*SLICK STARTS HERE*/
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: purple;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/*SLICK ENDS HERE*/
h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin: 0;
  padding: 0;
  font-weight: 400;
  line-height: 1.2;
}

/*COMPONENTS*/
/* NavBar custom */
header.header {
  z-index: 4;
  position: relative;
}
header .logo {
  width: 120px;
}
header .logo.hp {
  width: 128px;
  height: 100px;
}
header .navbar {
  padding-top: 0;
  background-image: none;
  padding-bottom: 0;
}
@media (max-width: 991.98px) {
  header .navbar .navbar-collapse {
    background-color: #2B9540;
    position: absolute;
    left: 0;
    top: 100px;
    right: 0;
    overflow: auto;
  }
}
header .navbar .navbar-brand {
  padding-top: 0 !important;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 128px;
  min-height: 105px;
}
@media (min-width: 992px) {
  header .navbar .navbar-brand {
    margin-right: 50px;
  }
}
header .navbar ul.navbar-nav {
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
}
header .navbar ul.navbar-nav > li.nav-item {
  position: relative;
  text-align: left;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  header .navbar ul.navbar-nav > li.nav-item {
    padding-left: 1.4rem !important;
    padding-right: 1.4rem !important;
  }
}
header .navbar ul.navbar-nav > li.nav-item .dropdown-menu {
  background-color: #2B9540;
}
@media (max-width: 991.98px) {
  header .navbar ul.navbar-nav > li.nav-item .dropdown-menu {
    padding-left: 16px;
  }
}
header .navbar ul.navbar-nav > li.nav-item .dropdown-menu.dropdown-menu-start .menu-item .nav-link::before {
  left: 0;
  bottom: 0;
  background: #FFFFFF !important;
}
header .navbar ul.navbar-nav > li.nav-item .dropdown-menu li {
  padding: 5px 0;
  color: #FFFFFF !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
@media (min-width: 992px) {
  header .navbar ul.navbar-nav > li.nav-item .dropdown-menu li {
    padding: 10px 20px;
  }
}
header .navbar ul.navbar-nav > li.nav-item .dropdown-menu li a {
  width: 100%;
  padding: 10px 0;
  display: inline-block;
  text-transform: inherit;
  font-weight: 400;
  text-align: left;
  color: #fff;
}
@media (min-width: 992px) {
  header .navbar ul.navbar-nav > li.nav-item .dropdown-menu li a {
    color: #FFFFFF;
    padding: 5px 0;
    white-space: normal;
  }
}
@media (min-width: 992px) {
  header .navbar ul.navbar-nav > li.nav-item:hover > ul {
    display: block;
  }
  header .navbar ul.navbar-nav > li.nav-item:hover > ul > li:hover > a:before {
    background: #FFFFFF;
    width: 30px;
  }
  header .navbar ul.navbar-nav > li.nav-item:hover > ul > li:hover > ul {
    display: block;
    left: 100%;
    top: 0;
  }
  header .navbar ul.navbar-nav > li.nav-item:hover > ul > li:hover > ul a:hover:before {
    width: 30px;
    background: #FFFFFF;
  }
}
header .navbar ul.navbar-nav > li.nav-item ul {
  min-width: 100%;
  width: max-content;
  border: none;
  padding: 0;
  z-index: 3;
}
header .navbar ul.navbar-nav > li.nav-item ul > ul {
  top: 0;
  left: 100%;
}
header .navbar ul.navbar-nav > li.nav-item ul li {
  background: transparent;
  position: relative;
}
header .navbar ul.navbar-nav > li.nav-item ul a {
  text-transform: inherit;
  color: #241733;
  font-size: 1.3rem;
  font-weight: 400;
  margin: 0 auto;
  position: relative;
  letter-spacing: 0.02em;
  text-decoration: none;
}
@media (min-width: 992px) {
  header .navbar ul.navbar-nav > li.nav-item ul a:before {
    display: block;
    position: absolute;
    content: "";
    left: 0px;
    bottom: -4px;
    height: 2px;
    width: 0;
    background: #2B9540;
    transition: all 0.2s ease;
  }
}
@media (min-width: 992px) {
  header .navbar ul.navbar-nav > li.nav-item ul a:hover::before {
    width: 30px;
  }
}
header .navbar ul.navbar-nav > li.nav-item ul a.active {
  position: relative;
}
@media (min-width: 992px) {
  header .navbar ul.navbar-nav > li.nav-item ul a.active:before {
    display: block;
    position: absolute;
    content: "";
    left: 4px;
    bottom: 2px;
    height: 2px;
    width: 30px !important;
    background: #2B9540 !important;
    transition: all 0.2s ease;
  }
}
@media (max-width: 991.98px) {
  header .navbar ul.navbar-nav > li.nav-item ul a.active {
    max-width: max-content;
  }
  header .navbar ul.navbar-nav > li.nav-item ul a.active:before {
    display: block;
    position: absolute;
    content: "";
    left: 0px;
    bottom: 4px;
    height: 2px;
    width: 100%;
    background: #2B9540;
    transition: all 0.2s ease;
  }
}
header .navbar ul.navbar-nav > li.nav-item ul a.active:before {
  background: #FFFFFF !important;
  bottom: -4px;
  left: 0px;
}
header .navbar ul.navbar-nav > li.nav-item .nav-link {
  text-transform: uppercase;
  color: #241733;
  font-size: 1.3rem;
  font-weight: 600;
  width: fit-content;
  position: relative;
  letter-spacing: 0.02em;
  display: inline-block;
}
@media (max-width: 991.98px) {
  header .navbar ul.navbar-nav > li.nav-item .nav-link {
    padding: 10px 0;
    color: #FFFFFF;
  }
}
@media (min-width: 992px) {
  header .navbar ul.navbar-nav > li.nav-item .nav-link:before {
    display: block;
    position: absolute;
    content: "";
    left: 4px;
    bottom: 2px;
    height: 2px;
    width: 0;
    background: #2B9540;
    transition: all 0.2s ease;
  }
}
@media (min-width: 992px) {
  header .navbar ul.navbar-nav > li.nav-item .nav-link:hover::before {
    width: 30px;
  }
}
header .navbar ul.navbar-nav > li.nav-item .nav-link.active {
  position: relative;
}
@media (min-width: 992px) {
  header .navbar ul.navbar-nav > li.nav-item .nav-link.active:before {
    display: block;
    position: absolute;
    content: "";
    left: 4px;
    bottom: 2px;
    height: 2px;
    width: 30px !important;
    background: #2B9540 !important;
    transition: all 0.2s ease;
  }
}
@media (max-width: 991.98px) {
  header .navbar ul.navbar-nav > li.nav-item .nav-link.active:before {
    display: block;
    position: absolute;
    content: "";
    left: 0px;
    bottom: 4px;
    height: 2px;
    width: 100%;
    background: #2B9540;
    transition: all 0.2s ease;
  }
}
header .navbar ul.navbar-nav > li.nav-item .nav-link.active:before {
  background: #FFFFFF;
}
header .navbar ul.navbar-nav > li.nav-item .nav-link svg {
  width: 20px;
  height: 20px;
}
header .navbar ul.navbar-nav > li.nav-item--langs {
  flex-grow: 0;
  padding: 0 8px !important;
}
@media (max-width: 991.98px) {
  header .navbar ul.navbar-nav > li.nav-item--langs {
    flex-grow: 1;
    padding-left: 2.4rem !important;
    padding-right: 2.4rem !important;
    flex-basis: 45%;
  }
}
@media (min-width: 992px) {
  header .navbar ul.navbar-nav > li.nav-item--langs .nav-link {
    padding-right: 0;
  }
  header .navbar ul.navbar-nav > li.nav-item--langs .nav-link:after {
    vertical-align: middle;
    display: none;
  }
}
header .navbar ul.navbar-nav > li.nav-item--langs .dropdown-menu {
  border: none;
  border-radius: 0;
}
@media (max-width: 991.98px) {
  header .navbar ul.navbar-nav > li.nav-item--langs .dropdown-menu .dropdown-item {
    color: #241733 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (max-width: 991.98px) {
  header .navbar ul.navbar-nav > li.nav-item--langs .dropdown-menu {
    background-color: transparent;
  }
}
header .navbar ul.navbar-nav > li.nav-item--langs .dropdown-menu li .dropdown-item {
  font-weight: 500;
  font-size: 1.5rem;
  position: relative;
  padding-left: 0;
  padding-right: 0;
  display: block;
  text-align: left;
  margin-left: 5px;
  margin-right: 5px;
}
@media (max-width: 991.98px) {
  header .navbar ul.navbar-nav > li.nav-item--langs .dropdown-menu li .dropdown-item {
    text-align: center;
  }
}
@media (min-width: 992px) {
  header .navbar ul.navbar-nav > li.nav-item--langs .dropdown-menu li .dropdown-item:before {
    display: block;
    position: absolute;
    content: "";
    left: 0px;
    bottom: 2px;
    height: 2px;
    width: 0;
    background: #2B9540;
    transition: all 0.2s ease;
  }
}
header .navbar ul.navbar-nav > li.nav-item--langs .dropdown-menu li .dropdown-item:hover {
  background-color: transparent;
  background-image: none;
}
@media (min-width: 992px) {
  header .navbar ul.navbar-nav > li.nav-item--langs .dropdown-menu li .dropdown-item:hover::before {
    width: 30px;
  }
}
header .navbar ul.navbar-nav > li.nav-item--search {
  flex-grow: 0;
}
@media (max-width: 991.98px) {
  header .navbar ul.navbar-nav > li.nav-item--search {
    position: relative;
    flex-grow: 1;
  }
}
@media (min-width: 992px) {
  header .navbar ul.navbar-nav > li.nav-item--search .nav-link {
    margin-left: 40px;
    margin-right: 40px;
  }
  header .navbar ul.navbar-nav > li.nav-item--search .nav-link::before {
    display: none;
  }
}
header .navbar ul.navbar-nav > li.nav-item--search .searchbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #E1EBE3;
  border-bottom: 1px solid #000;
  justify-content: space-between;
  z-index: 1;
}
@media (max-width: 991.98px) {
  header .navbar ul.navbar-nav > li.nav-item--search .searchbox {
    width: 100% !important;
  }
}
header .navbar ul.navbar-nav > li.nav-item--search .searchbox.collapsing {
  opacity: 0 !important;
}
header .navbar ul.navbar-nav > li.nav-item--search .searchbox.collapse.show {
  display: flex !important;
  opacity: 1 !important;
}
header .navbar ul.navbar-nav > li.nav-item--search .searchbox__close {
  background-color: transparent;
  border: none;
  outline: none;
  padding-left: 16px;
  padding-right: 16px;
}
header .navbar ul.navbar-nav > li.nav-item--search .searchbox__close svg {
  width: 12px;
  height: 12px;
}
header .navbar ul.navbar-nav > li.nav-item--search .searchbox__form {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}
header .navbar ul.navbar-nav > li.nav-item--search .searchbox__input {
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  /* clears the 'X' from Internet Explorer */
  /* clears the 'X' from Chrome */
}
header .navbar ul.navbar-nav > li.nav-item--search .searchbox__input::-ms-clear, header .navbar ul.navbar-nav > li.nav-item--search .searchbox__input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
header .navbar ul.navbar-nav > li.nav-item--search .searchbox__input::-webkit-search-decoration, header .navbar ul.navbar-nav > li.nav-item--search .searchbox__input::-webkit-search-cancel-button, header .navbar ul.navbar-nav > li.nav-item--search .searchbox__input::-webkit-search-results-button, header .navbar ul.navbar-nav > li.nav-item--search .searchbox__input::-webkit-search-results-decoration {
  display: none;
}
header .navbar ul.navbar-nav > li.nav-item--search .searchbox__submit {
  width: 40px;
  height: 20px;
  display: block;
  border: none;
  outline: none;
  background-color: transparent;
}
header .navbar ul.navbar-nav > li.nav-item--search .searchbox__submit svg {
  width: 20px;
  height: 20px;
  transform: translateY(-3px);
}
@media (min-width: 992px) {
  header .navbar ul.navbar-nav > li.nav-item--social {
    display: none !important;
  }
}
@media (max-width: 991.98px) {
  header .navbar ul.navbar-nav > li.nav-item--social {
    flex-direction: row !important;
    justify-content: center;
  }
  header .navbar ul.navbar-nav > li.nav-item--social a.nav-link {
    width: 30px !important;
    margin: 0;
  }
}

section.header-hp {
  min-height: 640px;
}
section.header-hp .social {
  background-color: #241733;
  position: absolute;
  top: 177px;
  right: 0;
  z-index: 4;
  padding: 12px;
}
@media (max-width: 991.98px) {
  section.header-hp .social {
    display: none !important;
  }
}
section.header-hp .social svg {
  width: 24px;
  height: 24px;
  margin-top: 8px;
  margin-bottom: 8px;
}
section.header-hp .gradient {
  position: absolute;
  z-index: 2;
  background: linear-gradient(90deg, #574525 0%, rgba(56, 45, 24, 0) 100%);
  width: 100%;
  top: 0;
  height: 640px;
}
@media (max-width: 768px) {
  section.header-hp .gradient {
    height: 400px !important;
  }
}
section.header-hp .bg-img {
  height: 640px !important;
}
@media (max-width: 768px) {
  section.header-hp .bg-img {
    height: 400px !important;
  }
}
section.header-hp .min-height {
  align-items: center;
}
@media (max-width: 991px) {
  section.header-hp .min-height {
    min-height: 400px;
  }
}
@media (max-width: 768px) {
  section.header-hp .min-height {
    min-height: 250px;
  }
}
section.header-hp h1, section.header-hp .h1 {
  color: #FBFAFC;
  max-width: 830px;
}
section.header-hp p, section.header-hp .richtext {
  font-size: 4.8rem;
  line-height: 120%;
  color: #FBFAFC;
  font-family: "DM Serif Display", serif;
  max-width: 830px;
}
@media (max-width: 991.98px) {
  section.header-hp p, section.header-hp .richtext {
    font-size: 2.4rem;
    line-height: 32px;
  }
}
section.header-hp .slick-slider {
  z-index: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
section.header-hp .w-scale {
  width: 75%;
  margin-top: 72px;
  margin-bottom: 40px;
}
@media (max-width: 991.98px) {
  section.header-hp .w-scale {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 24px;
  }
}
section.header-hp .z-1 {
  z-index: 1;
}
section.header-hp .z-3 {
  z-index: 3;
}
@media (max-width: 991.98px) {
  section.header-hp .navbar-collapse {
    background-color: #2B9540;
    position: absolute;
    left: 0;
    top: 100px;
    right: 0;
    overflow: auto;
  }
}
section.header-hp .navbar ul.navbar-nav li.nav-item {
  text-align: left;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}
section.header-hp .navbar ul.navbar-nav li.nav-item .dropdown-menu {
  border-radius: 0;
  background-color: #2B9540;
}
@media (max-width: 991.98px) {
  section.header-hp .navbar ul.navbar-nav li.nav-item .dropdown-menu {
    padding-left: 16px;
  }
}
section.header-hp .navbar ul.navbar-nav li.nav-item .dropdown-menu a {
  color: #FFFFFF;
  color: #FFFFFF;
  text-align: left;
  padding: 5px 0;
  width: 100%;
}
@media (max-width: 991.98px) {
  section.header-hp .navbar ul.navbar-nav li.nav-item .dropdown-menu a {
    padding: 10px 0;
    display: inline-block;
  }
}
section.header-hp .navbar ul.navbar-nav li.nav-item .dropdown-menu .dropdown-item {
  padding: 5px 0;
  color: #FFFFFF !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
@media (min-width: 992px) {
  section.header-hp .navbar ul.navbar-nav li.nav-item .dropdown-menu .dropdown-item {
    padding: 10px 20px;
  }
}
section.header-hp .navbar ul.navbar-nav li.nav-item .nav-link {
  color: #FFFFFF;
}
section.header-hp .card {
  border-radius: 0;
  border: 10px solid #fff;
  min-height: 450px;
}
@media (max-width: 991.98px) {
  section.header-hp .card {
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  }
}
@media (max-width: 991.98px) {
  section.header-hp .card.mb-40 {
    margin-bottom: 40px;
  }
}
section.header-hp .card .card-body {
  padding-top: 2.4rem;
}
section.header-hp .card .card-body .btn.btn-secondary {
  margin-left: 0;
  padding-left: 0;
  text-align: left;
}
section.header-hp .card .card-body .card-title {
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 32px;
  font-family: "Poppins", sans-serif;
  min-height: 80px;
  margin-bottom: 0;
}
@media (max-width: 991.98px) {
  section.header-hp .card .card-body .card-title {
    min-height: unset;
  }
}
section.header-hp .card .card-body p.card-text, section.header-hp .card .card-body .richtext.card-text {
  color: #241733;
  font-size: 1.6rem;
  line-height: 24px;
  font-family: "Poppins", sans-serif;
  height: 70px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap; /* let the text wrap preserving spaces */
}

/* Overwrite */
.navbar-toggler-icon {
  background-image: url("./../images/ico-nav.png") !important;
  background-size: 100%;
  background-position: center center;
  width: 24px;
  height: 24px;
  display: flex;
}

.navbar-toggler {
  border-color: transparent !important;
  color: transparent !important;
  width: 48px;
  height: 34px;
  background-color: #2B9540 !important;
  border-radius: 0;
}

.toggle-menu-mobile {
  width: 20px;
  height: 20px;
  transform: rotate(-90deg);
  margin-left: 8px;
  transition: all 0.5s ease;
  position: absolute;
  top: 8px;
  right: 16px;
  background: url(../images/arrow-slide-white.svg) no-repeat center;
  background-size: contain;
}
.toggle-menu-mobile.rotate {
  transform: rotate(90deg);
}

@media (max-width: 991.98px) {
  .dropdown-toggle::after {
    display: none;
  }
}

.dropdown-menu .dropdown-menu .dropdown-toggle::after {
  display: none !important;
}

.LangDropdown:hover:before {
  background: transparent !important;
  width: 0 !important;
}
.LangDropdown.active-lang:before {
  display: block;
  position: absolute;
  content: "";
  left: 2px !important;
  bottom: 2px;
  height: 2px;
  width: 100% !important;
  background: #2B9540 !important;
  transition: all 0.2s ease;
}
@media (max-width: 991.98px) {
  .LangDropdown.active-lang:before {
    background: #FFFFFF !important;
    left: 0 !important;
  }
}

footer {
  background-color: #241733;
  padding: 72px 0;
}
@media (max-width: 991.98px) {
  footer {
    padding: 40px 0;
  }
}
footer img {
  padding-bottom: 2.4rem;
}
footer span {
  font-size: 1.2rem;
  color: #ffffff;
  line-height: 150%;
  font-weight: 500;
  display: block;
}
footer span.copy {
  padding-bottom: 1.6rem;
}
@media (max-width: 767.98px) {
  footer span {
    text-align: left;
  }
}
footer div :last-child {
  margin-right: 0;
}
@media (max-width: 767.98px) {
  footer div :first-child {
    margin-left: 0;
  }
}
footer svg {
  width: 24px;
  height: 24px;
  margin: 8px;
  margin-bottom: 0;
  text-align: left;
}
footer .policy-buttons a {
  padding: 4px 8px;
  color: #62E07B;
  background: #FFFFFF;
  font-size: 12px;
  text-decoration: none;
  border-radius: 5px;
}
footer .policy-buttons a:hover {
  color: #FFFFFF !important;
  background: #62E07B !important;
}

/*main{    
    @include media-breakpoint-down(sm){
        background: rgba($primary, .2);
    }

    @include media-breakpoint-up(sm){
        background: rgba($primary, .4);
    }

    @include media-breakpoint-up(md){
          background: rgba($primary, .6);
    }

    @include media-breakpoint-up(xl){
    background: rgba($primary, .8);
    }

    @include media-breakpoint-up(xxl){
        background: rgba($primary, 1);
      }
    @include media-breakpoint-up(xxxl){
        background: rgba(yellow, 1);
      }
}*/
.light-beige {
  background-color: #F8F3E0;
}
.light-beige.r-l-60-40 {
  background: linear-gradient(to left, #F8F3E0 60%, #FBFAFC 40%);
}
.light-beige.l-r-60-40 {
  background: linear-gradient(to right, #F8F3E0 60%, #FBFAFC 40%);
}
.light-beige.l-r-50-50 {
  background: linear-gradient(to right, #F8F3E0 50%, #FBFAFC 50%);
}
.light-beige.l-r-80-20 {
  background: linear-gradient(to right, #F8F3E0 80%, #FBFAFC 20%);
}
@media (max-width: 991.98px) {
  .light-beige.l-r-80-20 {
    background: linear-gradient(to right, #F8F3E0 100%, #FBFAFC 0%);
  }
}
.light-beige.b-t-70-30 {
  background: linear-gradient(to top, #F8F3E0 70%, #FBFAFC 30%);
}
@media (max-width: 991.98px) {
  .light-beige.b-t-70-30 {
    background: linear-gradient(to top, #F8F3E0 100%, #FBFAFC 0%);
  }
}
.light-beige.t-b-50-50 {
  background: linear-gradient(to bottom, #F8F3E0 50%, #FBFAFC 50%);
}
@media (max-width: 991.98px) {
  .light-beige.t-b-50-50 {
    background: linear-gradient(to top, #F8F3E0 100%, #FBFAFC 0%);
  }
}
.light-beige.b-t-50-50 {
  background: linear-gradient(to top, #F8F3E0 50%, #FBFAFC 50%);
}
@media (max-width: 991.98px) {
  .light-beige.b-t-50-50 {
    background: linear-gradient(to top, #F8F3E0 100%, #FBFAFC 0%);
  }
}

.light-green {
  background-color: #E1EBE3;
}
.light-green.r-l-60-40 {
  background: linear-gradient(to left, #E1EBE3 60%, #FBFAFC 40%);
}
.light-green.l-r-60-40 {
  background: linear-gradient(to right, #E1EBE3 60%, #FBFAFC 40%);
}

.dark-brown {
  background-color: #42352F;
}

.beige {
  background-color: #CEC39F;
}

.white {
  background-color: #FFFFFF;
}

section.breadcrumbs {
  margin-bottom: 0;
  padding-top: 3.2rem;
  padding-bottom: 3.2rem !important;
}
@media (max-width: 991.98px) {
  section.breadcrumbs {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem !important;
  }
}
section.breadcrumbs .container .path {
  font-size: 1.6rem;
  color: #241733;
}
@media (max-width: 991.98px) {
  section.breadcrumbs .container .path {
    font-size: 1.4rem;
  }
}
section.breadcrumbs .container .path a {
  color: #241733;
  font-weight: 400;
  text-decoration: none;
}
section.breadcrumbs .container .path a:hover {
  text-decoration: underline;
}
section.breadcrumbs .container .path li:last-child a {
  font-weight: 700;
}
section.breadcrumbs .container .path svg {
  width: 16px;
  height: 16px;
  fill: #5E656A;
  margin-left: 6px;
  margin-right: 6px;
}

/*Carousel, mediumHero and miniHero*/
section.minihero {
  background-image: url("./../images/mini-header.jpg");
  height: 244px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
  box-shadow: inset 0 0 0 1000px rgba(101, 86, 61, 0.6);
  margin-bottom: 0;
}
@media (max-width: 991.98px) {
  section.minihero {
    height: 146px;
  }
}
section.minihero .row.insideHeader h1, section.minihero .row.insideHeader .h1 {
  color: #FFFFFF;
}
@media (max-width: 991.98px) {
  section.minihero .row.insideHeader h1, section.minihero .row.insideHeader .h1 {
    padding-bottom: 16px;
  }
}
section.mediumhero {
  background-image: url("./../images/medium-header.jpg");
  height: 424px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
  margin-bottom: 0;
}
section.mediumhero .row.insideHeader h1, section.mediumhero .row.insideHeader .h1 {
  color: #FFFFFF;
  margin-bottom: 40px;
}

/* Linear */
.two-boxes h2, .two-boxes .h2 {
  font-size: 3.2rem;
}
.two-boxes .white {
  padding: 40px;
}
.two-boxes.staggered.sticky .stick-txt, .two-boxes.staggered.sticky .stick-img {
  display: block;
}
.two-boxes.staggered .row div:nth-child(1) {
  margin-top: 80px !important;
}
@media (max-width: 991.98px) {
  .two-boxes.staggered .row div:nth-child(1) {
    margin-top: 40px !important;
  }
}
.two-boxes.staggered .row div:nth-child(2) {
  margin-bottom: 24px !important;
}
@media (max-width: 991.98px) {
  .two-boxes .row div .richtext, .two-boxes .row div h2, .two-boxes .row div .h2 {
    padding: 4rem 1.6rem 3.2rem;
  }
}
.two-boxes .row.flex-row-reverse .stick-txt {
  display: none;
  right: -60px;
  left: unset;
}
.two-boxes .row.flex-row-reverse .stick-img {
  display: none;
  top: -70px;
  left: -60px;
  right: unset;
}
.two-boxes .row p, .two-boxes .row .richtext {
  margin-bottom: 4rem;
}
.two-boxes .row .stick-txt {
  display: none;
  position: absolute;
  top: -70px;
  left: -60px;
  width: auto;
}
.two-boxes .row .stick-img {
  display: none;
  position: absolute;
  top: -35px;
  right: -60px;
  width: auto;
}

section.linear-two-col-floating-img {
  padding-top: 80px;
  margin-bottom: 80px !important;
}
section.linear-two-col-floating-img.pt-150 {
  padding-top: 150px;
}
section.linear-two-col-floating-img .box-title {
  padding-bottom: 3.2rem;
}
section.linear-two-col-floating-img .strip {
  font-size: 2.4rem;
  font-weight: 600;
  padding-bottom: 3.2rem;
}
section.linear-two-col-floating-img .strip img {
  width: 100% !important;
  height: auto !important;
}
section.linear-two-col-floating-img .strip div {
  padding: 20px 40px;
  text-align: left;
  font-size: 1.2vw;
}
section.linear-two-col-floating-img .strip div span {
  color: #AE8030;
}
section.linear-two-col-floating-img .blue, section.linear-two-col-floating-img .green {
  margin-right: 24px;
}
section.linear-two-col-floating-img .blue {
  display: none;
}
section.linear-two-col-floating-img.reverse {
  background: linear-gradient(to left, #EAE2C8 80%, #FBFAFC 20%);
}
section.linear-two-col-floating-img.reverse .green {
  display: none;
}
section.linear-two-col-floating-img.reverse .blue {
  display: block;
}
@media (max-width: 991.98px) {
  section.linear-two-col-floating-img.reverse {
    background: linear-gradient(to left, #EAE2C8 100%, #FBFAFC 0);
  }
}
section.linear-two-col-floating-img.reverse .row {
  flex-direction: row-reverse;
}
section.linear-two-col-floating-img .flex-row-reverse .img {
  padding-left: 0;
}
section.linear-two-col-floating-img .flex-row-reverse .img img {
  margin-left: -70px !important;
}
@media (max-width: 991.98px) {
  section.linear-two-col-floating-img .flex-row-reverse .img img {
    margin-left: unset !important;
  }
}
section.linear-two-col-floating-img .txt img {
  width: 120px;
  height: 120px;
}
section.linear-two-col-floating-img .img {
  margin-top: -180px;
}
@media (max-width: 991.98px) {
  section.linear-two-col-floating-img .img {
    margin-top: 0;
  }
}
section.linear-two-col-floating-img .richtext p {
  font-size: 2.4rem;
  line-height: 32px;
  padding-left: 0;
  padding-right: 30px;
}

.sds {
  padding-bottom: 80px;
}
@media (max-width: 991.98px) {
  .sds {
    padding-top: 40px;
  }
}
@media (max-width: 991.98px) {
  .sds .image {
    overflow: hidden;
    height: 200px;
    max-width: unset;
    width: 100%;
    display: flex;
    position: relative;
    justify-content: center;
  }
}
@media (max-width: 991.98px) {
  .sds .image img {
    width: unset !important;
    max-width: unset !important;
  }
}
.sds h2, .sds .h2 {
  padding-top: 7.2rem;
}
@media (max-width: 991.98px) {
  .sds h2, .sds .h2 {
    padding-top: 3.2rem;
    text-align: left;
  }
}
@media (max-width: 991.98px) {
  .sds p, .sds .richtext {
    text-align: left;
  }
}
.sds .box {
  max-width: 640px;
}
.sds .box p, .sds .box .richtext {
  margin-bottom: 24px;
}

section.chef-community-main {
  margin-bottom: 0;
  padding-top: 80px;
  padding-bottom: 80px;
}
@media (max-width: 991.98px) {
  section.chef-community-main {
    padding-top: 40px;
    padding-bottom: 0px;
  }
}
section.chef-community-main a {
  color: #241733;
  text-decoration: none;
}
@media (max-width: 991.98px) {
  section.chef-community-main .btn-primary {
    margin-bottom: 2.4rem;
  }
}
@media (max-width: 991.98px) {
  section.chef-community-main .btn-primary {
    margin-bottom: 3.2rem;
  }
}
section.chef-community-main .item-text {
  background: #FFFFFF;
  padding: 24px;
  min-height: 112px;
}
@media (max-width: 991.98px) {
  section.chef-community-main .item-text {
    min-height: unset;
    margin-bottom: 24px;
  }
}
section.chef-community-main .item-text p, section.chef-community-main .item-text .richtext {
  font-family: "DM Serif Display", serif;
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 0;
  padding-bottom: 0;
  line-height: 32px;
}
@media (max-width: 991.98px) {
  section.chef-community-main .item-text p, section.chef-community-main .item-text .richtext {
    padding-bottom: 0 !important;
  }
}
section.chef-community-main p, section.chef-community-main .richtext {
  padding-bottom: 4rem;
  margin-bottom: 0;
}

.slider-in-cucina {
  background: #42352F;
  padding-top: 80px;
  padding-bottom: 80px;
  margin-bottom: 0 !important;
}
@media (max-width: 991.98px) {
  .slider-in-cucina .slick-slider {
    margin-bottom: 0 !important;
  }
}
@media (max-width: 991.98px) {
  .slider-in-cucina {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media (min-width: 992px) {
  .slider-in-cucina .slick-dots {
    display: none !important;
  }
}
@media (max-width: 991.98px) {
  .slider-in-cucina .slick-dots {
    margin: 2.4rem 0;
  }
  .slider-in-cucina .slick-dots li {
    background: #FFFFFF;
  }
}
@media (max-width: 991.98px) {
  .slider-in-cucina .slick-arrow {
    display: none !important;
  }
}
@media (max-width: 991.98px) {
  .slider-in-cucina .btn-primary {
    margin-bottom: 2.4rem;
  }
}
.slider-in-cucina .richtext.medium p, .slider-in-cucina .richtext.medium .richtext {
  padding-bottom: 4rem;
  margin-bottom: 0 !important;
  max-width: 80%;
}
@media (max-width: 991.98px) {
  .slider-in-cucina .richtext.medium p, .slider-in-cucina .richtext.medium .richtext {
    padding-bottom: 2.4rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}
.slider-in-cucina .white-text {
  color: #FFFFFF;
}
.slider-in-cucina .card-item a {
  color: #241733;
  text-decoration: none;
}
.slider-in-cucina .card-item:not(:last-child) {
  margin-right: 16px;
}
.slider-in-cucina .card-item .item-text {
  background: #FFFFFF;
  padding: 24px;
}
.slider-in-cucina .card-item .item-text p, .slider-in-cucina .card-item .item-text .richtext {
  font-family: "DM Serif Display", serif;
  font-size: 2.4rem;
  line-height: 32px;
  margin-bottom: 0;
}

section.text {
  margin-bottom: 0;
}
section.text.spacer {
  padding-top: 104px;
  padding-bottom: 104px;
}

.ic-mail:before, .ic-phone:before, .ic-share:before {
  content: "";
  display: inline-block;
  margin-right: 8px;
  width: 24px;
  height: 24px;
  background-size: cover;
  vertical-align: middle;
}

.ic-mail:before {
  background: url(./../images/ic-mail.png) no-repeat center;
}

.ic-phone:before {
  background: url(./../images/ic-phone.png) no-repeat center;
}

.ic-share:before {
  background: url(./../images/ic-share.png) no-repeat center;
}

.title-text {
  padding-top: 40px;
}
.title-text.medium .richtext p, .title-text.medium .richtext {
  font-size: 2.4rem;
  line-height: 32px;
}
.title-text.foodbloggers h2, .title-text.foodbloggers .h2 {
  align-items: center;
  display: flex;
  margin-left: -61px;
}
@media (max-width: 575.98px) {
  .title-text.foodbloggers h2, .title-text.foodbloggers .h2 {
    display: block;
    margin-left: 0;
  }
}
.title-text.foodbloggers h2::before, .title-text.foodbloggers .h2::before {
  content: "";
  display: inline-block;
  width: 45px;
  height: 45px;
  background: url(./../images/yellow-hat.png) no-repeat center;
  background-size: cover;
  vertical-align: middle;
  margin-right: 16px;
}
@media (max-width: 575.98px) {
  .title-text.foodbloggers h2::before, .title-text.foodbloggers .h2::before {
    display: block;
  }
}
.title-text.appassionati h2, .title-text.appassionati .h2 {
  align-items: center;
  display: flex;
  margin-left: -61px;
}
@media (max-width: 575.98px) {
  .title-text.appassionati h2, .title-text.appassionati .h2 {
    display: block;
    margin-left: 0;
  }
}
.title-text.appassionati h2::before, .title-text.appassionati .h2::before {
  content: "";
  display: inline-block;
  width: 45px;
  height: 45px;
  background: url(./../images/green-hat.png) no-repeat center;
  background-size: cover;
  vertical-align: middle;
  margin-right: 16px;
}
@media (max-width: 575.98px) {
  .title-text.appassionati h2::before, .title-text.appassionati .h2::before {
    display: block;
  }
}
.title-text.ristoratori h2, .title-text.ristoratori .h2 {
  align-items: center;
  display: flex;
  margin-left: -61px;
}
@media (max-width: 575.98px) {
  .title-text.ristoratori h2, .title-text.ristoratori .h2 {
    display: block;
    margin-left: 0;
  }
}
.title-text.ristoratori h2::before, .title-text.ristoratori .h2::before {
  content: "";
  display: inline-block;
  width: 45px;
  height: 45px;
  background: url(./../images/blue-hat.png) no-repeat center;
  background-size: cover;
  vertical-align: middle;
  margin-right: 16px;
}
@media (max-width: 575.98px) {
  .title-text.ristoratori h2::before, .title-text.ristoratori .h2::before {
    display: block;
  }
}
.title-text.print {
  margin-bottom: 24px !important;
}
.title-text.print .btn.btn-secondary {
  width: unset;
}
.title-text.print .btn.btn-secondary::after {
  display: none;
}
.title-text.print .btn.btn-secondary span {
  margin-right: 10px;
}
.title-text.print .btn.btn-secondary svg {
  width: 20px;
  height: 20px;
}

.title-cta {
  margin-bottom: 0;
  padding-bottom: 24px;
}
.title-cta h2, .title-cta .h2 {
  padding-bottom: 0;
}

.text-cta {
  padding-bottom: 24px !important;
}

.title-text.print .btn.btn-secondary::after {
  display: none;
}
.title-text.print .btn.btn-secondary svg {
  width: 20px;
  height: 20px;
}

/* Quote */
blockquote {
  position: relative;
  color: #383838;
  font-family: "DM Serif Display", serif;
  font-size: 2.4rem;
  line-height: 32px;
  margin-top: 1rem;
  display: flex;
  width: 100%;
}
blockquote svg {
  width: 32px;
  height: 32px;
  float: right;
  position: absolute;
  bottom: 0;
  right: 4%;
  top: unset;
  margin-left: 24px;
}
blockquote svg:first-of-type {
  float: left;
  left: 0;
  top: 0;
  margin-right: 24px;
  margin-left: 0;
}
blockquote .richtext {
  float: left;
  font-size: 2.4rem;
  line-height: 32px;
  padding-left: 50px;
  padding-right: 50px;
}
blockquote .richtext p, blockquote .richtext .richtext {
  font-size: 2.4rem;
  line-height: 32px;
}
blockquote .richtext em {
  font-style: italic;
  font-size: 4rem;
}
@media (max-width: 991.98px) {
  blockquote .richtext em {
    font-size: 3.2rem;
  }
}

/* Quote plus cta */
section.quote-cta {
  padding-bottom: 4.8rem !important;
  padding-top: 4.8rem !important;
  margin-bottom: 0;
}
section.quote-cta h2, section.quote-cta .h2 {
  color: #FFFFFF;
}
section.quote-cta .text-lg-end {
  margin-bottom: 0 !important;
}
section.quote-cta .sign {
  font-size: 1.6rem;
  line-height: 24px;
  color: #FFFFFF;
}
section.quote-cta blockquote {
  margin-bottom: 0;
  margin-left: -50px;
  color: #FFFFFF;
}
@media (max-width: 1199.98px) {
  section.quote-cta blockquote {
    margin-left: 0 !important;
    display: block;
  }
}
@media (max-width: 1199.98px) {
  section.quote-cta blockquote svg {
    position: relative;
  }
  section.quote-cta blockquote svg :first-of-type {
    position: relative;
  }
}

@media (min-width: 768px) {
  section#block_content.quote-cta.dark-brown {
    margin-bottom: -350px;
  }
}

.filters-map {
  margin-bottom: 0;
}
.filters-map span {
  color: #2B9540;
  cursor: pointer;
  display: inline-block;
}
.filters-map span.pin {
  font-size: 1.8rem;
  font-weight: 600;
}
.filters-map span.pin span {
  pointer-events: none;
}
.filters-map span.pin svg {
  pointer-events: none;
  width: 24px;
  height: 24px;
  margin-left: 16px;
}
.filters-map span.pin svg.iclist {
  transform: scale(0.75);
}
.filters-map .accordion {
  width: 240px;
}
.filters-map .accordion .accordion-item .accordion-body {
  position: absolute;
  background-color: #FFFFFF;
  width: 240px;
  z-index: 1;
}
.filters-map .accordion .accordion-item .accordion-body ul li {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 28px;
  border-bottom: 1px solid #ccc;
  list-style-type: none;
  margin-bottom: 10px;
}
.filters-map .accordion .accordion-item h2, .filters-map .accordion .accordion-item .h2 {
  font-size: 1.6rem;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  background-color: transparent;
}
.filters-map .accordion .accordion-item h2 button, .filters-map .accordion .accordion-item .h2 button {
  color: #2B9540;
}
.filters-map .accordion .accordion-item h2 button.accordion-button, .filters-map .accordion .accordion-item .h2 button.accordion-button {
  padding-left: 0;
  z-index: 2;
  font-weight: 600;
  font-size: 1.8rem;
}
.filters-map .accordion .accordion-item h2 button.accordion-button:focus, .filters-map .accordion .accordion-item .h2 button.accordion-button:focus {
  box-shadow: none !important;
  border: none !important;
}
.filters-map .accordion .accordion-item h2 button.accordion-button:not(.collapsed), .filters-map .accordion .accordion-item .h2 button.accordion-button:not(.collapsed) {
  box-shadow: unset !important;
  outline: 16px solid #FFFFFF;
  background-color: #FFFFFF;
}
.filters-map .accordion .accordion-item h2 button::after, .filters-map .accordion .accordion-item .h2 button::after {
  background-image: url("./../images/green-arrow-down.png") !important;
}
.filters-map .accordion .accordion-item .accordion-collapse.collapsing .accordion-body, .filters-map .accordion .accordion-item .accordion-collapse.show .accordion-body {
  outline: 16px solid #FFFFFF;
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.6s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* Minicards, Standardcards Gallery etc */
/* Minicards */
.mini-cards .col {
  margin-top: 0;
  margin-bottom: 3.2rem;
}
.mini-cards .col .card {
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
}
.mini-cards .col .card img {
  max-width: 155px;
}
@media (max-width: 575px) {
  .mini-cards .col .card img {
    max-width: 100%;
  }
}
.mini-cards .col .card .card-body h5, .mini-cards .col .card .card-body .h5 {
  font-family: "DM Serif Display", serif;
  font-size: 2.4rem;
  line-height: 32px;
}
.mini-cards .col .card .card-body p, .mini-cards .col .card .card-body .richtext {
  font-family: "Poppins", sans-serif;
}
.mini-cards .col .card .card-body p.card-text, .mini-cards .col .card .card-body .richtext.card-text {
  font-size: 1.4rem;
  line-height: 150%;
}
.mini-cards .col .card--location {
  cursor: pointer;
  transition: all 0.2s ease;
}
.mini-cards .col .card--location:hover {
  transform: scale3d(1.04, 1.04, 1.04);
}

.standard-cards .col {
  margin-bottom: 40px;
  margin-top: 0;
}
.standard-cards.gallery .specs {
  padding-bottom: 1.6rem !important;
}
.standard-cards.gallery.int p, .standard-cards.gallery.int .richtext {
  display: block !important;
}
.standard-cards.gallery .card .card-body p, .standard-cards.gallery .card .card-body .richtext {
  display: none;
}
.standard-cards.gallery .card .card-body .specs span {
  color: #5E656A;
  font-family: "Lora", serif;
  font-size: 1.4rem;
  font-weight: 600;
}
.standard-cards .card {
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
}
.standard-cards .card .card-body {
  padding-top: 1.6rem;
  padding-bottom: 4.8rem;
}
.standard-cards .card .card-body .specs {
  color: #AE8030;
  font-size: 1rem;
  padding-bottom: 2.4rem;
}
.standard-cards .card .card-body .specs span {
  margin-top: 2px;
}
.standard-cards .card .card-body .specs p, .standard-cards .card .card-body .specs .richtext {
  display: none;
}
.standard-cards .card .card-body .specs svg {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
.standard-cards .card .card-body h5, .standard-cards .card .card-body .h5 {
  font-family: "DM Serif Display", serif;
  font-size: 2.4rem;
  line-height: 32px;
  min-height: 100px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap;
  padding-bottom: 0;
  margin-bottom: 3.2rem;
}
.standard-cards .card .card-body p, .standard-cards .card .card-body .richtext {
  font-family: "Poppins", sans-serif;
}
.standard-cards .card .card-body p.card-text, .standard-cards .card .card-body .richtext.card-text {
  font-size: 1.4rem;
  /* omologata alle mini cards, chiedere a Ilaria */
  line-height: 150%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap;
}

.card a {
  color: #241733;
  text-decoration: none;
}

.triple-images {
  padding: 2.4rem 0;
}
@media (max-width: 767px) {
  .triple-images img {
    margin-bottom: 24px;
  }
}

section.chef-community-main {
  margin-bottom: 0;
  padding-top: 80px;
  padding-bottom: 80px;
}
@media (max-width: 991.98px) {
  section.chef-community-main {
    padding-top: 40px;
    padding-bottom: 0px;
  }
}
section.chef-community-main a {
  color: #241733;
  text-decoration: none;
}
@media (max-width: 991.98px) {
  section.chef-community-main .btn-primary {
    margin-bottom: 2.4rem;
  }
}
@media (max-width: 991.98px) {
  section.chef-community-main .btn-primary {
    margin-bottom: 3.2rem;
  }
}
section.chef-community-main .item-text {
  background: #FFFFFF;
  padding: 24px;
  min-height: 112px;
}
@media (max-width: 991.98px) {
  section.chef-community-main .item-text {
    min-height: unset;
    margin-bottom: 24px;
  }
}
section.chef-community-main .item-text p, section.chef-community-main .item-text .richtext {
  font-family: "DM Serif Display", serif;
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 0;
  padding-bottom: 0;
  line-height: 32px;
}
@media (max-width: 991.98px) {
  section.chef-community-main .item-text p, section.chef-community-main .item-text .richtext {
    padding-bottom: 0 !important;
  }
}
section.chef-community-main p, section.chef-community-main .richtext {
  padding-bottom: 4rem;
  margin-bottom: 0;
}

.slider-eventi-news {
  padding-top: 80px;
}
.slider-eventi-news .col .card-body {
  padding-top: 0;
  padding-bottom: 2.4rem;
}
.slider-eventi-news .col .card-body .btn-secondary {
  margin-left: -36px;
}
.slider-eventi-news .col .card-body .specs {
  padding: 16px 0;
  font-family: "Lora", serif;
  font-size: 1.4rem;
}
.slider-eventi-news .col .card-body .card-title {
  font-size: 2.4rem;
  font-family: "DM Serif Display", serif;
  line-height: 120%;
  min-height: 60px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap;
}
.slider-eventi-news .col .card-body .card-text {
  min-height: 72px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap;
}
.slider-eventi-news .col .news {
  padding: 2.4rem;
  background: linear-gradient(0.2deg, rgba(95, 60, 0, 0.8) 26.3%, rgba(255, 255, 255, 0.4) 93.85%), url(./../images/miele.jpg) no-repeat center;
  background-size: cover;
  min-height: 575px;
}
.slider-eventi-news .col .news .slide-data {
  font-family: "Lora", serif;
  font-size: 1.8rem;
  margin: 0;
  padding-bottom: 16px;
}
.slider-eventi-news .col .news p.medium, .slider-eventi-news .col .news .richtext.medium {
  font-family: "DM Serif Display", serif;
  padding-bottom: 2.4rem;
}
.slider-eventi-news .col .news button {
  margin-left: -36px;
}
.slider-eventi-news .col .news button.white {
  color: #fff;
}
.slider-eventi-news .col .news button.white::after {
  background: url(./../images/cta-white-arrow.png) no-repeat center;
}
.slider-eventi-news .col .min-h {
  min-height: 575px;
}
.slider-eventi-news .slick-slider {
  padding-bottom: 7.2rem;
}
.slider-eventi-news .slide-item-container {
  padding: 40px;
  background: linear-gradient(0.2deg, rgba(95, 60, 0, 0.8) 26.3%, rgba(255, 255, 255, 0.4) 93.85%), url(./../images/miele.jpg) no-repeat center;
  background-size: cover;
  min-height: 433px;
}
.slider-eventi-news .slide-item-container:not(:last-child) {
  margin-right: 30px;
}
.slider-eventi-news .slide-item-container .slide-price {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  font-family: "Lora", serif;
}

.team .col {
  margin-top: 0;
}
.team .col .card-body {
  padding-top: 0 !important;
}
.team p, .team .richtext {
  padding-bottom: 3.2rem;
  margin-bottom: unset;
}
.team .title {
  font-size: 2.4rem;
  font-weight: 700;
}
.team .card {
  background-color: transparent;
  border: 0;
}
.team .card .card-body {
  padding: 3.2rem 0;
}
.team .card .card-body h5, .team .card .card-body .h5 {
  font-family: "Poppins", sans-serif;
  font-size: 1.4rem;
  line-height: 150%;
  font-weight: 700;
  margin-bottom: 8px;
}
.team .card .card-body p, .team .card .card-body .richtext {
  font-family: "Poppins", sans-serif;
}
.team .card .card-body p.card-text, .team .card .card-body .richtext.card-text {
  font-size: 1.4rem;
  line-height: 150%;
  font-style: italic;
  padding-bottom: 0;
}

.search .title {
  font-size: 1.6rem;
  color: #5E656A;
  line-height: 24px;
  height: 24px;
  margin-bottom: 8px;
}
.search input,
.search select {
  height: 50px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: #241733;
  position: relative;
  background-color: #FFFFFF !important;
}
.search label {
  color: #241733;
}
.search input.search-input {
  content: "";
  display: block;
  background: url("../../images/ico-search.png");
  background-repeat: no-repeat;
  background-position: 12px center;
  z-index: 1000;
  padding-left: 50px !important;
}
.search .btn.btn-primary {
  text-align: center;
  width: 100%;
}
.search .btn.btn-primary:hover {
  text-decoration: underline;
}
.search .btn.btn-primary:after {
  display: none;
}

.contact-form {
  padding-top: 0;
}
.contact-form .social div {
  margin-left: 32px;
}
.contact-form .social svg {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}
.contact-form.accreditamento {
  padding-top: 104px;
}
.contact-form.accreditamento.no-txt p.medium, .contact-form.accreditamento.no-txt .richtext.medium {
  display: none;
}
.contact-form .form-floating {
  padding-bottom: 3.2rem;
}
.contact-form p.medium, .contact-form .richtext.medium {
  padding-bottom: 4rem;
}
.contact-form p.info, .contact-form .richtext.info {
  padding-bottom: 7.2rem;
}
.contact-form form .form-floating .form-control:not(:placeholder-shown) ~ label {
  transform: scale(0.85) translateY(-0.9rem) translateX(0.15rem);
}
.contact-form form .form-floating input,
.contact-form form .form-floating textarea {
  background: transparent;
  border: 1px solid #241733;
  border-radius: 0;
}
.contact-form form .form-floating input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #E1EBE3 inset !important;
}
.contact-form form .form-floating textarea {
  min-height: 140px !important;
}
.contact-form form .form-floating label {
  height: auto;
  left: 24px;
  padding-left: 0;
}
.contact-form form .form-floating .form-control {
  font-family: "Poppins", sans-serif;
  min-height: 48px;
  padding-left: 24px;
  padding-right: 24px;
}
.contact-form form .form-floating .form-control:focus {
  border: 1px solid #241733;
  box-shadow: none;
}
.contact-form form .form-floating label.error {
  transform: none !important;
  color: red;
  font-size: 13px;
  bottom: 0;
  top: auto;
}
.contact-form form input[type=file] {
  color: #79947E;
  font-size: 13px;
  line-height: 24px;
  font-style: italic;
  cursor: pointer;
}
.contact-form form input[type=file]::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url(./../images/ic-allega-file.png) no-repeat center;
  background-size: cover;
  vertical-align: middle;
}
.contact-form form input[type=file]::file-selector-button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: #2B9540;
  font-size: 16px;
  line-height: 28px;
  font-style: normal;
  font-weight: 600;
}
.contact-form form .form-check-input {
  background-color: transparent;
  border: 1px solid #241733;
  box-shadow: none;
}
.contact-form form .form-check-input:checked {
  background-color: #2B9540;
  border: 1px solid #2B9540;
}
.contact-form form .btn-secondary {
  width: auto;
}

.list-link {
  padding-top: 104px;
}
.list-link p.medium, .list-link .richtext.medium {
  padding-bottom: 4rem;
}
.list-link .link-col a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  border-bottom: 1px solid #2B9540;
  text-decoration: none;
}
.list-link .link-col a p, .list-link .link-col a .richtext {
  display: inline-block;
  font-weight: 600;
  padding: 8px 0;
  text-decoration: none;
}
.list-link .link-col a img {
  max-height: 24px;
  margin-right: 8px;
  transition: all 0.5s ease;
}
.list-link .link-col a:hover img {
  margin-right: 0px;
  transition: all 0.5s ease;
}
.list-link .link-col a:hover p, .list-link .link-col a:hover .richtext {
  text-decoration: underline;
}

.mapOnclick {
  /* pin */
  /* modalbox specifici */
}
.mapOnclick .sds-title {
  position: absolute;
  top: -160px;
  left: 50%;
  transform: translateX(-50%);
  font-family: "DM Serif Display", serif;
  font-size: 2.4rem;
  line-height: 32px;
}
.mapOnclick .pointer {
  cursor: pointer;
}
.mapOnclick .positioning {
  width: fit-content;
}
.mapOnclick .close-button {
  position: absolute;
  top: -15px;
  right: -15px;
  border-radius: 50%;
  background-color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-weight: 700;
  box-shadow: -3px 3px 2px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.mapOnclick .legend .title {
  font-size: 2.4rem;
  line-height: 32px;
  font-family: "DM Serif Display", serif;
  padding-bottom: 2.4rem;
}
.mapOnclick .legend .item {
  font-size: 1.6rem;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 16px;
}
.mapOnclick .legend .item img {
  margin-right: 16px;
}
.mapOnclick .villasimius img, .mapOnclick .villasimius svg {
  bottom: 11%;
  right: 16%;
}
.mapOnclick .sestu img, .mapOnclick .sestu svg {
  bottom: 18%;
  right: 42%;
}
.mapOnclick .villaspeciosa img, .mapOnclick .villaspeciosa svg {
  bottom: 17%;
  right: 57%;
}
.mapOnclick .ortacesus img, .mapOnclick .ortacesus svg {
  bottom: 29%;
  right: 31%;
}
.mapOnclick .silius img, .mapOnclick .silius svg {
  bottom: 24%;
  right: 24%;
}
.mapOnclick .dolianova img, .mapOnclick .dolianova svg {
  bottom: 22%;
  right: 35%;
}
.mapOnclick .cardedu img, .mapOnclick .cardedu svg {
  bottom: 43%;
  right: 12%;
}
.mapOnclick .tertenia img, .mapOnclick .tertenia svg {
  bottom: 36%;
  right: 16%;
}
.mapOnclick .carbonia img, .mapOnclick .carbonia svg {
  bottom: 10%;
  right: 73%;
}
.mapOnclick .oristano img, .mapOnclick .oristano svg {
  bottom: 40%;
  right: 70%;
}
.mapOnclick .marrubiu img, .mapOnclick .marrubiu svg {
  bottom: 34%;
  right: 66%;
}
.mapOnclick .macomer img, .mapOnclick .macomer svg {
  bottom: 49%;
  right: 61%;
}
.mapOnclick .bortigali img, .mapOnclick .bortigali svg {
  bottom: 56%;
  right: 58%;
}
.mapOnclick .mamoiada img, .mapOnclick .mamoiada svg {
  bottom: 54%;
  right: 32%;
}
.mapOnclick .oliena img, .mapOnclick .oliena svg {
  bottom: 58%;
  right: 18%;
}
.mapOnclick .dorgali img, .mapOnclick .dorgali svg {
  bottom: 60%;
  right: 6%;
}
.mapOnclick .oschiri img, .mapOnclick .oschiri svg {
  bottom: 84%;
  right: 28%;
}
.mapOnclick .budduso img, .mapOnclick .budduso svg {
  bottom: 79%;
  right: 20%;
}
.mapOnclick .mores img, .mapOnclick .mores svg {
  bottom: 70%;
  right: 61%;
}
.mapOnclick .ittiri img, .mapOnclick .ittiri svg {
  bottom: 72%;
  right: 74%;
}
.mapOnclick .thiesi img, .mapOnclick .thiesi svg {
  bottom: 67%;
  right: 69%;
}
.mapOnclick .modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mapOnclick .list {
  cursor: pointer;
  font-size: 1.6rem;
  line-height: 28px;
  font-weight: 600;
  text-transform: capitalize;
  color: #2B9540;
  text-align: center;
}
.mapOnclick .list:hover {
  text-decoration: underline;
}
.mapOnclick .boxMap {
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
  max-width: 350px;
}
.mapOnclick .boxMap .item {
  padding: 24px;
  text-align: left;
}
.mapOnclick .boxMap p, .mapOnclick .boxMap .richtext {
  font-size: 2.4rem;
  line-height: 32px;
  font-family: "DM Serif Display", serif;
  text-transform: capitalize;
}
.mapOnclick .boxMap span {
  display: block;
}
.mapOnclick .boxMap img {
  height: 205px;
}

section.chef-map .infos-map h2.medium, section.chef-map .infos-map .medium.h2 {
  padding-bottom: 16px;
}
section.chef-map .infos-map ul li {
  padding-bottom: 2.4rem;
}
section.chef-map .infos-map ul li p.fw-bold, section.chef-map .infos-map ul li .richtext.fw-bold {
  padding-bottom: 0.8rem;
}
section.chef-map .infos-map ul li p.fw-italic, section.chef-map .infos-map ul li .richtext.fw-italic {
  padding-bottom: 0.8rem;
}
section.chef-map .infos-map p.fw-bold, section.chef-map .infos-map .richtext.fw-bold {
  padding-bottom: 4rem;
}
section.chef-map .infos-map p.medium, section.chef-map .infos-map .richtext.medium {
  padding-bottom: 4rem;
  margin-bottom: 0;
}

.double-images {
  position: relative;
}
.double-images .row {
  padding-bottom: 4rem;
}
.double-images:after {
  content: "";
  display: block;
  width: 60%;
  height: 70%;
  background-color: #E1EBE3;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
@media (max-width: 992px) {
  .double-images {
    background: #E1EBE3;
  }
  .double-images:after {
    display: none;
  }
}

.triple-cards-elements {
  position: relative;
  padding-bottom: 40px;
  margin-bottom: 40px;
}
@media (max-width: 992px) {
  .triple-cards-elements .card-container {
    margin-bottom: 40px;
  }
}
.triple-cards-elements .card-container .card-img {
  position: relative;
  min-width: 280px;
  background: white;
  min-height: 280px;
  margin-bottom: 16px;
}
.triple-cards-elements .card-container .card-img img {
  max-width: 160px;
}
.triple-cards-elements .card-container .card-number {
  font-family: "DM Serif Display", serif;
  color: #AE8030;
  font-size: 78px;
  line-height: 120%;
  position: absolute;
  bottom: -20px;
  left: -50px;
}
@media (max-width: 1200px) {
  .triple-cards-elements .card-container .card-number {
    font-size: 56px;
    left: 8px;
    bottom: 8px;
  }
}
@media (max-width: 768px) {
  .triple-cards-elements .card-container .card-number {
    font-size: 64px;
    left: 24px;
    bottom: 24px;
  }
}
.triple-cards-elements .card-container .card-p {
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  margin-bottom: 24px;
}

section.cards-green {
  margin-bottom: 80px;
}
@media (min-width: 992px) {
  section.cards-green .container.box {
    padding: 0;
    margin: 0 auto;
  }
}

.cards-green h2, .cards-green .h2 {
  padding-bottom: 1.6rem;
}
.cards-green p, .cards-green .richtext {
  line-height: unset;
  padding-bottom: 3.2rem;
  margin-bottom: 0;
}
.cards-green.rules .slider-cards-green .slick-track {
  display: flex !important;
}
.cards-green.rules .slider-cards-green .slick-slide {
  height: inherit !important;
}
.cards-green.rules .slider-cards-green .card-valori {
  position: relative;
}
.cards-green.rules .slider-cards-green .card-valori h2, .cards-green.rules .slider-cards-green .card-valori .h2 {
  display: none;
}
.cards-green.rules .slider-cards-green .card-valori h3, .cards-green.rules .slider-cards-green .card-valori .h3 {
  padding: 0 16px;
}
.cards-green.rules .slider-cards-green .card-valori .richtext {
  display: block;
  padding: 2.4rem;
  padding-bottom: 0;
}
.cards-green.rules .slider-cards-green .card-valori .valori-extra {
  display: none;
}
.cards-green .slider-cards-green {
  margin-bottom: 0;
}
.cards-green .slider-cards-green .slick-track {
  display: flex !important;
}
.cards-green .slider-cards-green .slick-slide {
  height: inherit !important;
}
.cards-green .slider-cards-green.unslick {
  margin-left: 0;
  margin-right: 0;
}
@media (max-width: 992px) {
  .cards-green .slider-cards-green.unslick {
    width: auto !important;
  }
}
.cards-green .slider-cards-green.unslick .card-valori {
  width: calc(33.3% - 16px);
}
.cards-green .slider-cards-green.unslick .card-valori:not(:last-child) {
  margin-right: auto !important;
}
@media (max-width: 992px) {
  .cards-green .slider-cards-green.unslick .card-valori {
    width: 100% !important;
    margin-right: 0 !important;
    margin-bottom: 24px;
  }
}
.cards-green .slider-cards-green.unslick .card-valori:last-child {
  margin-right: 0 !important;
  width: 33.3%;
}
.cards-green .slider-cards-green.unslick .card-valori h2, .cards-green .slider-cards-green.unslick .card-valori .h2 {
  display: none;
}
.cards-green .slider-cards-green.unslick .card-valori img {
  min-height: 80px;
}
.cards-green .slider-cards-green.unslick .card-valori p, .cards-green .slider-cards-green.unslick .card-valori .richtext {
  display: block;
  padding: 2.4rem;
}
.cards-green .slider-cards-green.unslick .card-valori p.fst-italic, .cards-green .slider-cards-green.unslick .card-valori .richtext.fst-italic {
  display: none !important;
}
.cards-green .slider-cards-green .slick-dots {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  bottom: 0;
  margin: auto;
}
.cards-green .slider-cards-green .card-valori {
  min-height: 383px;
  padding: 4.8rem 0;
}
@media (max-width: 991.98px) {
  .cards-green .slider-cards-green .card-valori {
    min-height: 366px;
  }
}
.cards-green .slider-cards-green .card-valori:not(:last-child) {
  margin-right: 16px;
}
.cards-green .slider-cards-green .card-valori img {
  max-width: 80px;
  padding-bottom: 24px;
}
.cards-green .slider-cards-green .card-valori h3, .cards-green .slider-cards-green .card-valori .h3 {
  font-weight: 600;
}
.cards-green .slider-cards-green .card-valori .valori-extra {
  font-size: 14px;
  height: 0;
  margin: 0;
}
.cards-green .slider-cards-green .card-valori .richtext {
  display: none;
}
.cards-green .slider-cards-green .card-valori .valori-extra {
  display: block;
}

.slider-full .slick-slider {
  margin-bottom: 0;
}
@media (max-width: 991.98px) {
  .slider-full .slick-slider .slick-arrow {
    display: none !important;
  }
}
.slider-full .slick-slide {
  position: relative;
}
.slider-full .slick-dots {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  bottom: 0;
  margin: auto;
}
.slider-full .full-slide-text {
  max-width: 40%;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 6.4rem;
}
@media (max-width: 768px) {
  .slider-full .full-slide-text {
    max-width: 100%;
    margin: 2.4rem;
  }
}
@media (max-width: 768px) {
  .slider-full .full-slide-text h2, .slider-full .full-slide-text .h2 {
    font-size: 18px;
  }
}

section.hero-plus-triple-cards {
  background-image: url(./../images/hero-plus-triple.jpg);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 640px;
}
@media (max-width: 991.98px) {
  section.hero-plus-triple-cards {
    background-position: center center;
  }
}
section.triple-cards {
  margin-top: -360px;
}
@media (max-width: 991.98px) {
  section.triple-cards .col {
    margin-bottom: 24px;
  }
}
section.triple-cards .col:last-child {
  margin-bottom: 0;
}
section.triple-cards .w-scale {
  width: 75%;
  margin-top: 72px;
  margin-bottom: 48px;
}
@media (max-width: 991.98px) {
  section.triple-cards .w-scale {
    width: 100%;
  }
}
section.triple-cards .card {
  border-radius: 0;
  border: 10px solid #fff;
  min-height: 450px;
}
@media (max-width: 991.98px) {
  section.triple-cards .card {
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  }
}
@media (max-width: 991.98px) {
  section.triple-cards .card.mb-40 {
    margin-bottom: 40px;
  }
}
section.triple-cards .card .card-body {
  padding-top: 2.4rem;
}
section.triple-cards .card .card-body .btn.btn-secondary {
  margin-left: 0;
  padding-left: 0;
  text-align: left;
}
section.triple-cards .card .card-body .card-title {
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 32px;
  padding-bottom: 24px;
  font-family: "Poppins", sans-serif;
  min-height: 80px;
  margin-bottom: 0;
}
@media (max-width: 991.98px) {
  section.triple-cards .card .card-body .card-title {
    min-height: unset;
  }
}
section.triple-cards .card .card-body p.card-text, section.triple-cards .card .card-body .richtext.card-text {
  color: #241733;
  font-size: 1.6rem;
  line-height: 24px;
  margin-bottom: 24px;
  font-family: "Poppins", sans-serif;
  height: 70px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap; /* let the text wrap preserving spaces */
}

section.title {
  margin-bottom: 0 !important;
}
section.title h2, section.title .h2 {
  margin-bottom: 0;
}
section.title.medium h2, section.title.medium .h2 {
  font-size: 3.2rem;
  line-height: 40px;
  font-weight: 400;
}

.details .row:nth-child(1) {
  padding-bottom: 7.2rem;
}
.details h2, .details .h2 {
  font-size: 2.4rem;
  line-height: 120%;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
.details p, .details .richtext {
  font-size: 1.6rem;
  line-height: 24px;
}
.details ul {
  margin-left: 20px;
}
.details ul.icon {
  margin-left: 0;
}
.details ul.icon li {
  list-style-type: none;
  margin-bottom: 8px;
}
.details ul.icon li svg {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.details ul li {
  list-style-type: disc;
}
.details .author {
  font-family: "Lora", serif;
  font-size: 1.8rem;
  font-weight: 600;
  color: #5E656A;
  margin-right: 16px;
}
.details .share svg {
  width: 20px;
  height: 20px;
}
.details .max-w-830 {
  max-width: 830px;
}

@media (max-width: 768px) {
  #modalSentieriSorriso .modal-content {
    background: linear-gradient(to top, #FBFAFC 80%, #FFF2C0 20%);
  }
}
#modalSentieriSorriso .modal-content .modal-title {
  background-color: #FFF2C0;
  padding: 56px;
  padding-bottom: 16px;
}
@media (max-width: 768px) {
  #modalSentieriSorriso .modal-content .modal-title {
    padding: 16px;
  }
}
#modalSentieriSorriso .modal-content .modal-title .modal-header {
  padding: 0;
}
#modalSentieriSorriso .modal-content .modal-title .modal-header h1, #modalSentieriSorriso .modal-content .modal-title .modal-header .h1 {
  margin-bottom: 8px;
}
@media (max-width: 768px) {
  #modalSentieriSorriso .modal-content .modal-title .modal-header h1, #modalSentieriSorriso .modal-content .modal-title .modal-header .h1 {
    font-size: 32px;
  }
}
#modalSentieriSorriso .modal-content .modal-title .text-animation {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
#modalSentieriSorriso .modal-content .modal-title .text-animation .provincia {
  align-items: center;
}
#modalSentieriSorriso .modal-content .modal-title svg {
  max-width: 75px;
  max-height: 75px;
  z-index: 1;
}
#modalSentieriSorriso .modal-content .modal-title .slide-text {
  background: #CC7F32;
  margin-left: -10px;
  margin-right: 16px;
  width: 360px;
  min-height: 38px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 24px;
  color: #fff;
  animation: slide 1.5s ease;
  animation-delay: 0.5s;
  animation-fill-mode: backwards;
  clip-path: polygon(95% 0, 100% 50%, 95% 100%, 0 100%, 0 0);
}
@media (max-width: 768px) {
  #modalSentieriSorriso .modal-content .modal-title .slide-text {
    clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0 100%, 0 0);
    margin-left: -20px;
    width: 100%;
    justify-content: center;
  }
}
#modalSentieriSorriso .modal-content .modal-title .slide-text p, #modalSentieriSorriso .modal-content .modal-title .slide-text .richtext {
  margin: 0;
  font-weight: 700;
  white-space: nowrap;
}
#modalSentieriSorriso .modal-content .modal-title h2, #modalSentieriSorriso .modal-content .modal-title .h2 {
  animation: fadeInTitle 2s ease;
  animation-delay: 2s;
  animation-fill-mode: backwards;
  color: #CC7F32;
}
#modalSentieriSorriso .modal-content .modal-title h2.comune, #modalSentieriSorriso .modal-content .modal-title .comune.h2 {
  padding: 0;
}
@media (max-width: 991px) {
  #modalSentieriSorriso .modal-content .modal-title h2, #modalSentieriSorriso .modal-content .modal-title .h2 {
    width: 100%;
    text-align: end;
  }
}
@keyframes fadeInTitle {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slide {
  0% {
    width: 0;
    opacity: 0;
  }
  100% {
    width: 360px;
    opacity: 1;
  }
}
#modalSentieriSorriso .modal-content .main-modal {
  padding: 56px;
  background: linear-gradient(to left, #FBFAFC 70%, #FFF2C0 30%);
}
@media (max-width: 768px) {
  #modalSentieriSorriso .modal-content .main-modal {
    background: transparent;
    padding: 0;
  }
}
#modalSentieriSorriso .modal-content .main-modal .map {
  transform: translateY(-65px);
}
@media (max-width: 768px) {
  #modalSentieriSorriso .modal-content .main-modal .map {
    transform: translateY(0);
    margin-bottom: 24px;
  }
}
#modalSentieriSorriso .modal-content .main-modal .card-dop {
  max-width: 100px;
}
#modalSentieriSorriso .modal-content .main-modal h2, #modalSentieriSorriso .modal-content .main-modal .h2 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
}
#modalSentieriSorriso .modal-content .main-modal ol {
  list-style: none;
}
#modalSentieriSorriso .modal-content .main-modal ol .icon {
  position: relative;
  margin-left: 32px;
  counter-increment: item;
}
#modalSentieriSorriso .modal-content .main-modal ol .icon:before {
  content: counter(item) "-";
  font-weight: 700;
  position: absolute;
  top: 0;
  left: -24px;
}
#modalSentieriSorriso .modal-content .main-modal ol .icon:after {
  content: "";
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background: url(./../images/ic-monumenti.png) no-repeat center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: -56px;
}
#modalSentieriSorriso .modal-content .main-modal .info-list li {
  margin-bottom: 16px;
}
#modalSentieriSorriso .modal-content .main-modal .info-list li .icon-top {
  max-width: 30px;
  height: auto;
  margin-right: 16px;
}
#modalSentieriSorriso .modal-content .main-modal .info-list li p, #modalSentieriSorriso .modal-content .main-modal .info-list li .richtext {
  margin: 0;
}
#modalSentieriSorriso .modal-content .main-modal .list-style {
  padding-left: 2rem;
}
#modalSentieriSorriso .modal-content .main-modal .list-style li {
  list-style: inherit;
}

.ic-yellow .color-ic {
  fill: #FFB800;
}
.ic-yellow .slide-text {
  background: #FFB800 !important;
}
.ic-yellow .comune {
  color: #FFB800 !important;
}

.ic-pink .color-ic {
  fill: #DD5BA1;
}
.ic-pink .slide-text {
  background: #DD5BA1 !important;
}
.ic-pink .comune {
  color: #DD5BA1 !important;
}

.ic-green .color-ic {
  fill: #62E07B;
}
.ic-green .slide-text {
  background: #62E07B !important;
}
.ic-green .comune {
  color: #62E07B !important;
}

.ic-red .color-ic {
  fill: #E83851;
}
.ic-red .slide-text {
  background: #E83851 !important;
}
.ic-red .comune {
  color: #E83851 !important;
}

.ic-blue .color-ic {
  fill: #6D8BC5;
}
.ic-blue .slide-text {
  background: #6D8BC5 !important;
}
.ic-blue .comune {
  color: #6D8BC5 !important;
}

.ic-brown .color-ic {
  fill: #CC7F32;
}
.ic-brown .slide-text {
  background: #CC7F32 !important;
}
.ic-brown .comune {
  color: #CC7F32 !important;
}

.ic-gold .color-ic {
  fill: #DCA63C;
}
.ic-gold .slide-text {
  background: #DCA63C !important;
}
.ic-gold .comune {
  color: #DCA63C !important;
}

.ic-bordeaux .color-ic {
  fill: #A61D30;
}
.ic-bordeaux .slide-text {
  background: #A61D30 !important;
}
.ic-bordeaux .comune {
  color: #A61D30 !important;
}

.chef-community-card-list .col {
  margin-bottom: 40px;
  margin-top: 0;
}
.chef-community-card-list .col .card {
  background-color: transparent;
  border: 0;
}
@media (max-width: 991px) {
  .chef-community-card-list .col .card {
    margin-bottom: 24px;
  }
}
.chef-community-card-list .col .card .card-body {
  padding: 16px 16px 16px 0;
}
.chef-community-card-list .col .card .card-body .card-title {
  font-size: 2.4rem;
  font-weight: 400;
  font-family: "DM Serif Display", serif;
  margin-bottom: 0;
}
.chef-community-card-list .col .card a {
  color: #2B9540;
}
.chef-community-card-list .col .card a:hover {
  text-decoration: underline;
}

.chef-community-blog {
  background: linear-gradient(to left, #F8F3E0 50%, #FBFAFC 50%);
  background-size: 100% 70%;
  background-repeat: no-repeat;
  margin-bottom: 0;
}
.chef-community-blog h2, .chef-community-blog .h2 {
  padding-bottom: 1.6rem;
}
.chef-community-blog h4, .chef-community-blog .h4 {
  padding-bottom: 2.4rem;
  font-weight: 600;
}
.chef-community-blog .tagline {
  padding-bottom: 3.2rem;
  display: block;
}
.chef-community-blog.foodbloggers h2, .chef-community-blog.foodbloggers .h2 {
  align-items: center;
  display: flex;
  margin-left: -61px;
}
@media (max-width: 575.98px) {
  .chef-community-blog.foodbloggers h2, .chef-community-blog.foodbloggers .h2 {
    display: block;
    margin-left: 0;
  }
}
.chef-community-blog.foodbloggers h2::before, .chef-community-blog.foodbloggers .h2::before {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  background: url(./../images/yellow-hat.png) no-repeat center;
  background-size: contain;
  vertical-align: middle;
  margin-right: 16px;
}
@media (max-width: 575.98px) {
  .chef-community-blog.foodbloggers h2::before, .chef-community-blog.foodbloggers .h2::before {
    display: block;
  }
}
.chef-community-blog.appassionati h2, .chef-community-blog.appassionati .h2 {
  align-items: center;
  display: flex;
  margin-left: -61px;
}
@media (max-width: 575.98px) {
  .chef-community-blog.appassionati h2, .chef-community-blog.appassionati .h2 {
    display: block;
    margin-left: 0;
  }
}
.chef-community-blog.appassionati h2::before, .chef-community-blog.appassionati .h2::before {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  background: url(./../images/green-hat.png) no-repeat center;
  background-size: contain;
  vertical-align: middle;
  margin-right: 16px;
}
@media (max-width: 575.98px) {
  .chef-community-blog.appassionati h2::before, .chef-community-blog.appassionati .h2::before {
    display: block;
  }
}
.chef-community-blog.ristoratori h2, .chef-community-blog.ristoratori .h2 {
  align-items: center;
  display: flex;
  margin-left: -61px;
}
@media (max-width: 575.98px) {
  .chef-community-blog.ristoratori h2, .chef-community-blog.ristoratori .h2 {
    display: block;
    margin-left: 0;
  }
}
.chef-community-blog.ristoratori h2::before, .chef-community-blog.ristoratori .h2::before {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  background: url(./../images/blue-hat.png) no-repeat center;
  background-size: contain;
  vertical-align: middle;
  margin-right: 16px;
}
@media (max-width: 575.98px) {
  .chef-community-blog.ristoratori h2::before, .chef-community-blog.ristoratori .h2::before {
    display: block;
  }
}
.chef-community-blog .share {
  position: absolute;
  top: 0;
  right: 0;
  width: auto !important;
}
.chef-community-blog .share svg {
  width: 20px;
  height: 20px;
}
.chef-community-blog .blog {
  display: flex;
  align-items: center;
}
.chef-community-blog .blog .btn.btn-secondary {
  margin-bottom: 16px;
  width: auto;
  display: flex;
  align-items: center;
}
.chef-community-blog .blog .btn.btn-secondary span {
  display: block;
  margin-right: 30px;
  font-family: "Poppins", sans-serif;
}
.chef-community-blog .blog span {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 28px;
  color: #2B9540;
}
.chef-community-blog h3, .chef-community-blog .h3 {
  font-size: 2.4rem;
  line-height: 120%;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 16px;
}
.chef-community-blog .max-w-830 {
  max-width: 830px;
}
.chef-community-blog span {
  font-size: 1.2rem;
  font-family: "Lora", serif;
  color: #5E656A;
}
.chef-community-blog .standard-cards .card-valori {
  margin-right: 16px;
}
.chef-community-blog .standard-cards .card-valori .card {
  border: 0;
  box-shadow: none;
}
.chef-community-blog .standard-cards .card-valori .card .card-body {
  min-height: 300px;
}
.chef-community-blog .standard-cards .card-valori .card .card-body .btn.btn-secondary {
  margin-left: -36px;
}
.chef-community-blog .standard-cards .card-valori .card span {
  color: #AE8030;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
}

.pagination .page-numbers {
  display: flex;
  justify-content: flex-end;
}
.pagination ul {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  padding: 0;
}
.pagination ul li {
  display: flex;
  margin: 0 8px;
}
.pagination ul li.current-page {
  justify-content: center;
  align-items: center;
  background: #2B9540;
  border-radius: 50%;
  width: 26px;
  height: 26px;
}
.pagination ul li.current-page a {
  color: #FFFFFF;
}
.pagination ul li a {
  text-align: center;
  color: #241733;
  width: 26px;
  text-decoration: none;
}
.pagination ul .d-inline {
  display: inline;
}
.pagination .previous, .pagination .next {
  display: inline-block;
  border: none;
  background: #FBFAFC;
}

.chef-community-suggested .slider-suggested .card-valori .title {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 32px;
}
.chef-community-suggested .slider-suggested .card-valori .title span {
  display: block;
  font-weight: 400;
  font-style: italic;
}
.chef-community-suggested .slider-suggested .card-valori:not(:last-child) {
  margin-right: 16px;
}
.chef-community-suggested .slider-suggested .card-valori img {
  width: 100%;
}

#modalGallery .wrap-modal-slider {
  transition: all 0.5s ease;
  opacity: 0;
}
#modalGallery .wrap-modal-slider.open {
  opacity: 1;
}
#modalGallery .item {
  padding: 0 120px;
}
@media (max-width: 768px) {
  #modalGallery .item {
    padding: 0;
  }
}

section.questions-rhymes, section.questions-phrases {
  margin-bottom: 2.4rem;
}
section.questions-rhymes .words .items, section.questions-phrases .words .items {
  font-size: 2.4rem;
  line-height: 130%;
  font-weight: 600;
  color: #2B9540;
  padding: 24px;
}
section.questions-rhymes .words .items:first-of-type, section.questions-phrases .words .items:first-of-type {
  padding-left: 0;
}
section.questions-rhymes .title, section.questions-phrases .title {
  padding-bottom: 2.4rem;
  font-size: 4rem;
  line-height: 120%;
  color: #2B9540;
  font-family: "DM Serif Display", serif;
}
section.questions-rhymes .question, section.questions-phrases .question {
  background-color: #FFFFFF;
  max-width: 70%;
  text-align: center;
  color: #124BB8;
  font-size: 2.4rem;
  line-height: 130%;
  font-weight: 600;
  padding: 6.4rem 0;
}
section.questions-rhymes .question span, section.questions-phrases .question span {
  border-bottom: 3px solid #2B9540;
  width: 100px;
  display: inline-block;
}
section.questions-col-direction .list {
  padding: 6.4rem;
}
section.questions-col-direction .list .box {
  padding-bottom: 4rem;
}
section.questions-col-direction .list .box .question {
  max-width: unset;
  text-align: left;
  padding: 0 0 0.8rem 0;
}
section.questions-col-direction .list .box .points {
  font-family: "Lora", serif;
  font-weight: 600;
  color: #5E656A;
}
section.answers {
  margin-bottom: 2.4rem;
}
section.answers .container {
  padding: 2.4rem !important;
  padding-bottom: 0 !important;
}
section.answers .container .row {
  max-width: 830px;
}
section.answers .container .list .box {
  margin-bottom: 2.4em;
  font-family: "DM Serif Display", serif;
  padding: 3.2rem;
}
section.answers .container .list .box:last-of-type {
  margin-bottom: 0;
}
section.answers .container .list .box .numbers {
  font-size: 6.4rem;
  font-weight: 500;
  color: #AE8030;
}
section.answers .container .list .box .question {
  font-size: 2.4rem;
  line-height: 3.2rem;
  color: #124BB8;
  max-width: unset;
  text-align: left;
  padding: 0 0 0.8rem 0;
  font-weight: 500;
}
section.answers .container .list .box .points {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #124BB8;
}
section.answers .title {
  padding-bottom: 2.4rem;
  font-size: 4rem;
  line-height: 120%;
  color: #241733;
  font-family: "DM Serif Display", serif;
}
section.questions-rhymes .sticky {
  position: absolute;
  top: -40px;
  right: 39px;
}
section.questions-rhymes .sticky img {
  width: 290px;
  mix-blend-mode: multiply;
}

.title-text.pyramid p.medium, .title-text.pyramid .richtext.medium {
  font-family: "DM Serif Display", serif;
  max-width: 830px;
}
.title-text.max-w-830 h2, .title-text.max-w-830 .h2,
.title-text.max-w-830 p, .title-text.max-w-830 .richtext {
  max-width: 830px;
}

.sana-alimentazione .position-relative h3, .sana-alimentazione .position-relative .h3 {
  font-weight: 600;
}
.sana-alimentazione .position-relative h3 span, .sana-alimentazione .position-relative .h3 span {
  color: #AE8030;
}
.sana-alimentazione .box-left {
  padding: 48px;
}
@media (max-width: 992px) {
  .sana-alimentazione .box-left {
    padding: 40px 16px;
  }
}
.sana-alimentazione .box-left .double-title {
  margin: 0;
  font-weight: 400;
  margin-bottom: 24px;
}
.sana-alimentazione .box-left .double-title .first-title {
  font-family: "DM Serif Display", serif;
  font-size: 40px;
}
.sana-alimentazione .box-left .double-title .second-title {
  font-family: "DM Serif Display", serif;
  font-size: 50px;
}
.sana-alimentazione .box-right {
  margin-top: 40px;
  position: relative;
  padding: 48px;
}
.sana-alimentazione .box-right h3, .sana-alimentazione .box-right .h3 {
  font-size: 2.4rem;
  line-height: 32px;
}
@media (max-width: 992px) {
  .sana-alimentazione .box-right {
    margin-top: 0;
    margin-bottom: 120px;
    padding: 40px 16px;
  }
}
.sana-alimentazione .box-right .einstein {
  position: absolute;
  top: -142px;
  right: -11px;
  max-width: 280px;
  mix-blend-mode: multiply;
}
@media (max-width: 992px) {
  .sana-alimentazione .box-right .einstein {
    top: unset;
    bottom: -86px;
    right: -1px;
    max-width: 170px;
    transform: rotate(180deg);
  }
}

.text-selected p, .text-selected .richtext {
  font-size: 2.4rem;
  line-height: 32px;
  max-width: 830px;
  font-weight: 600;
}
.text-selected p span, .text-selected .richtext span {
  color: #AE8030;
}
.text-selected p:not(:last-child), .text-selected .richtext:not(:last-child) {
  margin-bottom: 32px;
}

@media (max-width: 992px) {
  .pyramid-figure .img-desktop {
    display: none;
  }
}
.pyramid-figure .img-mobile {
  display: none;
}
@media (max-width: 992px) {
  .pyramid-figure .img-mobile {
    display: block;
  }
}

.occhio-bilancia {
  position: relative;
  margin-bottom: 40px;
}
.occhio-bilancia .kcal {
  padding: 48px;
  position: relative;
}
.occhio-bilancia p, .occhio-bilancia .richtext {
  font-size: 2.4rem;
  max-width: 830px;
  text-align: left;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 48px;
}
.occhio-bilancia p.medium, .occhio-bilancia .richtext.medium {
  font-size: 2.4rem;
  max-width: 830px;
  line-height: 32px;
  font-family: "DM Serif Display", serif;
  font-weight: 400;
  margin-bottom: 0;
}
.occhio-bilancia p span, .occhio-bilancia .richtext span {
  color: #AE8030;
}
.occhio-bilancia .einstein {
  max-width: 280px;
  position: absolute;
  bottom: -143px;
  left: -40px;
  margin-left: 80px;
  mix-blend-mode: multiply;
}
@media (max-width: 992px) {
  .occhio-bilancia .einstein {
    margin-bottom: 130px;
  }
}
@media (max-width: 992px) {
  .occhio-bilancia .einstein {
    right: 10px;
    top: 99%;
    max-width: 170px;
  }
}
.occhio-bilancia .bilancia {
  position: absolute;
  right: 10px;
  top: -86px;
}
@media (min-width: 992px) {
  .occhio-bilancia .bilancia {
    max-width: 200px;
    right: 54px;
    top: -86px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .occhio-bilancia .bilancia {
    max-width: 150px;
    top: -100px;
  }
}
@media screen and (min-width: 0) and (max-width: 575px) {
  .occhio-bilancia .bilancia {
    max-width: 80px;
    top: -34px;
  }
}
@media screen and (min-width: 576px) and (max-width: 991px) {
  .occhio-bilancia .bilancia {
    max-width: 130px;
  }
}

section.eventi-news button.btn.btn-secondary::after {
  transition: all 0s !important;
}
section.eventi-news button.btn.btn-secondary.list-grid {
  display: flex;
}
section.eventi-news button.btn.btn-secondary.list-grid.ico-grid::after {
  background-image: url("./../images/icon-list.png") !important;
  background-size: 20px 20px;
  display: block;
  transition: all 0s !important;
  right: 0;
}
section.eventi-news button.btn.btn-secondary.list-grid.ico-list::after {
  background-image: url("./../images/icon-grid.png") !important;
  background-size: 20px 20px;
  display: block;
  transition: all 0s !important;
  right: 0;
}
section.eventi-news button:focus, section.eventi-news button:active {
  border-color: transparent !important;
  outline: 0 !important;
  box-shadow: none !important;
}
section.eventi-news.grid .row.switch {
  flex-direction: row;
}
section.eventi-news.grid .row.switch .col {
  width: 100%;
}
section.eventi-news.grid .row.switch .col .slider-item {
  position: relative;
}
section.eventi-news.grid .row.switch .col .slider-item .slide-data {
  position: absolute;
  top: 48px;
  color: #5E656A;
  background-color: #fff;
  padding: 5px 10px;
  font-family: "Lora", serif;
  font-size: 1.8rem;
  font-weight: 600;
}
section.eventi-news.grid .row.switch .col .slider-item .medium {
  font-family: "DM Serif Display", serif;
  font-size: 4.8rem;
  padding-bottom: 2.4rem;
}
@media (max-width: 991px) {
  section.eventi-news.grid .row.switch .col .slider-item .medium {
    font-size: 2.4rem;
  }
}
section.eventi-news.grid .row.switch .col .card-body {
  width: 60%;
}
section.eventi-news.grid .row.switch .col .min-h {
  flex-direction: row !important;
  min-height: unset !important;
}
section.eventi-news.grid .row.switch .col .min-h .card-title {
  font-size: 4.8rem;
  line-height: 120%;
}
@media (max-width: 991px) {
  section.eventi-news.grid .row.switch .col .min-h .card-title {
    font-size: 2.4rem;
  }
}
section.eventi-news.grid .row.switch .col .min-h .specs span {
  font-size: 1.8rem;
}
section.eventi-news.grid .row.switch .col .min-h img {
  width: 100% !important;
  object-fit: cover;
  height: 100%;
}
section.eventi-news.grid .row.switch .col .news {
  min-height: 364px;
}
section.eventi-news .list-grid {
  margin-bottom: 16px;
}
section.eventi-news h2, section.eventi-news .h2 {
  padding-bottom: 16px;
}
section.eventi-news p.subtitle, section.eventi-news .richtext.subtitle {
  font-size: 1.6rem;
  padding-bottom: 32px;
  margin-bottom: 0;
}
section.eventi-news .col .news {
  padding: 2.4rem;
  background: linear-gradient(0.2deg, rgba(95, 60, 0, 0.8) 26.3%, rgba(255, 255, 255, 0.4) 93.85%), url(./../images/miele.jpg) no-repeat center;
  background-size: cover;
  min-height: 575px;
}
section.eventi-news .col .news a {
  margin-left: -36px;
}
section.eventi-news .col .news a.white {
  color: #fff;
}
section.eventi-news .col .news a.white::after {
  background: url(./../images/cta-white-arrow.png) no-repeat center;
}
section.eventi-news .col .min-h {
  min-height: 575px;
}
section.eventi-news .col .card-body {
  padding-top: 0;
  padding-bottom: 2.4rem;
}
section.eventi-news .col .card-body .card-title {
  font-size: 2.4rem;
  font-family: "DM Serif Display", serif;
  line-height: 120%;
  min-height: 60px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap;
}
section.eventi-news .col .card-body .card-text {
  min-height: 72px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap;
}
section.eventi-news .col .card-body .specs {
  padding: 16px 0;
  font-family: "Lora", serif;
  font-size: 1.4rem;
}
section.eventi-news .col .card-body a {
  margin-left: -36px;
}

.einstein-right {
  margin-bottom: 0;
}
@media (max-width: 992px) {
  .einstein-right {
    background: #E1EBE3;
  }
}
.einstein-right .block-green {
  min-height: 555px;
  position: relative;
  padding: 56px 72px;
}
@media (max-width: 992px) {
  .einstein-right .block-green {
    padding: 40px 16px;
  }
}
.einstein-right .block-green .einstein {
  position: absolute;
  top: 22%;
  left: 84%;
  transform: rotate(90deg);
  mix-blend-mode: multiply;
}
@media (max-width: 1399px) {
  .einstein-right .block-green .einstein {
    max-width: 100%;
    left: 81%;
  }
}
@media (max-width: 1199px) {
  .einstein-right .block-green .einstein {
    max-width: 100%;
    left: 77%;
  }
}
@media (max-width: 992px) {
  .einstein-right .block-green .einstein {
    display: none;
  }
}

.einstein-idea {
  background: linear-gradient(to left, transparent 70%, #F8F3E0 30%);
}
@media (max-width: 992px) {
  .einstein-idea .container {
    padding: 0;
    max-width: 100%;
  }
}
@media (max-width: 992px) {
  .einstein-idea {
    background: transparent;
  }
}
.einstein-idea .block-img {
  background: #F8F3E0;
  position: relative;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 992px) {
  .einstein-idea .block-img {
    background: transparent;
    margin-bottom: 24px;
  }
}
.einstein-idea .block-img .einstein {
  transform: translateX(15px);
}
@media (max-width: 992px) {
  .einstein-idea .block-img .einstein {
    display: none;
  }
}
.einstein-idea .block-img .einstein-mobile {
  display: none;
}
@media (max-width: 992px) {
  .einstein-idea .block-img .einstein-mobile {
    display: block;
    transform: translateY(-40px);
    mix-blend-mode: multiply;
  }
}
@media (max-width: 768px) {
  .einstein-idea .block-img .einstein-mobile {
    transform: translateY(-30px);
  }
}
.einstein-idea .block-text {
  height: 100%;
}
.einstein-idea .block-text p, .einstein-idea .block-text .richtext {
  max-width: 460px;
  margin: auto;
}
@media (max-width: 992px) {
  .einstein-idea .block-text p, .einstein-idea .block-text .richtext {
    max-width: 100%;
    padding: 0 16px;
  }
}

.page-error {
  text-align: center;
  height: calc(100vh - 105px - 296px);
}
@media (max-width: 1500px) {
  .page-error {
    height: calc(100vh - 105px);
  }
}
.page-error .minihero {
  background-image: url(./../images/404-hero.jpg);
  height: 244px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 40px;
}
.page-error h1, .page-error .h1 {
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .page-error h1, .page-error .h1 {
    margin-bottom: 32px;
  }
}
.page-error p {
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .page-error p {
    margin-bottom: 24px;
  }
}

/*GENERAL CSS*/
html,
body {
  overflow-x: hidden;
}

body {
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  background-color: #FBFAFC;
}

section {
  margin-bottom: 40px;
}
@media (max-width: 991.98px) {
  section {
    margin-bottom: 40px;
  }
}

section.light-green {
  margin-bottom: 0;
  padding-bottom: 104px;
}
@media (max-width: 991.98px) {
  section.light-green {
    padding-bottom: 40px;
  }
}

section.light-beige {
  margin-bottom: 0;
  padding-bottom: 40px;
}
@media (max-width: 991.98px) {
  section.light-beige {
    padding-bottom: 40px;
  }
}

h1, .h1,
h2,
.h2 {
  font-family: "DM Serif Display", serif;
}

h3, .h3,
h4,
.h4 {
  font-family: "Poppins", sans-serif;
}

h1, .h1 {
  font-size: 6.4rem;
  line-height: 72px;
}
@media (max-width: 991.98px) {
  h1, .h1 {
    font-size: 4rem;
    line-height: 48px;
  }
}

h2, .h2 {
  font-size: 4.8rem;
  line-height: 120%;
  padding-bottom: 3.2rem;
  padding-top: 1rem;
}
@media (max-width: 991.98px) {
  h2, .h2 {
    padding-bottom: 2.4rem;
    font-size: 3.2rem;
  }
}
@media (min-width: 991px) and (max-width: 1200px) {
  h2, .h2 {
    font-size: 4rem;
  }
}
h2.medium, .medium.h2 {
  font-size: 3.2rem;
  line-height: 40px;
  font-weight: 400;
}

h4, .h4 {
  line-height: 32px;
}

p, .richtext {
  font-size: 1.6rem;
  line-height: 24px;
}
p.medium, .richtext.medium {
  font-size: 2.4rem;
  line-height: 32px;
}
p ul, p ol, .richtext ul, .richtext ol {
  padding-left: 15px;
}
p ul li, .richtext ul li {
  list-style: disc;
}

.link {
  text-decoration: none;
  font-weight: 700;
}

ul {
  padding: 0;
}
ul li {
  list-style: none;
}

.btn {
  font-family: "Poppins", sans-serif;
  padding: 16px;
  position: relative;
  font-size: 1.6rem;
}
.btn.btn-primary, .btn input.btn-primary {
  color: #FFFFFF;
  line-height: 18px;
  border: 0;
  border-radius: 0;
  width: 200px;
  height: 50px;
  text-align: left;
  background-color: #2B9540 !important;
}
.btn.btn-primary:hover, .btn input.btn-primary:hover {
  background-color: #2B9540 !important;
}
.btn.btn-primary:hover:after, .btn input.btn-primary:hover:after {
  right: 0;
}
.btn.btn-primary:focus, .btn input.btn-primary:focus {
  box-shadow: none;
}
.btn.btn-primary:after, .btn input.btn-primary:after {
  content: "";
  display: inline-block;
  width: 19px;
  height: 19px;
  background: url(./../images/cta-white-arrow.png) no-repeat center;
  background-size: cover;
  position: absolute;
  right: 16px;
  transition: all 0.5s ease;
}
.btn.btn-secondary, .btn input.btn-secondary {
  color: #2B9540;
  background-color: transparent;
  background-image: none;
  line-height: 18px;
  border: 0;
  border-radius: 0;
  width: 200px;
  height: 50px;
  font-weight: 600;
}
.btn.btn-secondary:hover, .btn input.btn-secondary:hover {
  background-color: transparent !important;
}
.btn.btn-secondary:hover:after, .btn input.btn-secondary:hover:after {
  right: 0;
}
.btn.btn-secondary:focus, .btn input.btn-secondary:focus {
  box-shadow: none;
}
.btn.btn-secondary:after, .btn input.btn-secondary:after {
  content: "";
  display: inline-block;
  width: 19px;
  height: 19px;
  background: url(./../images/cta-green-arrow.png) no-repeat center;
  background-size: cover;
  position: absolute;
  right: 16px;
  transition: all 0.5s ease;
}
.btn.btn-tertiary {
  color: #2B9540;
  background-color: #FFFFFF;
  line-height: 18px;
  border: 0;
  border-radius: 0;
  width: 200px;
  height: 50px;
  text-align: left;
}
.btn.btn-tertiary:hover {
  background-color: #FFFFFF !important;
}
.btn.btn-tertiary:hover:after {
  right: 0;
}
.btn.btn-tertiary:focus {
  box-shadow: none;
}
.btn.btn-tertiary:after {
  content: "";
  display: inline-block;
  width: 19px;
  height: 19px;
  background: url(./../images/cta-green-arrow.png) no-repeat center;
  background-size: cover;
  position: absolute;
  right: 16px;
  transition: all 0.5s ease;
}

.slick-arrow {
  background: #F8F3E0;
  width: 84px;
  height: 84px;
  z-index: 2;
  transition: all 0.2s ease;
}
.slick-arrow.slick-disabled {
  background: #F8F3E0;
  opacity: 1;
  pointer-events: none;
}
.slick-arrow:hover, .slick-arrow:active, .slick-arrow:focus {
  background: #F8F3E0;
  transform: translate(0, -50%);
  transition: all 0.2s ease;
}
.slick-arrow.slick-next::before, .slick-arrow.slick-prev::before {
  content: "";
  width: 20px;
  height: 33px;
  display: inline-block;
  background: url(./../images/arrow-slide-green.svg) no-repeat center;
  background-size: cover;
  transition: all 0.5s ease;
}
.slick-arrow.slick-prev:hover::before {
  margin-right: 16px;
  transition: all 0.5s ease;
}
.slick-arrow.slick-next:hover::before {
  margin-left: 16px;
  transition: all 0.5s ease;
}
.slick-arrow.slick-next::before {
  transform: rotate(180deg);
}

.slick-dots {
  position: relative;
  margin: 4rem 0;
}
.slick-dots li {
  width: 6px;
  height: 6px;
  background: #241733;
  border-radius: 50%;
}
.slick-dots li:not(:last-child) {
  margin-right: 24px;
}
.slick-dots li.slick-active {
  border: 3px solid green;
  background: transparent;
  width: 12px;
  height: 12px;
}
.slick-dots li button:before {
  font-size: 0;
}

/* spacers */
.pt-104 {
  padding-top: 80px;
}
@media (max-width: 991.98px) {
  .pt-104 {
    padding-top: 40px;
  }
}

.mt-104 {
  margin-top: 80px;
}
@media (max-width: 991.98px) {
  .mt-104 {
    margin-top: 40px;
  }
}

.pb-104 {
  padding-bottom: 80px;
}
@media (max-width: 991.98px) {
  .pb-104 {
    padding-bottom: 40px;
  }
}

.mb-104 {
  margin-bottom: 80px;
}
@media (max-width: 991.98px) {
  .mb-104 {
    margin-bottom: 40px;
  }
}

.counter-ricette {
  font-size: 18px;
  font-weight: 600;
  color: #5E656A;
  font-family: "Lora", serif;
}

.alert {
  position: relative;
}
.alert .close {
  color: #FFFFFF;
  text-decoration: none;
  position: absolute;
  top: 5px;
  right: 10px;
}

.alert-success {
  color: green;
  background-color: rgba(0, 128, 0, 0.6);
  border-color: green;
}

.alert-warning {
  color: red;
  background-color: rgba(255, 0, 0, 0.6);
  border-color: red;
}