.chef-community-card-list {
    .col {
        margin-bottom: 40px;
        margin-top: 0;
        .card {
            background-color: transparent;
            border: 0;

            @media(max-width:991px) {
                margin-bottom: 24px;
            }
            .card-body {
                padding: 16px 16px 16px 0;
                .card-title {
                    font-size: 2.4rem;
                    font-weight:400;
                    font-family: $font-primary;
                    margin-bottom: 0;
                }
            }

            a {
                color: $green;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}