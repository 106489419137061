
#modalGallery {

    .wrap-modal-slider {
        transition: all .5s ease;
        opacity: 0;
        
        &.open {
            opacity: 1;
        }
    }

    .item {
        padding: 0 120px;

        @media(max-width: 768px) {
            padding: 0;
        }
    }
}