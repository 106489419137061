section {
    &.title {
        margin-bottom: 0 !important;
        h2 {
            margin-bottom: 0;
        }

        &.medium {
            h2 {
                font-size: 3.2rem;
                line-height: 40px;
                font-weight: 400;
            }
        }
    }
}