section {
    &.text {
        margin-bottom: 0;
        &.spacer {
            padding-top: 104px;
            padding-bottom: 104px;
        }
    }
}

.ic-mail:before, .ic-phone:before, .ic-share:before {
    content: "";
    display: inline-block;
    margin-right: 8px;
    width: 24px;
    height: 24px;
    background-size: cover;
    vertical-align: middle;
}

.ic-mail:before {
    background: url(./../images/ic-mail.png) no-repeat center;
}

.ic-phone:before {
    background: url(./../images/ic-phone.png) no-repeat center;
}

.ic-share:before {
    background: url(./../images/ic-share.png) no-repeat center;
}