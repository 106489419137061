.contact-form {
    .social {
        div {
            margin-left: 32px;
        }
        svg {
            width: 24px;
            height: 24px;
            margin-right: 16px;
        }
    }

    padding-top: 0;

    &.accreditamento {
        padding-top: 104px;

        &.no-txt {
            p, .richtext {
                &.medium {
                    display: none;
                }
            }
        }
    }

    .form-floating {
        padding-bottom: 3.2rem;

        @include media-breakpoint-down(lg) {
            //padding-bottom: 3.2rem;
        }
    }

    p, .richtext {
        &.medium {
            padding-bottom: 4.0rem;
        }

        &.info {
            padding-bottom: 7.2rem;
        }
    }

    form {
        .form-floating {
            & .form-control:not(:placeholder-shown)~label {
                transform: scale(0.85) translateY(-0.9rem) translateX(0.15rem);
            }

            input,
            textarea {
                background: transparent;
                border: 1px solid $black;
                border-radius: 0;
            }

            & input:-webkit-autofill {
                -webkit-box-shadow: 0 0 0 30px $light-green inset !important;
            }

            textarea {
                min-height: 140px !important;
            }

            & label {
                height: auto;
                left: 24px;
                padding-left: 0;
            }

            .form-control {
                font-family: $font-secondary;
                min-height: 48px;
                padding-left: 24px;
                padding-right: 24px;


                &:focus {
                    border: 1px solid $black;
                    box-shadow: none;
                }
            }
            label.error {
                transform: none!important;
                color: red;
                font-size: 13px;
                bottom: 0;
                top: auto;
            }
        }

        input[type=file] {
            color: #79947E;
            font-size: 13px;
            line-height: 24px;
            font-style: italic;
            cursor: pointer;

            &::before {
                content: "";
                display: inline-block;
                width: 24px;
                height: 24px;
                background: url(./../images/ic-allega-file.png) no-repeat center;
                background-size: cover;
                vertical-align: middle;
            }

            &::file-selector-button {
                cursor: pointer;
                border: none;
                background-color: transparent;
                color: $green;
                font-size: 16px;
                line-height: 28px;
                font-style: normal;
                font-weight: 600;
            }
        }

        .form-check-input {
            background-color: transparent;
            border: 1px solid $black;
            box-shadow: none;

            &:checked {
                background-color: $green;
                border: 1px solid $green;

            }
        }
        .btn-secondary {
            width: auto;
        }
    }
}