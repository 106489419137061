
#modalSentieriSorriso {
    .modal-content {
        @media(max-width: 768px) {
            background: linear-gradient(to top, $bgBody 80%, $beige-sds 20%);
        }

        .modal-title {
            background-color: $beige-sds;
            padding: 56px;
            padding-bottom: 16px;

            @media(max-width:768px) {
                padding: 16px;
            }
    
            .modal-header {
                padding: 0;
                
                h1 {
                    margin-bottom: 8px;

                    @media(max-width: 768px) {
                        font-size: 32px;
                    }
                }
            }
            .text-animation {
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                .provincia {
                    align-items: center;
                }

                // @media(max-width: 768px) {
                //     display: block;
                //     max-height: 100%;
                // }
            }
            svg {
                max-width: 75px;
                max-height: 75px;
                z-index: 1;

                // @media(max-width: 768px) {
                //     max-width: 43px;
                //     max-height: 43px;
                // }
            }
            .slide-text {
                background: $brown-sds;
                margin-left: -10px;
                margin-right: 16px;
                width: 360px;
                min-height: 38px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding: 0 24px;
                color: #fff;
                animation: slide 1.5s ease;
                animation-delay: .5s;
                animation-fill-mode: backwards;
                clip-path: polygon(95% 0, 100% 50%, 95% 100%, 0 100%, 0 0);

                @media(max-width: 768px) {
                    clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0 100%, 0 0);
                    margin-left: -20px;
                    width: 100%;
                    justify-content: center;
                }
        
                p, .richtext {
                    margin: 0;
                    font-weight: 700;
                    white-space: nowrap;
                }
            }
            h2 {
                animation: fadeInTitle 2s ease;
                animation-delay: 2s;
                animation-fill-mode: backwards;
                color: $brown-sds;

                &.comune {
                    padding: 0;
                }

                @media(max-width: 991px) {
                    width: 100%;
                    text-align: end;
                }
            }
            @keyframes fadeInTitle {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }
            
            @keyframes slide {
                0% {
                    width: 0;
                    opacity: 0;
                }
                100% {
                    width: 360px;
                    opacity: 1;
                }
            }
        }
        .main-modal {
            padding: 56px;
            background: linear-gradient(to left, $bgBody 70%, $beige-sds 30%);

            @media(max-width: 768px) {
                background: transparent;
                padding: 0;
            }
    
            .map {
                transform: translateY(-65px);

                @media(max-width: 768px) {
                    transform: translateY(0);
                    margin-bottom: 24px;
                }
            }

            .card-dop {
                max-width: 100px;
            }

            h2 {
                font-size: 24px;
                line-height: 32px;
                font-weight: 400;
            }
            ol {
                list-style: none;
                .icon {
                    position: relative;
                    margin-left: 32px;
                    counter-increment: item;
                    &:before {
                        content: counter(item) "-";
                        font-weight: 700;
                        position: absolute;
                        top: 0;
                        left: -24px;
                    }
                    &:after {
                        content: "";
                        width: 20px;
                        height: 20px;
                        display: inline-block;
                        vertical-align: middle;
                        background: url(./../images/ic-monumenti.png) no-repeat center;
                        background-size: cover;
                        position: absolute;
                        top: 0;
                        left: -56px;
                    }
                }
            }
            .info-list {
                li {
                    margin-bottom: 16px;
                    
                    .icon-top {
                        max-width: 30px;
                        height: auto;
                        margin-right: 16px;
                    }
                    p, .richtext {
                        margin: 0;
                    }
                }
            }
            .list-style {
                padding-left: 2rem;
                li {
                    list-style: inherit;
                }
            }
        }
    }
}
.ic-yellow {
    & .color-ic {
        fill:$ic-yellow;
    }
    & .slide-text {
        background: $ic-yellow !important;
    }
    & .comune {
        color: $ic-yellow !important;
    }
}
.ic-pink {
    & .color-ic {
        fill:$ic-pink;
    }
    & .slide-text {
        background: $ic-pink !important;
    }
    & .comune {
        color: $ic-pink !important;
    }
}
.ic-green {
    & .color-ic {
        fill:$ic-green;
    }
    & .slide-text {
        background: $ic-green !important;
    }
    & .comune {
        color: $ic-green !important;
    }
}
.ic-red {
    & .color-ic {
        fill:$ic-red;
    }
    & .slide-text {
        background: $ic-red !important;
    }
    & .comune {
        color: $ic-red !important;
    }
}

.ic-blue {
    & .color-ic {
        fill:$ic-blue;
    }
    & .slide-text {
        background: $ic-blue !important;
    }
    & .comune {
        color: $ic-blue !important;
    }
}
.ic-brown {
    & .color-ic {
        fill:$ic-brown;
    }
    & .slide-text {
        background: $ic-brown !important;
    }
    & .comune {
        color: $ic-brown !important;
    }
}
.ic-gold {
    & .color-ic {
        fill:$ic-gold;
    }
    & .slide-text {
        background: $ic-gold !important;
    }
    & .comune {
        color: $ic-gold !important;
    }
}
.ic-bordeaux {
    & .color-ic {
        fill:$ic-bordeaux;
    }
    & .slide-text {
        background: $ic-bordeaux !important;
    }
    & .comune {
        color: $ic-bordeaux !important;
    }
}