section {
    &.breadcrumbs {
        margin-bottom: 0;
        padding-top: 3.2rem;
        padding-bottom: 3.2rem !important;
        @include media-breakpoint-down(lg){
            padding-top: 1.6rem;
            padding-bottom: 1.6rem !important;
        }
        .container {
            .path {
                font-size: 1.6rem;
                @include media-breakpoint-down(lg){
                    font-size: 1.4rem;
                }
                color: $dark;
    
                a {
                    color: $dark;
                    font-weight: 400;
                    text-decoration: none;
    
                    &:hover {
                        text-decoration: underline;
                    }
                }
                li:last-child {
                    a {
                        font-weight: 700;
                    }
                }
    
                svg {
                    width: 16px;
                    height: 16px;
                    fill: $light-grey;
                    margin-left: 6px;
                    margin-right: 6px;
                }
            }
        }
    }
}
