section {
    &.eventi-news {
        button {
            //display: flex; assegnare ai bottoni che switchano e basta 
            &.btn {
                &.btn-secondary {
                    &::after {
                        //display: none; assegnare ai bottoni che switchano e basta 
                        transition: all 0s !important;
                    }
                    &.list-grid {
                        display: flex;
                        &.ico-grid {
                            &::after {
                                background-image: url("./../images/icon-list.png") !important;
                                background-size: 20px 20px ;
                                //background-position: center right;
                                display: block;
                                transition: all 0s !important;
                                right: 0;
                            }
                        }
                        &.ico-list {
                            &::after {
                                background-image: url("./../images/icon-grid.png") !important;
                                background-size: 20px 20px ;
                                //background-position: center right;
                                display: block;
                                transition: all 0s !important;
                                right: 0;
                            }
                        }
                    }

                }
            }

            &:focus, &:active {
                border-color:transparent !important;
                outline:0  !important;
                box-shadow: none !important
               }
        }
        &.grid {
            .row {
                &.switch {
                    flex-direction: row;
                    .col {
                        width: 100%;
                        .slider-item {
                            position: relative;
                            .slide-data {
                                position: absolute;
                                top: 48px;
                                color: #5E656A;
                                background-color: #fff;
                                padding: 5px 10px;
                                font-family: $font-tertiary;
                                font-size: 1.8rem;
                                font-weight:600
                            }
                            .medium {
                                font-family: $font-primary;
                                font-size: 4.8rem;
                                padding-bottom: 2.4rem;

                                @media(max-width:991px) {
                                    font-size: 2.4rem;
                                }
                            }
                        }  
                        .card-body {
                            width: 60%;
                        }                      
                        .min-h {
                            flex-direction: row !important;
                            min-height: unset !important;
                            .card-title {
                                font-size: 4.8rem;
                                line-height: 120%;

                                @media(max-width:991px) {
                                    font-size: 2.4rem;
                                }
                            }
                            .specs {
                                span {
                                    font-size: 1.8rem;
                                }
    
                            }
                            img {
                                width: 100% !important;
                                object-fit: cover;
                                height: 100%;
                            }
                        }
                        .news {
                            min-height: 364px;
                        }
                    }
                }
            }
            
        }
        .list-grid {
            margin-bottom: 16px;
        }
        h2 {
            padding-bottom: 16px;
        }
        p, .richtext {
            &.subtitle {
            font-size: 1.6rem;
            padding-bottom: 32px;
            margin-bottom: 0;
            }
        }
        .col {
            .news {
                padding: 2.4rem;
                background: linear-gradient(0.2deg, rgba(95, 60, 0, 0.8) 26.3%, rgba(255, 255, 255, 0.4) 93.85%), url(./../images/miele.jpg) no-repeat center;
                background-size: cover;
                min-height: 575px;

                a {
                    margin-left: -36px;
                    &.white {
                        color: #fff;

                        &::after {
                            background: url(./../images/cta-white-arrow.png) no-repeat center;
                        }
                    }
                }
            }

            .min-h {
                min-height: 575px;
            }

            .card-body {
                padding-top: 0;
                padding-bottom: 2.4rem;

                .card-title {
                    font-size: 2.4rem;
                    font-family: "DM Serif Display", serif;
                    // padding-bottom: 2.4rem;
                    //margin-bottom: 0;
                    line-height: 120%;
                    min-height: 60px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2; /* start showing ellipsis when 3rd line is reached */
                    white-space: pre-wrap; 
                }
                .card-text {
                    min-height: 72px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
                    white-space: pre-wrap; 
                }

                .specs {
                    padding: 16px 0;
                    font-family: "Lora", serif;
                    font-size: 1.4rem;
                }

                a {
                    margin-left: -36px;
                }
            }
        }
    }
}