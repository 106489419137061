.chef-community-blog {
    background: linear-gradient(to left, $light-beige 50%, $bgBody 50%);
    background-size: 100% 70%;
    background-repeat: no-repeat;
    margin-bottom: 0;
    h2 {
        padding-bottom: 1.6rem;
    }
    // h3 {
    //     padding-bottom: 3.2rem;
    //     margin-bottom: 0 !important;
    // }
    h4 {
        padding-bottom: 2.4rem;
        font-weight: 600;
    }
    .tagline {
        padding-bottom: 3.2rem;
        display: block;
    }
    &.foodbloggers {
        h2 {
            align-items: center;
            display: flex;
            margin-left: -61px;
            @include media-breakpoint-down(sm){
                display: block;
                margin-left: 0;
            }
            &::before {
                content: "";
                display: inline-block;
                @include media-breakpoint-down(sm){
                    display: block;
                }
                width: 50px;
                height: 50px;
                background: url(./../images/yellow-hat.png) no-repeat center;
                background-size: contain;
                vertical-align: middle;
                margin-right: 16px;
            }
        }
    }

    &.appassionati {
        h2 {
            align-items: center;
            display: flex;
            margin-left: -61px;
            @include media-breakpoint-down(sm){
                display: block;
                margin-left: 0;
            }            
            &::before {
                content: "";
                display: inline-block;
                @include media-breakpoint-down(sm){
                    display: block;
                }                
                width: 50px;
                height: 50px;
                background: url(./../images/green-hat.png) no-repeat center;
                background-size: contain;
                vertical-align: middle;
                margin-right: 16px;
            }
        }
    }

    &.ristoratori {
        h2 {
            align-items: center;
            display: flex;
            margin-left: -61px;
            @include media-breakpoint-down(sm){
                display: block;
                margin-left: 0;
            }            
            &::before {
                content: "";
                display: inline-block;
                @include media-breakpoint-down(sm){
                    display: block;
                }                
                width: 50px;
                height: 50px;
                background: url(./../images/blue-hat.png) no-repeat center;
                background-size: contain;
                vertical-align: middle;
                margin-right: 16px;
            }
        }
    }

    .share {
        position: absolute;
        top: 0;
        right: 0;
        width: auto !important;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    .blog {
        display: flex;
        align-items: center;

        .btn {
            &.btn-secondary {
                margin-bottom: 16px;
                width: auto;
                display: flex;
                align-items: center;

                span {
                    display: block;
                    margin-right: 30px;
                    font-family: $font-secondary;
                }
            }
        }

        span {
            font-size: 1.8rem;
            font-weight: 600;
            line-height: 28px;
            color: $green;
        }
    }

    h3 {
        font-size: 2.4rem;
        line-height: 120%;
        font-family: $font-secondary;
        font-weight: 600;
        margin-bottom: 16px;
    }

    .max-w-830 {
        max-width: 830px;
    }

    span {
        font-size: 1.2rem;
        font-family: $font-tertiary;
        color: $light-grey;
    }

    .standard-cards {
        .card-valori {
            margin-right: 16px;

            .card {
                border: 0;
                box-shadow: none;

                .card-body {
                    min-height: 300px;

                    .btn {
                        &.btn-secondary {
                            margin-left: -36px;
                        }
                    }
                }

                span {
                    color: #AE8030;
                    font-size: 1rem;
                    font-family: $font-secondary;
                }
            }
        }
    }
}