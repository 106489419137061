.search {
    .title {
        font-size: 1.6rem;
        color: $light-grey;
        line-height: 24px;
        height: 24px;
        margin-bottom: 8px;
    }

    input,
    select {
        height: 50px;
        font-family: $font-secondary;
        font-weight: 700;
        color: $black;
        position: relative;
        background-color: $white !important;
    }

    label {
        color: $black;
    }

    input.search-input {
        content: '';
        display: block;
        background: url("../../images/ico-search.png");
        background-repeat: no-repeat;
        background-position: 12px center;
        z-index: 1000;
        padding-left: 50px !important;
    }

    .btn {
        &.btn-primary {
            text-align: center;
            width: 100%;
            &:hover {
                text-decoration: underline;
            }
            &:after {
                display: none;
            }
        }
    }
}