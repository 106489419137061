.triple-cards-elements {
    position: relative;
    padding-bottom: 40px;
    margin-bottom: 40px;

    .card-container {

        @media(max-width: 992px) {
            margin-bottom: 40px;
        }
        
        .card-img {
            position: relative;
            min-width: 280px;
            background: white;
            min-height: 280px;
            margin-bottom: 16px;
            
            & img {
                max-width: 160px;
            }
        }
        .card-number {
            font-family: $font-primary;
            color: $basic-brown;
            font-size: 78px;
            line-height: 120%;
            position: absolute;
            bottom: -20px;
            left: -50px;

            @media(max-width: 1200px) {
                font-size: 56px;
                left: 8px;
                bottom: 8px;
            }
            @media(max-width: 768px) {
                font-size: 64px;
                left: 24px;
                bottom: 24px;
            }
        }
        .card-p {
            font-size: 14px;
            line-height: 21px;
            font-weight: 600;
            margin-bottom: 24px;
        }
    }
}