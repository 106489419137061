section {

    &.linear-two-col-floating-img {
        &.pt-150 {
            padding-top: 150px;
        }
        padding-top: 80px;
        margin-bottom: 80px !important;
        .box-title {
            padding-bottom: 3.2rem;
        }
        .strip {
            font-size: 2.4rem;
            font-weight: 600;
            padding-bottom: 3.2rem;
            img {
                width: 100% !important;
                height: auto !important;
            }       
            
            div {
                padding: 20px 40px;
                text-align: left;
                font-size: 1.2vw;
                span {
                    color: $basic-brown;
                }
            }
        }
        .blue, .green {
            margin-right: 24px;
        }
        .blue {
            display: none;
        }

        &.reverse {
            .green {
                display: none;
            }

            .blue {
                display: block;
            }

            background: linear-gradient(to left, $lightplus-beige 80%, $bgBody 20%);

            @include media-breakpoint-down(lg) {
                background: linear-gradient(to left, $lightplus-beige 100%, $bgBody 0);
            }

            .row {
                flex-direction: row-reverse;
            }
        }

        .flex-row-reverse {
            .img {
                padding-left: 0;

                img {
                    margin-left: -70px !important;

                    @include media-breakpoint-down(lg) {
                        margin-left: unset !important;
                    }
                }
            }
        }

        .txt {
            img {
                width: 120px;
                height: 120px;
            }
        }

        .img {
            @include media-breakpoint-down(lg) {
                margin-top: 0;
            }

            //margin-top: -100px;
            margin-top: -180px;
        }

        .richtext p {
            font-size: 2.4rem;
            line-height: 32px;
            padding-left:0;
            padding-right:30px;
            
        }
    }
}