/* Minicards, Standardcards Gallery etc */

/* Minicards */

.mini-cards {
    .col {
        margin-top: 0;
        margin-bottom: 3.2rem;
        .card {
            box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);

            img {
                max-width: 155px;
                
                 @media(max-width: 575px) {
                    max-width: 100%;
                }
            }
    
            .card-body {
                h5 {
                    font-family: $font-primary;
                    font-size: 2.4rem;
                    line-height: 32px;
                }
    
                p, .richtext {
                    font-family: $font-secondary;
    
                    &.card-text {
                        font-size: 1.4rem;
                        line-height: 150%;
                    }
                }
            }
    
            &--location {
                cursor: pointer;
                transition: all .2s ease;
    
                &:hover {
                    transform: scale3d(1.04, 1.04, 1.04);
                }
            }
        }
    }

}

.standard-cards {
    .col {
        margin-bottom: 40px;
        margin-top: 0;
    }
    &.gallery {
        .specs {
            padding-bottom: 1.6rem !important;
        }
        &.int {
            p, .richtext {
                display: block !important;
            }
        }
        .card {
            .card-body {
                p, .richtext {
                    display: none;
                }
                .specs {
                    span {
                        color: $light-grey;
                        font-family: $font-tertiary;
                        font-size: 1.4rem;
                        font-weight: 600;
                    }
                }
            }
        }
    }
    .card {
        box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);

        .card-body {
            padding-top: 1.6rem;
            padding-bottom: 4.8rem;
            .specs {
                color: $basic-brown;
                font-size: 1.0rem;
                padding-bottom: 2.4rem;
                span {
                    margin-top: 2px;
                }
                p, .richtext {
                    display: none;
                }

                svg {
                    width: 16px;
                    height: 16px;
                    margin-right: 4px;
                }
            }

            h5 {
                font-family: $font-primary;
                font-size: 2.4rem;
                line-height: 32px;
                min-height: 100px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
                white-space: pre-wrap; 
                padding-bottom: 0;
                margin-bottom: 3.2rem;
            }

            p, .richtext {
                font-family: $font-secondary;

                &.card-text {
                    font-size: 1.4rem;
                    /* omologata alle mini cards, chiedere a Ilaria */
                    line-height: 150%;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
                    white-space: pre-wrap; 
                }
            }
        }
    }
}
.card {
    a {
        color:$black;
        text-decoration: none;
    }
}