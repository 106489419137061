/* Quote */

blockquote {
    position: relative;
    color: #383838;
    font-family: $font-primary;
    font-size: 2.4rem;
    line-height: 32px;
    margin-top: 1rem;
    display: flex;
    width: 100%;

    svg {
        width: 32px;
        height: 32px;
        float: right;
        position: absolute;
        bottom: 0;
        right: 4%;
        top: unset;
        margin-left: 24px;

        &:first-of-type {
            float: left;
            left: 0;
            top: 0;
            margin-right: 24px;
            margin-left: 0;
        }
    }

    .richtext {
        float: left;
        font-size: 2.4rem;
        line-height: 32px;
        padding-left: 50px;
        padding-right: 50px;

        & p, .richtext {
            font-size: 2.4rem;
            line-height: 32px; 
        }
        // @include media-breakpoint-down(lg){
        //     padding-left: 0;
        //     padding-right: 0;
        // }
        em {
            font-style: italic;
            font-size: 4.0rem;
            @include media-breakpoint-down(lg){
                font-size: 3.2rem;
            }
        }
    }

}

/* Quote plus cta */

section {
    &.quote-cta {
        padding-bottom: 4.8rem !important;
        padding-top: 4.8rem !important;
        margin-bottom: 0;
        h2 {
            color: $white;
        }
        .text-lg-end {
            margin-bottom: 0 !important;
        }
        .sign {
            font-size: 1.6rem;
            line-height: 24px;
            color: $white;
        }
        blockquote {
            margin-bottom: 0;
            margin-left: -50px;
            color: $white;
            @include media-breakpoint-down(xl){
                margin-left: 0 !important;
                display: block;
            }
            svg {
                @include media-breakpoint-down(xl){
                    position: relative;
                    :first-of-type {
                        position: relative;
                    }
                }
            }
        }
    }
}


section#block_content.quote-cta.dark-brown {
    @media (min-width:768px){
        margin-bottom: -350px;
    }
}

