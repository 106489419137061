.slider-eventi-news {
    padding-top: 80px;
    .col {
        .card-body {
            .btn-secondary {
                margin-left: -36px;
            }
            

            padding-top: 0;
            padding-bottom: 2.4rem;
            .specs {
                padding: 16px 0;
                font-family: $font-tertiary;
                font-size: 1.4rem;
            }
            .card-title {
                font-size: 2.4rem;
                font-family: $font-primary;
                // padding-bottom: 2.4rem;
                // margin-bottom: 0;
                line-height: 120%;
                    min-height: 60px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2; /* start showing ellipsis when 3rd line is reached */
                    white-space: pre-wrap; 
                }
                .card-text {
                    min-height: 72px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
                    white-space: pre-wrap; 
                }
        }
        .news {
            .slide-data {
                font-family: $font-tertiary;
                font-size: 1.8rem;
                margin: 0;
                padding-bottom: 16px;
            }
            p, .richtext {
                &.medium {
                    font-family: $font-primary;
                    padding-bottom: 2.4rem;
                }
            }
            button {
                margin-left: -36px;
                &.white {
                    color: #fff;
                    &::after {
                        background: url(./../images/cta-white-arrow.png) no-repeat center;
                    }
                }
            }
            padding: 2.4rem;
            background: linear-gradient(0.2deg, rgba(95, 60, 0, 0.8) 26.3%, rgba(255, 255, 255, 0.4) 93.85%), url(./../images/miele.jpg) no-repeat center;
            background-size: cover;
            min-height: 575px;
        }
        .min-h {
            min-height: 575px;
        }
    }

    

    .slick-slider {
         padding-bottom: 7.2rem;
    }

    .slide-item-container {
        padding: 40px;
        background: linear-gradient(0.2deg, rgba(95, 60, 0, 0.8) 26.3%, rgba(255, 255, 255, 0.4) 93.85%), url(./../images/miele.jpg) no-repeat center;
        background-size: cover;
        min-height: 433px;

        &:not(:last-child) {
            margin-right: 30px;
        }

        .slide-price {
            font-size: 18px;
            line-height: 28px;
            font-weight: 600;
            font-family: $font-tertiary;
        }
    }
}