.title-text {
    padding-top: 40px;
    
    &.medium {
        .richtext p, .richtext {
            font-size: 2.4rem;
            line-height: 32px;
        }
    }
    &.foodbloggers {
        h2 {
            align-items: center;
            display: flex;
            margin-left: -61px;
            @include media-breakpoint-down(sm){
                display: block;
                margin-left: 0;
            }
            &::before {
                content: "";
                display: inline-block;
                @include media-breakpoint-down(sm){
                    display: block;
                }
                width: 45px;
                height: 45px;
                background: url(./../images/yellow-hat.png) no-repeat center;
                background-size: cover;
                vertical-align: middle;
                margin-right: 16px;
            }
        }
    }
    &.appassionati {
        h2 {
            align-items: center;
            display: flex;
            margin-left: -61px;
            @include media-breakpoint-down(sm){
                display: block;
                margin-left: 0;
            }            
            &::before {
                content: "";
                display: inline-block;
                @include media-breakpoint-down(sm){
                    display: block;
                }                
                width: 45px;
                height: 45px;
                background: url(./../images/green-hat.png) no-repeat center;
                background-size: cover;
                vertical-align: middle;
                margin-right: 16px;
            }
        }
    }
    &.ristoratori {
        h2 {
            align-items: center;
            display: flex;
            margin-left: -61px;
            @include media-breakpoint-down(sm){
                display: block;
                margin-left: 0;
            }            
            &::before {
                content: "";
                display: inline-block;
                @include media-breakpoint-down(sm){
                    display: block;
                }                
                width: 45px;
                height: 45px;
                background: url(./../images/blue-hat.png) no-repeat center;
                background-size: cover;
                vertical-align: middle;
                margin-right: 16px;
            }
        }
    }
    &.print {
        margin-bottom: 24px !important;
        .btn {
            &.btn-secondary {
                width: unset;
                &::after {
                    display: none;
                }
                span {
                    margin-right: 10px;
                }
                svg {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}

.title-cta {
    margin-bottom: 0;
    padding-bottom: 24px;
    h2 {
        padding-bottom: 0;
    }
}

.text-cta {
    padding-bottom: 24px !important;
}

.title-text {
    &.print {
        .btn {
            &.btn-secondary {
                &::after {
                    display: none;
                }
                svg {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}