/*main{    
    @include media-breakpoint-down(sm){
        background: rgba($primary, .2);
    }

    @include media-breakpoint-up(sm){
        background: rgba($primary, .4);
    }

    @include media-breakpoint-up(md){
          background: rgba($primary, .6);
    }
        
    @include media-breakpoint-up(xl){
    background: rgba($primary, .8);
    }

    @include media-breakpoint-up(xxl){
        background: rgba($primary, 1);
      }
    @include media-breakpoint-up(xxxl){
        background: rgba(yellow, 1);
      }
}*/

//bg colors

.light-beige {
    background-color: $light-beige;

    &.r-l-60-40 {
        background: linear-gradient(to left, $light-beige 60%, $bgBody 40%)
            /* right to left  60/40 */
    }

    &.l-r-60-40 {
        background: linear-gradient(to right, $light-beige 60%, $bgBody 40%)
            /* left to right  60/40 */
    }

    &.l-r-50-50 {
        background: linear-gradient(to right, $light-beige 50%, $bgBody 50%)
            /* left to right  60/40 */
    }

    // &.l-r-50-50 {
    //     background: linear-gradient(to right, $light-beige 50%, $bgBody 50%)
    //         /* left to right  60/40 */
    // }

    &.l-r-80-20 {
        @include media-breakpoint-down(lg) {
            background: linear-gradient(to right, $light-beige 100%, $bgBody 0%)
                /* left to right  100/0 */
        }

        background: linear-gradient(to right, $light-beige 80%, $bgBody 20%)
        /* left to right  80/20 */
    }

    &.b-t-70-30 {
        @include media-breakpoint-down(lg) {
            background: linear-gradient(to top, $light-beige 100%, $bgBody 0%)
                /* bottom to top  100/0 */
        }

        background: linear-gradient(to top, $light-beige 70%, $bgBody 30%)
        /* bottom to top  70/30 */
    }

    &.t-b-50-50 {
        @include media-breakpoint-down(lg) {
            background: linear-gradient(to top, $light-beige 100%, $bgBody 0%)
                /* bottom to top  100/0 */
        }

        background: linear-gradient(to bottom, $light-beige 50%, $bgBody 50%)
        /* bottom to top  50/50 */
    }

    &.b-t-50-50 {
        @include media-breakpoint-down(lg) {
            background: linear-gradient(to top, $light-beige 100%, $bgBody 0%)
                /* bottom to top  100/0 */
        }

        background: linear-gradient(to top, $light-beige 50%, $bgBody 50%)
        /* bottom to top  50/50 */
    }
}

.light-green {
    background-color: $light-green;

    &.r-l-60-40 {
        background: linear-gradient(to left, $light-green 60%, $bgBody 40%)
            /* right to left  60/40 */
    }

    &.l-r-60-40 {
        background: linear-gradient(to right, $light-green 60%, $bgBody 40%)
            /* left to right  60/40 */
    }
}

.dark-brown {
    background-color: $dark-brown;
}

.beige {
    background-color: $beige;
}

.white {
    background-color: $white;
}