.slider-in-cucina {
    .slick-slider {
        @include media-breakpoint-down(lg){
            margin-bottom: 0 !important;
        }
    }
    background: $dark-brown;
    padding-top: 80px;
    padding-bottom: 80px;
    @include media-breakpoint-down(lg){
        padding-top: 40px;
        padding-bottom: 40px;
    }
    margin-bottom: 0 !important;

    .slick-dots {
        @include media-breakpoint-up(lg) {
            display: none !important;
        }

        @include media-breakpoint-down(lg) {
            margin: 2.4rem 0;
            li {
                background: $white;
            }
        }
    }

    .slick-arrow {
        @include media-breakpoint-down(lg) {
            display: none !important;
        }
    }

    .btn-primary {
        @include media-breakpoint-down(lg) {
            margin-bottom: 2.4rem;
        }
    }

    .richtext {
        &.medium p, &.medium .richtext {
            padding-bottom: 4.0rem;
            margin-bottom: 0 !important;
            max-width: 80%;
            @include media-breakpoint-down(lg) {
                padding-bottom: 2.4rem;
                font-size: 1.6rem;
                line-height: 2.4rem;
            }
        }
    }

    .white-text {
        color: $white;
    }

    .card-item {

        a {
            color: $black;
            text-decoration: none;
        }
        
        &:not(:last-child) {
            margin-right: 16px;
        }

        .item-text {
            background: $white;
            padding: 24px;

            p, .richtext {
                font-family: $font-primary;
                font-size: 2.4rem;
                line-height: 32px;
                margin-bottom: 0;
            }
        }
    }

    // .slick-arrow  {
    //     background: $light-beige;
    //     width: 84px;
    //     height: 84px;
    //     z-index: 2;
    //     transition: all .2s ease;
    
    //     &.slick-disabled {
    //         background: $light-beige;
    //         opacity: .5;
    //         pointer-events: none;
    //     }
    
    
    //     &:hover {
    //         background: $light-beige;
    //         transform: translate(0, -50%);
    //         transition: all .2s ease;
    //     }
    
    //     &.slick-next::before, &.slick-prev::before {
    //         content: "";
    //         width: 20px;
    //         height: 33px;
    //         display: inline-block;
    //         background: url(/images/arrow-slide-green.svg) no-repeat center;
    //         background-size: cover;
    //         transition: all .5s ease;
    //     }

    //     &.slick-prev {
    //         &:hover {
    //             &::before {
    //                 margin-right: 16px;
    //                 transition: all .5s ease;
    //             }
    //         }
    //     }
    //     &.slick-next {
    //         right: 0;

    //         &:hover {
    //             &::before {
    //                 margin-left: 16px;
    //                 transition: all .5s ease;
    //             }
    //         }
    
    //         &::before {
    //             transform: rotate(180deg);
    //         }
    //     }
    // }
}