/* NavBar custom */

header {
    &.header {
        z-index: 4;
        position: relative;
    }
    .logo {
        width: 120px;
        // height: 50px;

        &.hp {
            width: 128px;
            height: 100px;
        }
    }

    .navbar {
        padding-top: 0;
        background-image: none;
        padding-bottom: 0;

        .navbar-collapse{
            @include media-breakpoint-down(lg) {
                background-color: $green;
                position: absolute;
                left: 0;
                top: 100px;
                right: 0;
                overflow: auto;

            }
        }

        .navbar-brand {
            padding-top: 0 !important;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            width: 128px;
            min-height: 105px;

            @include media-breakpoint-up(lg) {
                margin-right: 50px;
            }
        }

        ul {
            &.navbar-nav {
                position: relative;
                padding-top: 8px;
                padding-bottom: 8px;

                & > li.nav-item {
                     & {
                            position: relative;
                            text-align: left;
                            padding-left: 2.4rem;
                            padding-right: 2.4rem;
                            @media screen and (min-width: 992px) and (max-width: 1200px) {
                                padding-left: 1.4rem !important;
                                padding-right: 1.4rem !important;
                            }

                            .dropdown-menu {
                                background-color: $green;
                                @include media-breakpoint-down(lg) {
                                    padding-left: 16px;
                                }

                                &.dropdown-menu-start {
                                    .menu-item {
                                        .nav-link {
                                            &::before {
                                                left: 0;
                                                bottom: 0;
                                                background: $white!important;
                                            }
                                        }
                                    }
                                }
                                li {
                                    @include media-breakpoint-up(lg) {
                                        padding: 10px 20px;
                                    }
                                    padding: 5px 0;
                                    color: #FFFFFF !important;
                                    margin-left: 0 !important;
                                    margin-right: 0 !important;


                                    a {
                                        @include media-breakpoint-up(lg) {
                                            color: $white;
                                            padding: 5px 0;
                                            white-space: normal;
                                        }
                                        width: 100%;
                                        padding: 10px 0;
                                        display: inline-block;
                                        text-transform: inherit;
                                        font-weight: 400;
                                        text-align: left;
                                        color: #fff;
                                    }
                                }

                            }
                            
                            // &:hover {
                            //     .dropdown-menu-start {
                            //         display: block;
                            //     }
                            // }
                            @include media-breakpoint-up(lg) {
                                &:hover {
                                    
                                    & > ul {
                                        display: block;
    
                                        & > li:hover {
                                            & > a:before{
                                                background: $white;
                                                width: 30px;
                                            }
                                            & > ul {
                                                display: block;
                                                left: 100%;
                                                top: 0;
                                                a:hover{
                                                    &:before{
                                                        width: 30px;
                                                        background: $white;
                                                    }
                                                }
                                            }
                                        }  
                                    }
                                }
                            }
                            & ul {
                                min-width: 100%;
                                width: max-content;
                                border: none;
                                padding: 0;
                                z-index: 3;

                                & > ul {
                                    top: 0;
                                    left: 100%;
                                }

                                li {
                                    background: transparent;
                                    position: relative;
                                }

                                a {
                                    text-transform: inherit;
                                    color: $dark;
                                    font-size: 1.3rem;
                                    font-weight: 400;
                                    margin: 0 auto;
                                    position: relative;
                                    letter-spacing: .02em;
                                    text-decoration: none;

                                    @include media-breakpoint-up(lg) {
                                        &:before {
                                            display: block;
                                            position: absolute;
                                            content: "";
                                            left: 0px;
                                            bottom: -4px;
                                            height: 2px;
                                            width: 0;
                                            background: $green;
                                            transition: all 0.2s ease;
                                        }
                                    }
        
                                    &:hover {

                                        @include media-breakpoint-up(lg) {
                                            &::before {
                                                width: 30px;
                                            }
                                        }
                                    }

                                    &.active {
                                        position: relative;

                                        
                                        @include media-breakpoint-up(lg) {
                                            &:before {
                                                display: block;
                                                position: absolute;
                                                content: "";
                                                left: 4px;
                                                bottom: 2px;
                                                height: 2px;
                                                width: 30px!important;
                                                background: $green!important;
                                                transition: all 0.2s ease;
                                            }
                                        }
                                        
                                        
                                        @include media-breakpoint-down(lg) {
                                            max-width: max-content;
                                            &:before {
                                                display: block;
                                                position: absolute;
                                                content: "";
                                                left: 0px;
                                                bottom: 4px;
                                                height: 2px;
                                                width: 100%;
                                                background: $green;
                                                transition: all 0.2s ease;
                                            }
                                        }
                                        
                                        &:before {
                                            background: $white!important;
                                            bottom: -4px;
                                            left: 0px;
                                        }
                                    }
                                }
                            }
                        .nav-link {
                            text-transform: uppercase;
                            color: $dark;
                            font-size: 1.3rem;
                            font-weight: 600;
                            width: fit-content;
                            //margin: 0 auto;
                            position: relative;
                            letter-spacing: .02em;
                            display: inline-block;
                            @include media-breakpoint-down(lg) {
                                padding: 10px 0;
                                color: $white;
                            }


                            @include media-breakpoint-up(lg) {
                                &:before {
                                    display: block;
                                    position: absolute;
                                    content: "";
                                    left: 4px;
                                    bottom: 2px;
                                    height: 2px;
                                    width: 0;
                                    background: $green;
                                    transition: all 0.2s ease;
                                }
                                
                            }
                            

                            &:hover {
                                @include media-breakpoint-up(lg) {
                                    &::before {
                                        width: 30px;
                                    }
                                }
                            }

                            &.active {
                                position: relative;

                                @include media-breakpoint-up(lg) {
                                    &:before {
                                        display: block;
                                        position: absolute;
                                        content: "";
                                        left: 4px;
                                        bottom: 2px;
                                        height: 2px;
                                        width: 30px!important;
                                        background: $green!important;
                                        transition: all 0.2s ease;
                                    }
                                }

                                @include media-breakpoint-down(lg) {
                                    &:before {
                                        display: block;
                                        position: absolute;
                                        content: "";
                                        left: 0px;
                                        bottom: 4px;
                                        height: 2px;
                                        width: 100%;
                                        background: $green;
                                        transition: all 0.2s ease;
                                    }
                                }

                                &:before {
                                    background: $white;
                                    //bottom: 0;
                                }

                            }

                            svg {
                                width: 20px;
                                height: 20px;
                            }
                        }

                        &--langs {
                            flex-grow: 0;
                            padding: 0 8px!important;

                            @include media-breakpoint-down(lg) {
                                flex-grow: 1;
                                padding-left: 2.4rem!important;
                                padding-right: 2.4rem!important;
                                flex-basis: 45%;
                            }

                            @include media-breakpoint-up(lg) {
                                .nav-link {
                                    padding-right: 0;

                                    &:after {
                                        vertical-align: middle;
                                        display: none;
                                    }

                                    // &::before {
                                    //     display: none;
                                    // }
                                }
                            }


                            .dropdown-menu {
                                border: none;
                                border-radius: 0;
                                .dropdown-item {
                                    @include media-breakpoint-down(lg) {
                                        color: #241733  !important;
                                        margin-left: 0 !important;
                                        margin-right: 0 !important;
                                    }
                                }
                                @include media-breakpoint-down(lg) {
                                    background-color: transparent;
                                }

                                li {
                                    .dropdown-item {
                                        font-weight: 500;
                                        font-size: 1.5rem;
                                        position: relative;
                                        padding-left: 0;
                                        padding-right: 0;
                                        display: block;
                                        text-align: left;
                                        margin-left: 5px;
                                        margin-right: 5px;

                                        @include media-breakpoint-down(lg) {
                                            text-align: center;
                                        }

                                        @include media-breakpoint-up(lg) {
                                            &:before {
                                                display: block;
                                                position: absolute;
                                                content: "";
                                                left: 0px;
                                                bottom: 2px;
                                                height: 2px;
                                                width: 0;
                                                background: $green;
                                                transition: all 0.2s ease;
                                            }
                                        }

                                        &:hover {
                                            @include media-breakpoint-up(lg) {
                                                &::before {
                                                    width: 30px;
                                                }
                                            }

                                            background-color: transparent;
                                            background-image: none;
                                        }
                                    }
                                }
                            }
                        }

                        &--search {
                            flex-grow: 0;

                            @include media-breakpoint-down(lg) {
                                position: relative;
                                flex-grow: 1;
                            }

                            .nav-link {
                                @include media-breakpoint-up(lg) {
                                    margin-left: 40px;
                                    margin-right: 40px;

                                    &::before {
                                        display: none;
                                    }
                                }
                            }

                            .searchbox {
                                position: absolute;
                                top: 0;
                                left: 0;
                                height: 100%;
                                width: 100%;
                                background-color: $light-green ;
                                border-bottom: 1px solid $body-color;
                                justify-content: space-between;
                                z-index: 1;

                                @include media-breakpoint-down(lg) {
                                    width: 100% !important;
                                }


                                &.collapsing {
                                    opacity: 0 !important;
                                }

                                &.collapse.show {
                                    display: flex !important;
                                    opacity: 1 !important;
                                }

                                &__close {
                                    background-color: transparent;
                                    border: none;
                                    outline: none;
                                    padding-left: 16px;
                                    padding-right: 16px;

                                    svg {
                                        width: 12px;
                                        height: 12px;
                                    }
                                }

                                &__form {
                                    background-color: transparent;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    flex-grow: 1;
                                }

                                &__input {
                                    background-color: transparent;
                                    border: none;
                                    outline: none;
                                    width: 100%;

                                    /* clears the 'X' from Internet Explorer */
                                    &::-ms-clear,
                                    &::-ms-reveal {
                                        display: none;
                                        width: 0;
                                        height: 0;
                                    }

                                    /* clears the 'X' from Chrome */
                                    &::-webkit-search-decoration,
                                    &::-webkit-search-cancel-button,
                                    &::-webkit-search-results-button,
                                    &::-webkit-search-results-decoration {
                                        display: none;
                                    }
                                }

                                &__submit {
                                    width: 40px;
                                    height: 20px;
                                    display: block;
                                    border: none;
                                    outline: none;
                                    background-color: transparent;

                                    svg {
                                        width: 20px;
                                        height: 20px;
                                        transform: translateY(-3px);
                                    }
                                }
                            }
                        }

                        &--social {
                            @include media-breakpoint-up(lg) {
                                display: none !important;
                            }
                            @include media-breakpoint-down(lg) {
                                flex-direction: row !important;
                                justify-content: center;
                                a {
                                    &.nav-link {
                                        width: 30px !important;
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


section {
    &.header-hp {
        min-height: 640px;

        .social {
            @include media-breakpoint-down(lg) {
                display: none !important;
            }

            background-color: $dark;
            position: absolute;
            top: 177px;
            right: 0;
            z-index: 4;
            padding: 12px;

            svg {
                width: 24px;
                height: 24px;
                margin-top: 8px;
                margin-bottom: 8px;
            }
        }

        .gradient {
            position: absolute;
            z-index: 2;
            background: linear-gradient(90deg, #574525 0%, rgba(56, 45, 24, 0) 100%);
            width: 100%;
            top: 0;
            height: 640px;
            @media(max-width: 768px) {
                height: 400px!important;
            }
        }

        .bg-img {
            height: 640px!important;
            @media(max-width: 768px) {
                height: 400px!important;
            }
        }

        .min-height {
            align-items: center;
            @media (max-width: 991px) {
                min-height: 400px;
            }
            @media(max-width: 768px) {
                min-height: 250px;
            }
        }

        h1 {
            color: $bgBody;
            max-width: 830px;
        }

        p, .richtext {
            font-size: 4.8rem;
            line-height: 120%;
            color: $bgBody;
            font-family: $font-primary;
            max-width: 830px;
            @include media-breakpoint-down(lg) {
                font-size: 2.4rem;
                line-height: 32px;
            }
        }

        .slick-slider {
            z-index: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        .w-scale {
            width: 75%;
            margin-top: 72px;
            margin-bottom: 40px;

            @include media-breakpoint-down(lg) {
                width: 100%;
                margin-top: 16px;
                margin-bottom: 24px;
            }
        }

        .z-1 {
            z-index: 1;
        }

        .z-3 {
            z-index: 3;
        }
        .navbar-collapse{
            @include media-breakpoint-down(lg) {
                background-color: $green;
                position: absolute;
                left: 0;
                top: 100px;
                right: 0;
                overflow: auto;

            }
        }
        .navbar {
            ul {
                &.navbar-nav {
                    li {
                        &.nav-item {
                            text-align: left;
                            .dropdown-menu {
                                border-radius: 0;
                                    background-color: $green;
                                    
                                    @include media-breakpoint-down(lg) {
                                        padding-left: 16px;
                                    }
                                    a{
                                        color: $white;
                                        color: #FFFFFF;
                                        // display: block;
                                        text-align: left;
                                        padding: 5px 0;
                                        width: 100%;
                                        @include media-breakpoint-down(lg) {
                                            padding: 10px 0;
                                            display: inline-block;
                                        }
                                        
                                    }
                          
                                .dropdown-item {
                                    
                                    @include media-breakpoint-up(lg) {
                                        padding: 10px 20px;
                                    }
                                    padding: 5px 0;
                                    color: $white  !important;
                                    margin-left: 0 !important;
                                    margin-right: 0 !important;
                                }
                            }
                            padding-left: 2.4rem;
                            padding-right: 2.4rem;
                            .nav-link {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }

        .card {
            border-radius: 0;
            border: 10px solid #fff;
            min-height: 450px;
            @include media-breakpoint-down(lg) {
                // border: 0;
                box-shadow: 0px 0px 40px rgb(0 0 0 / 10%);
            }

            &.mb-40 {
                @include media-breakpoint-down(lg) {
                    margin-bottom: 40px;
                }
            }

            .card-body {
                padding-top: 2.4rem;
                .btn {
                    &.btn-secondary {
                        margin-left: 0;
                        padding-left: 0;
                        text-align: left;
                    }
                }

                .card-title {
                    font-size: 2.4rem;
                    font-weight: 700;
                    line-height: 32px;
                    //padding-bottom: 24px;
                    font-family: $font-secondary;
                    min-height: 80px;
                    margin-bottom: 0;
                    @include media-breakpoint-down(lg) {
                        min-height: unset;
                    }
                }

                p, .richtext {
                    &.card-text {
                        color: $dark;
                        font-size: 1.6rem;
                        line-height: 24px;
                        //margin-bottom: 24px;
                        font-family: $font-secondary;
                        height: 70px;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
                        white-space: pre-wrap; /* let the text wrap preserving spaces */
                    }
                }
            }
        }
    }
}

/* Overwrite */

.navbar-toggler-icon {
    background-image: url("./../images/ico-nav.png") !important;
    background-size: 100%;
    background-position: center center;
    width: 24px;
    height: 24px;
    display: flex;
}

.navbar-toggler {
    border-color: transparent !important;
    color: transparent !important;
    width: 48px;
    height: 34px;
    background-color: $green !important;
    border-radius: 0;
}

.toggle-menu-mobile {
    width: 20px;
    height: 20px;
    transform: rotate(-90deg);
    margin-left: 8px;
    transition: all .5s ease;
    position: absolute;
    top: 8px;
    right: 16px;
    background: url(../images/arrow-slide-white.svg) no-repeat center;
    background-size: contain;
    &.rotate {
        transform: rotate(90deg);
    }
}

.dropdown-toggle::after {
    @include media-breakpoint-down(lg) {
        display: none;
    }
}

.dropdown-menu .dropdown-menu .dropdown-toggle::after {
    display: none!important;
}

.LangDropdown {

    &:hover {
        &:before {
            background: transparent!important;
            width: 0!important;
        }
    }

    &.active-lang {
        &:before {
            display: block;
            position: absolute;
            content: "";
            left: 2px!important;
            bottom: 2px;
            height: 2px;
            width: 100%!important;
            background: $green!important;
            transition: all 0.2s ease;

            @include media-breakpoint-down(lg) {
                background: $white!important;
                left: 0!important;
            }
        }
    }
}