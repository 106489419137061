.chef-community-suggested {
    .slider-suggested {
        .card-valori {
            .title {
                font-size: 1.4rem;
                font-weight: 600;
                margin-bottom: 32px;

                span {
                    display: block;
                    font-weight: 400;
                    font-style: italic;
                }
            }


            &:not(:last-child) {
                margin-right: 16px;
            }

            img {
                width: 100%;
            }
        }
    }
}