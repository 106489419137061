@import "variables";
@import "bs-custom";


/*START FULL BOOTSTRAP IMPORT HERE*/
//@import 'bootstrap/scss/bootstrap.scss';
/*END FULL BOOTSTRAP IMPORT HERE*/


/*START IMPORT SINGLE BOOTSTRAP COMPONENTS*/
// Configuration
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

// Layout & components
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas";

// Helpers
@import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";
/*END IMPORT SINGLE BOOTSTRAP COMPONENTS*/

/*SLICK STARTS HERE*/
$slick-arrow-color: purple;
@import 'slick-carousel/slick/slick.scss';
@import 'slick-carousel/slick/slick-theme.scss';
/*SLICK ENDS HERE*/

@import "bs-override";
@import "fonts";

/*COMPONENTS*/
@import "forms";
@import "header";
@import "footer";
@import "general";
@import "breadcrumbs";
@import "hero";
@import "two-boxes";
@import "linear-two-col-floating-img";
@import "sds";
@import "chef-community-main";
@import "slider-in-cucina";
@import "text";
@import "title-text";
@import "quote";
@import "filters-map";
@import "cards";
@import "triple-images";
@import "chef-community-main";
@import "slider-eventi-news";
@import "team";
@import "search";
@import "contact-form";
@import "list-link";
@import "map-onclick";
@import "chef-community-map";
@import "double-images";
@import "triple-cards";
@import "slider-cards-green";
@import "slider-full";
@import "hero-plus-triple-cards";
@import "title";
@import "details";
@import "modale-sentieri-sorriso";
@import "chef-community-card-list";
@import "chef-community-blog";
@import "pagination";
@import "chef-community-suggested";
@import "modale-photo-gallery";
@import "questions";
@import "food-pyramid.scss";
@import "eventi-news.scss";
@import "block-text-einstein";
@import "404";

/*GENERAL CSS*/
html,
body {
    overflow-x: hidden;
}

body {
    font-weight: 400;
    font-family: $font-secondary;
    background-color: $bgBody;
}

section {
    margin-bottom: 40px;
    @include media-breakpoint-down(lg){
        margin-bottom: 40px;
    }
}

section {
    &.light-green {
        margin-bottom: 0;
        padding-bottom: 104px;
        @include media-breakpoint-down(lg){
            padding-bottom: 40px;
        }
    }
}

section {
    &.light-beige {
        margin-bottom: 0;
        padding-bottom: 40px;
        @include media-breakpoint-down(lg){
            padding-bottom: 40px;
        }
    }
}

h1,
h2 {
    font-family: $font-primary;
}

h3,
h4 {
    font-family: $font-secondary;
}

h1 {
    font-size: 6.4rem;
    line-height: 72px;
    @include media-breakpoint-down(lg){
        font-size: 4rem;
        line-height: 48px;
    }
}

h2 {
    font-size: 4.8rem;
    line-height: 120%;
    padding-bottom: 3.2rem;
    padding-top: 1rem;

    @include media-breakpoint-down(lg){
        padding-bottom: 2.4rem;
        font-size: 3.2rem;
    }
    @media(min-width: 991px) and (max-width:1200px) {
        font-size: 4rem;
    }

    &.medium {
        font-size: 3.2rem;
        line-height: 40px;
        font-weight: 400;
    }
}

h4 {
    line-height: 32px;
}

p, .richtext {
    font-size: 1.6rem;
    line-height: 24px;

    &.medium {
        font-size: 2.4rem;
        line-height: 32px;
    }

    ul, ol {
        padding-left: 15px;
    }

    ul li {
        list-style: disc;
    }
}

.link {
    text-decoration: none;
    font-weight: 700;
}

ul {
    padding: 0;

    li {
        list-style: none;
    }
}

.btn {
    font-family: $font-secondary;
    padding: 16px;
    position: relative;
    font-size: 1.6rem;

    &.btn-primary, input.btn-primary {
        color: $white;
        line-height: 18px;
        border: 0;
        border-radius: 0;
        width: 200px;
        height: 50px;
        text-align: left;
        background-color: $green !important;

        &:hover {
            background-color: $green !important;

            &:after {
                right: 0;
            }
        }

        &:focus {
            box-shadow: none;
        }

        &:after {
            content: "";
            display: inline-block;
            width: 19px;
            height: 19px;
            background: url(./../images/cta-white-arrow.png) no-repeat center;
            background-size: cover;
            position: absolute;
            right: 16px;
            transition: all .5s ease;
        }
    }

    &.btn-secondary, input.btn-secondary {
        color: $green;
        background-color: transparent;
        background-image: none;
        line-height: 18px;
        border: 0;
        border-radius: 0;
        width: 200px;
        height: 50px;
        font-weight: 600;

        &:hover {
            background-color: transparent !important;

            &:after {
                right: 0;
            }
        }

        &:focus {
            box-shadow: none;
        }

        &:after {
            content: "";
            display: inline-block;
            width: 19px;
            height: 19px;
            background: url(./../images/cta-green-arrow.png) no-repeat center;
            background-size: cover;
            position: absolute;
            right: 16px;
            transition: all .5s ease;
        }
    }

    &.btn-tertiary {
        color: $green;
        background-color: $white;
        line-height: 18px;
        border: 0;
        border-radius: 0;
        width: 200px;
        height: 50px;
        text-align: left;

        &:hover {
            background-color: $white !important;

            &:after {
                right: 0;
            }
        }

        &:focus {
            box-shadow: none;
        }

        &:after {
            content: "";
            display: inline-block;
            width: 19px;
            height: 19px;
            background: url(./../images/cta-green-arrow.png) no-repeat center;
            background-size: cover;
            position: absolute;
            right: 16px;
            transition: all .5s ease;
        }
    }
}

.slick-arrow {
    background: $light-beige;
    width: 84px;
    height: 84px;
    z-index: 2;
    transition: all .2s ease;

    &.slick-disabled {
        background: $light-beige;
        opacity: 1;
        pointer-events: none;
    }


    &:hover,
    &:active,
    &:focus {
        background: $light-beige;
        transform: translate(0, -50%);
        transition: all .2s ease;
    }

    &.slick-next::before,
    &.slick-prev::before {
        content: "";
        width: 20px;
        height: 33px;
        display: inline-block;
        background: url(./../images/arrow-slide-green.svg) no-repeat center;
        background-size: cover;
        transition: all .5s ease;
    }

    &.slick-prev {
        &:hover {
            &::before {
                margin-right: 16px;
                transition: all .5s ease;
            }
        }
    }

    &.slick-next {
        // right: 0;

        &:hover {
            &::before {
                margin-left: 16px;
                transition: all .5s ease;
            }
        }

        &::before {
            transform: rotate(180deg);
        }
    }
}

.slick-dots {
    position: relative;
    margin: 4rem 0;

    li {
        width: 6px;
        height: 6px;
        background: $black;
        border-radius: 50%;

        &:not(:last-child) {
            margin-right: 24px;
        }

        &.slick-active {
            border: 3px solid green;
            background: transparent;
            width: 12px;
            height: 12px;
        }

        button:before {
            font-size: 0;
        }
    }
}

/* spacers */

.pt-104 {
    padding-top: 80px;
    @include media-breakpoint-down(lg){
        padding-top: 40px;
    }
}

.mt-104 {
    margin-top: 80px;
    @include media-breakpoint-down(lg){
        margin-top: 40px;
    }
}

.pb-104 {
    padding-bottom: 80px;
    @include media-breakpoint-down(lg){
        padding-bottom: 40px;
    }
}

.mb-104 {
    margin-bottom: 80px;
    @include media-breakpoint-down(lg){
        margin-bottom: 40px;
    }
}

.counter-ricette {
    font-size: 18px;
    font-weight: 600;
    color: $light-grey;
    font-family: $font-tertiary;
}

.alert {
    position: relative;

    .close {
        color: $white;
        text-decoration: none;
        position: absolute;
        top: 5px;
        right: 10px;
    }
}

.alert-success {
    color: green;
    background-color: #00800099;
    border-color: green;
}

.alert-warning {
    color: red;
    background-color: #ff000099;
    border-color: red;
}