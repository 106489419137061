.details {
    .row {
        &:nth-child(1) {
            padding-bottom: 7.2rem;
        }
    }
    h2 {
        font-size: 2.4rem;
        line-height: 120%;
        font-family: $font-secondary;
        font-weight: 700;
    }

    p, .richtext {
        font-size: 1.6rem;
        line-height: 24px;
    }

    ul {
        &.icon {
            margin-left: 0;

            li {
                list-style-type: none;
                margin-bottom: 8px;

                svg {
                    width: 20px;
                    height: 20px;
                    margin-right: 8px;
                }
            }
        }

        margin-left: 20px;

        li {
            list-style-type: disc;
        }
    }

    .author {
        font-family: $font-tertiary;
        font-size: 1.8rem;
        font-weight: 600;
        color: $light-grey;
        margin-right: 16px;
    }

    .share {
        svg {
            width: 20px;
            height: 20px;
        }
    }

    .max-w-830 {
        max-width: 830px;
    }
}