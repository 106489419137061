@import "variables";

.page-error {
    text-align: center;
    height: calc(100vh - 105px - 296px);

    @media(max-width: 1500px) {
        height: calc(100vh - 105px);

    }


    .minihero {
        background-image: url(./../images/404-hero.jpg);height: 244px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin-bottom: 40px;
    }

    h1 {
        margin-bottom: 40px;

        @media(max-width:768px) {
            margin-bottom: 32px;
        }
    }
    p {
        margin-bottom: 40px;

        @media(max-width:768px) {
            margin-bottom: 24px;
        }
    }
}