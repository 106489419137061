footer {
    background-color: $dark;
    padding: 72px 0;
    @include media-breakpoint-down(lg){
        padding: 40px 0;
    }

    img {
        padding-bottom: 2.4rem;
    }

    span {
        &.copy {
        padding-bottom: 1.6rem;
        }
        font-size: 1.2rem;
        color: #ffffff;
        line-height: 150%;
        font-weight: 500;
        display: block;
        @include media-breakpoint-down(md){
            text-align: left;
        }
    }
    div {
        :last-child {
            margin-right: 0;
        }
        @include media-breakpoint-down(md){
            :first-child {
                margin-left: 0;
            }
        }
    }
    svg {
        width: 24px;
        height: 24px;
        margin: 8px;
        margin-bottom: 0;
        text-align: left;
    }

    .policy-buttons {
        a {
           padding: 4px 8px; 
           color: $green-sds;
           background: $white;
           font-size: 12px;
           text-decoration: none;
           border-radius: 5px;

           &:hover {
                color: $white!important;
                background: $green-sds!important;
           }
        }
    }
}