$accent: #93328e;
$text:   #333;

/*BS :ROOT VARS*/
$primary:   $green;
$secondary: #93328e;
$success:   #93328e;
$info:      #93328e;
$warning:   #93328e;
$danger:    #93328e;
$font-family-base: 'Open Sans', sans-serif;
$font-size-root:62.5%;        // 1rem === 10px ; 1.6rem === 16px
$font-size-base: 1.6rem;      // default body font size === 16px
$line-height-base: 1.44;      // default line height = 1.44
$body-text-align:left;
$body-color: #000;          // default text color
$body-bg: $bgBody;            // deafult body background

// BS grid vars
$grid-columns: 12 !default;
$grid-gutter-width: 3.2rem;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px // manually added breakpoint
);

$container-max-widths: (
  xs: 477px, // manually added container maxw  
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  //xxxl: 1580px // manually added container maxw  
);


$spacer: 2.4rem;
//$spacer: 3.2rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 6 // manually added 
  );
  
  
$enable-rounded:             true;
$enable-shadows:             false;
$enable-gradients:           true;
$enable-grid-classes:        true;
$enable-caret:               true;
$enable-button-pointers:     true;
$enable-rfs:                 true;
$enable-validation-icons:    true;
$enable-negative-margins:    true;
$enable-deprecation-messages:true;
$enable-important-utilities: true;

// colors

// $theme-colors: (
//   "accent":  $accent,
//   "text":    $text
// );



// sxample components customizatio

$input-btn-font-family: monospace;

$form-check-input-width: 1em;
$form-check-padding-start:$form-check-input-width + .8em ;

$form-check-input-bg: $primary;

$form-check-input-checked-color: #FAFAFA;
$form-check-input-border:$primary;