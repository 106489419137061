.list-link {
    padding-top: 104px;
    p, .richtext {
        &.medium {
            padding-bottom: 4.0rem;
        }
    }
    .link-col a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        border-bottom: 1px solid $green;
        text-decoration: none;

        & p, .richtext {
            display: inline-block;
            font-weight: 600;
            padding: 8px 0;
            text-decoration: none;
        }

        & img {
            max-height: 24px;
            margin-right: 8px;
            transition: all .5s ease;
        }

        &:hover {
            & img {
                margin-right: 0px;
                transition: all .5s ease;
            }

            & p, .richtext {
                text-decoration: underline;
            }
        }
    }
}