section {
    &.cards-green {
        margin-bottom: 80px; // 80 + 24 dots

        .container {
            &.box {
                @include media-breakpoint-up(lg) {
                    padding: 0;
                    margin: 0 auto;
                }
            }
        }
    }
}

.cards-green {
    h2 {
        padding-bottom: 1.6rem;
    }

    p, .richtext {
        line-height: unset;
        padding-bottom: 3.2rem;
        margin-bottom: 0;
    }

    &.rules {
        .slider-cards-green {

            .slick-track {
                display: flex !important;
            }

            .slick-slide {
                height: inherit !important;
            }
            .card-valori {
                position: relative;

                h2 {
                    display: none;
                }

                h3 {
                    padding: 0 16px;
                }

                .richtext {
                    display: block;
                    padding: 2.4rem;
                    padding-bottom: 0;
                }

                .valori-extra {
                    display: none;
                }

                // span {
                //     position: absolute;
                //     top: 10px;
                //     left: 10px;
                //     font-size: 6.4rem;
                //     font-family: $font-primary;
                //     color: $basic-brown;
                //     line-height: 6.4rem;
                //     display: block;
                // }
            }
        }
    }


    .slider-cards-green {
        .slick-track {
                display: flex !important;
            }

            .slick-slide {
                height: inherit !important;
            }
        &.unslick {
            //width: 100% !important;
            margin-left: 0;
            margin-right: 0;

            @media(max-width: 992px) {
                width: auto !important;
            }



            .card-valori {
                width: calc(33.3% - 16px);

                &:not(:last-child) {
                    margin-right: auto !important;
                }

                @media(max-width: 992px) {
                    width: 100% !important;
                    margin-right: 0 !important;
                    margin-bottom: 24px;
                }

                &:last-child {
                    margin-right: 0 !important;
                    width: 33.3%;
                }

                h2 {
                    display: none;
                }

                img {
                    min-height: 80px;
                }

                p, .richtext {
                    display: block;
                    padding: 2.4rem;

                    &.fst-italic {
                        display: none !important;
                    }
                }
            }
        }

        margin-bottom: 0;

        .slick-dots {
            padding-top: 2.4rem;
            padding-bottom: 2.4rem;
            bottom: 0;
            margin: auto;
        }

        .card-valori {
            min-height: 383px;
            padding: 4.8rem 0;

            @include media-breakpoint-down(lg) {
                min-height: 366px;
            }

            &:not(:last-child) {
                margin-right: 16px;
            }

            img {
                max-width: 80px;
                padding-bottom: 24px;
            }

            h3 {
                font-weight: 600;
            }

            .valori-extra {
                font-size: 14px;
                height: 0;
                margin: 0;
            }

            .richtext {
                display: none;

            }
            .valori-extra {
                display: block;
            }

            // span {
            //     display: none;
            // }
        }
    }
}